import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
    Button,
    Modal,
    MenuItem,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Typography,
    Backdrop,
    Link,
    Box,
    Menu,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import TuneIcon from "@mui/icons-material/Tune";
import L from "leaflet";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LayersIcon from "@mui/icons-material/Layers";

export function OpenStreetMap() {
    const [selectedMap, setSelectedMap] = useState<string>("Open Street Map");
    const [selectedMapCopyright, setSelectedMapCopyright] = useState<string>(
        "https://www.openstreetmap.org/copyright"
    );
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [markerPosition, setMarkerPosition] = useState<L.LatLngTuple | null>(
        null
    );
    const mapRef = useRef<L.Map>(null);

    const maps: { [key: string]: string; } = {
        "Fundo Branco": "",
        "Open Street Map": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "Google Maps": "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
        "Google Satellite": "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        "Google Terrain": "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    };

    const copyrightsMaps: { [key: string]: string; } = {
        "Fundo Branco": "",
        "Open Street Map": "https://www.openstreetmap.org/copyright",
        "Google Maps": "https://about.google/brand-resource-center/",
        "Google Satellite": "https://about.google/brand-resource-center/",
        "Google Terrain": "https://about.google/brand-resource-center/",
    };

    const [items, setItems] = useState([
        { id: "outorgas", content: "Outorgas" },
        { id: "regionalizacao", content: "Estações na regionalização" },
        { id: "estudo", content: "Ponto de estudo" },
        { id: "trechos", content: "Trechos" },
        { id: "massas", content: "Massas d'água" },
        { id: "drenagem", content: "Área de drenagem" },
    ]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            map.on("click", handleMapClick);
        }
    }, []);

    const handleMapClick = (e: L.LeafletMouseEvent) => {
        setMarkerPosition([e.latlng.lat, e.latlng.lng]);
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        setItems(reorderedItems);
    };

    const handleInfoButtonClick = () => {
        setOpenInfoDialog(true);
    };

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (mapName: string) => {
        setSelectedMap(mapName);
        setSelectedMapCopyright(mapName ? copyrightsMaps[mapName] : "");
        handleMenuClose();
    };

    return (
        <MapContainer
            ref={mapRef}
            center={[-19.924444, -43.938888]}
            zoom={13}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#000",
            }}
        >
            <TileLayer url={maps[selectedMap]} />
            {markerPosition && (
                <Marker position={markerPosition} >
                    <Popup>
                        Lat/Long: {markerPosition[0]}, {markerPosition[1]}
                    </Popup>
                </Marker>
            )}
        </MapContainer>

    // <div
    //   style={{
    //     position: "absolute",
    //     top: 20,
    //     right: 20,
    //     zIndex: 1000,
    //   }}
    // >
    //   <Button
    //     variant="contained"
    //     sx={{
    //       height: "40px",
    //       width: "40px",
    //       borderRadius: "50%",
    //       backgroundColor: "transparent",
    //       color: "black",
    //       "&:hover": {
    //         backgroundColor: "#C2D7DE",
    //       },
    //     }}
    //     onClick={() => setOpenModal(true)}
    //   >
    //     <TuneIcon sx={{ fontSize: "20px" }} />
    //   </Button>
    // </div>
    // <div
    //   style={{
    //     position: "absolute",
    //     bottom: 20,
    //     right: 20,
    //     zIndex: 1000,
    //   }}
    // >
    //   <Button
    //     variant="contained"
    //     sx={{
    //       height: "40px",
    //       width: "40px",
    //       borderRadius: "50%",
    //       backgroundColor: "transparent",
    //       color: "black",
    //       "&:hover": {
    //         backgroundColor: "#C2D7DE",
    //       },
    //     }}
    //     onClick={handleInfoButtonClick}
    //   >
    //     <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
    //   </Button>
    // </div>

    // <Modal
    //   open={openModal}
    //   onClose={() => setOpenModal(false)}
    //   style={{
    //     position: "absolute",
    //     top: "50%",
    //     left: "50%",
    //     transform: "translate(-50%, -50%)",
    //     backgroundColor: "#fff",
    //     padding: 20,
    //     borderRadius: "40%",
    //     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    //     height: "70vh",
    //     width: "30vw",
    //   }}
    // >
    //   <div
    //     style={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)",
    //       backgroundColor: "#DCE8EC",
    //       padding: 20,
    //       borderRadius: 40,
    //       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    //       width: "100%",
    //       height: "100%",
    //     }}
    //   >
    //     <Box
    //       flexDirection={"row"}
    //       display={"flex"}
    //       alignItems="center"
    //       justifyContent="space-between"
    //     >
    //       <Typography variant="h6" style={{ marginRight: "auto" }}>
    //         Layers
    //       </Typography>
    //       <Button
    //         variant="contained"
    //         onClick={handleMenuOpen}
    //         sx={{
    //           borderRadius: 2,
    //           borderColor: "black",
    //           height: "4vh",
    //           textTransform: "none",
    //           backgroundColor: "transparent",
    //           color: "black",
    //           "&:hover": {
    //             backgroundColor: "#C2D7DE",
    //           },
    //         }}
    //       >
    //         {selectedMap} <LayersIcon />
    //       </Button>
    //       <Menu
    //         id="map-menu"
    //         anchorEl={anchorEl}
    //         open={Boolean(anchorEl)}
    //         onClose={handleMenuClose}
    //         anchorOrigin={{
    //           vertical: "bottom",
    //           horizontal: "left",
    //         }}
    //         transformOrigin={{
    //           vertical: "top",
    //           horizontal: "left",
    //         }}
    //       >
    //         {Object.keys(maps).map((mapName) => (
    //           <MenuItem
    //             key={mapName}
    //             selected={mapName === selectedMap}
    //             onClick={() => handleMenuItemClick(mapName)}
    //           >
    //             {mapName}
    //           </MenuItem>
    //         ))}
    //       </Menu>
    //     </Box>
    //     <Divider
    //       sx={{
    //         border: "1px solid",
    //         borderColor: "#b7c4c7",
    //         marginTop: "1vh",
    //       }}
    //     />
    //     <DragDropContext onDragEnd={handleDragEnd}>
    //       <Droppable droppableId="modalDroppable" direction="vertical">
    //         {(provided) => (
    //           <div
    //             {...provided.droppableProps}
    //             ref={provided.innerRef}
    //             style={{ overflow: "hidden" }}
    //           >
    //             {items.map((item, index) => (
    //               <Draggable
    //                 key={item.id}
    //                 draggableId={item.id}
    //                 index={index}
    //               >
    //                 {(provided) => (
    //                   <div
    //                     {...provided.draggableProps}
    //                     {...provided.dragHandleProps}
    //                     ref={provided.innerRef}
    //                   >
    //                     <Box
    //                       ref={provided.innerRef}
    //                       flexDirection={"row"}
    //                       display={"flex"}
    //                       alignItems="center"
    //                       justifyContent="space-between"
    //                       sx={{ marginTop: "2vh" }}
    //                     >
    //                       <Box
    //                         flexDirection={"row"}
    //                         display={"flex"}
    //                         alignItems="center"
    //                       >
    //                         <Checkbox
    //                           defaultChecked
    //                           color="primary"
    //                           inputProps={{
    //                             "aria-label": "secondary checkbox",
    //                           }}
    //                         />
    //                         <Typography variant="body1">
    //                           {item.content}
    //                         </Typography>
    //                       </Box>
    //                       <Box sx={{ display: "flex", alignItems: "center" }}>
    //                         <MenuOutlinedIcon />
    //                       </Box>
    //                     </Box>
    //                   </div>
    //                 )}
    //               </Draggable>
    //             ))}
    //             {provided.placeholder}
    //           </div>
    //         )}
    //       </Droppable>
    //     </DragDropContext>
    //     <Box>
    //       <Button
    //         sx={{
    //           position: "absolute",
    //           bottom: 10,
    //           right: 10,
    //           textTransform: "none",
    //           border: "1px solid #b7c4c7",
    //           borderRadius: "40%",
    //         }}
    //         onClick={() => setOpenModal(false)}
    //       >
    //         Feito
    //       </Button>
    //     </Box>
    //   </div>
    // </Modal> */}
    // </div >
    );
}
