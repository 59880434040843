import { isNull } from "lodash";
import { PaintLineString } from "./paintLine";
import { PaintMarker, PaintPoint } from "./paintPoint";
import { PaintPolygon } from "./paintPolygon";
import { evaluate } from "mathjs";

/// Dynamic paint
export interface PaintCondition<T extends PaintPoint | PaintLineString | PaintPolygon | PaintMarker> {
    comparation: "=" | "!=" | ">" | ">=" | "<" | "<=";
    comparisonValue: Date | number | string | null | PropertieCondition;
    propertieValue: PropertieCondition;
    paintData: T;
}

export interface PropertieCondition {
    propertieName?: string;
    propertieEquation?: string;
}

export const paintByConditions = (properties: { [key: string]: any; }, conditions: PaintCondition<PaintPoint | PaintLineString | PaintPolygon | PaintMarker>[]): PaintPoint | PaintLineString | PaintPolygon | PaintMarker => {
    if (conditions.length === 0) throw Error("Nenhuma condição encontrada");

    for (const condition of conditions) {
        const value = conditionToValue(properties, condition.propertieValue);

        let compareValue: Date | number | string | null = 0;

        if (!condition.comparisonValue) {
            compareValue = condition.comparisonValue;
        }
        else if (condition.comparisonValue instanceof Date) {
            compareValue = condition.comparisonValue;
        }
        else if (typeof condition.comparisonValue === "string" || typeof condition.comparisonValue === "number") {
            compareValue = condition.comparisonValue;
        }
        else if ('propertieName' in condition.comparisonValue && 'propertieEquation' in condition.comparisonValue) {
            compareValue = conditionToValue(properties, condition.comparisonValue as PropertieCondition);
        }

        const check = compareData(value, compareValue, condition.comparation);
        if (check) return condition.paintData;
    }

    return conditions[0].paintData;
};

const conditionToValue = (properties: { [key: string]: number | string | Date | undefined | null; }, propertieCondition: PropertieCondition): Date | number | string | null => {
    if (propertieCondition.propertieName) return properties[propertieCondition.propertieName] ?? null;

    if (propertieCondition.propertieEquation) {
        const variables: { [key: string]: number | string; } = {};

        for (const key in properties) {
            if (!properties[key]) {
                variables[key] = 0;
                continue;
            }

            if (properties[key] instanceof Date) {
                variables[key] = (properties[key] as Date)?.getTime() ?? 0;
                continue;
            }

            variables[key] = (properties[key] as string | number) ?? 0;
        }

        return evaluate(propertieCondition.propertieEquation, properties);
    }

    return null;
};

const compareData = (
    val1: Date | number | string | null,
    val2: Date | number | string | null,
    comparison: "=" | "!=" | ">" | ">=" | "<" | "<="
): boolean => {

    let valor1 = null;
    if (val1 instanceof Date) {
        valor1 = val1.getTime();
    }

    let valor2 = null;
    if (val2 instanceof Date) {
        valor2 = val2.getTime();
    }

    if (isNull(valor1) || isNull(valor2)) return false;

    switch (comparison) {
        case "=":
            return valor1 === valor2;
        case "!=":
            return valor1 !== valor2;
        case ">":
            return valor1 > valor2;
        case ">=":
            return valor1 >= valor2;
        case "<":
            return valor1 < valor2;
        case "<=":
            return valor1 <= valor2;
        default:
            return false;
    }
};