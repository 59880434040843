import axios, { AxiosResponse } from "axios";
import { HidroCryper } from "../../core";
import { API_URL } from "../../enviroment";
import {
    ApiAuth,
    ApiAuxiliares,
    ApiEstacoes,
    ApiEstatistica,
    ApiEstudo,
    ApiFluviometricas,
    ApiHidrologia,
    ApiOutorgas,
    ApiRegionalizacoes,
    ApiUser,
} from "./";
import { ApiSchema } from "./apiSchema";
import { ApiObservatorio } from "./observatorio";

// Refresh Token
export async function requestRefreshAuthToken(): Promise<boolean> {
    // Obtendo o token salvo no local
    const encryptedData: string = localStorage.getItem("refreshToken") ?? "";
    const refreshToken = HidroCryper.decrypt(encryptedData);

    if (refreshToken === "") {
        return false;
    }

    let token = "";

    try {
        const response: AxiosResponse = await axios.post(
            `${API_URL}/auth/refresh`,
            {},
            {
                headers: {
                    "Authorization": "Bearer " + refreshToken,
                },
            }
        );

        if (response.status !== 200 || !response.data["token"]) token = "";
        else if (response.status === 200) token = response.data["token"];
    } catch (e) {
        token = "";
    }

    token = token.replace("Bearer ", "");
    const encrypted = HidroCryper.encrypt(token ?? "");
    localStorage.setItem("token", encrypted);

    return token !== "";
}

// Dados da API
export function getApiSchema(): ApiSchema {
    return {
        auth: ApiAuth,
        auxiliares: ApiAuxiliares,
        estacoes: ApiEstacoes,
        estatistica: ApiEstatistica,
        estudo: ApiEstudo,
        fluviometricas: ApiFluviometricas,
        hidrologia: ApiHidrologia,
        outorgas: ApiOutorgas,
        regionalizacoes: ApiRegionalizacoes,
        user: ApiUser,
        observatorio: ApiObservatorio,
    };
}