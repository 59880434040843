import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const loginMS = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/auth/login-ms`);
};

const loginGoogle = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/auth/login-google`);
};


const login = async (email: string, senha: string): Promise<AxiosResponse> => {
    return await axios.post(`${API_URL}/auth/login`,
        {
            email: email,
            senha: senha,
        },
    );
};

export const ApiAuth = {
    loginMS: loginMS,
    loginGoogle: loginGoogle,
    login: login,

};
