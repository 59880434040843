import React from 'react';
import { Box, Icon, Link, Typography, Grid, useTheme } from '@mui/material';
import { Color } from '../../../core';
import logoHbr from '../../../assets/logo-hbr.svg';
import { Helmet } from 'react-helmet-async';

export const Footer: React.FC = () => {
    const theme = useTheme();
    const themeDefault = useTheme();
    const themeNow = theme ?? themeDefault;

    const bkColor = new Color(themeNow.palette.primary.dark);
    const textColor = bkColor.contrast();

    return (
        <>
            <Helmet>
                <title>Contato e Localização - HIDROBR</title>
                <meta name="description" content="Entre em contato conosco ou visite-nos em nossa localização em Belo Horizonte, MG. Estamos aqui para ajudar com suas necessidades em hidrologia e recursos hídricos." />
                <meta name="keywords" content="contato, localização, hidrologia, recursos hídricos, Belo Horizonte, MG" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "HIDROBR",
                        "url": "https://hidrobr.com",
                        "logo": "https://hidrobr.com/path/to/logo.png",
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+55-31-99854-8220",
                                "contactType": "customer service",
                                "email": "hidrobr@hidrobr.com"
                            },
                            {
                                "@type": "ContactPoint",
                                "telephone": "+55-31-3504-2733",
                                "contactType": "customer service",
                                "email": "comercial@hidrobr.com"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "AV. BRASIL, Nº 888, 14º E 15º ANDAR",
                            "addressLocality": "Belo Horizonte",
                            "addressRegion": "MG",
                            "postalCode": "30180-001",
                            "addressCountry": "BR"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <Box sx={{ backgroundColor: '#1E2E42', color: 'white', p: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
                        <Icon sx={{ color: textColor.toHex }}>mail</Icon>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <Link href="mailto:hidrobr@hidrobr.com" color="inherit" underline="hover" target="_blank">
                                HIDROBR@HIDROBR.COM
                            </Link>
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <Link href="mailto:comercial@hidrobr.com" color="inherit" underline="hover" target="_blank">
                                COMERCIAL@HIDROBR.COM
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
                        <Icon sx={{ color: textColor.toHex }}>call</Icon>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <Link href="https://wa.me/5531998548220" color="inherit" underline="hover" target="_blank">
                                31 99854-8220
                            </Link>
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <Link href="tel:3135042733" color="inherit" underline="hover" target="_blank">
                                31 3504-2733
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
                        <Icon sx={{ color: textColor.toHex }}>location_on</Icon>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <Link
                                href="https://www.google.com/maps/search/?api=1&query=AV.+BRASIL,+N%C2%BA+888,+14%C2%BA+E+15%C2%BA+ANDAR+BELO+HORIZONTE,+MG"
                                color="inherit"
                                underline="hover"
                                target="_blank"
                            >
                                AV. BRASIL, Nº 888, 14º E 15º ANDAR BELO HORIZONTE, MG
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                            <img src={logoHbr} alt='HidroBr soluções integradas' style={{ height: '4.375rem' }} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};