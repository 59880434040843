/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, isAxiosError } from "axios";
import { getApiSchema } from "../../services";
import {
    FluvioStation,
    Q710,
    TelemetricaStation,
    q710,
    unitFactor,
    UnitFactor,
    Area,
    ConsumoData,
    Outorga,
    FluvioStatioData,
    PaintMarker,
    TelemetricaStationData,
} from "../../core";

import { LatLng, LatLngLiteral } from "leaflet";

const dateStringToDate = (dateString: string) => {
    const [day, month, year, hour, minute, second] = dateString.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/)?.slice(1).map(Number) ?? [0, 0, 0, 0, 0, 0];
    return new Date(year, month - 1, day, hour, minute, second);
};

export interface ResultStationRequestError {
    error: string;
    message: string;
}

export const getDataTelemetricasCache = async (): Promise<{ [key: string]: TelemetricaStation; } | ResultStationRequestError> => {
    const api = getApiSchema();
    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.dataTelemetricasCache(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    const teleStations: { [key: string]: TelemetricaStation; } = {};

    for (const station in response.data) {
        const stationData = response.data[station].sort((a: any, b: any) => {
            // Primeiro, comparar se o valor é nulo ou não
            if (a.Vazao_Adotada === null && b.Vazao_Adotada !== null) return 1;
            if (a.Vazao_Adotada !== null && b.Vazao_Adotada === null) return -1;

            // Se ambos valores são nulos ou ambos não são nulos, comparar pela data
            const dateA = dateStringToDate(a.Data_Hora_Medicao ?? "01-01-1900 00:00:00");
            const dateB = dateStringToDate(b.Data_Hora_Medicao ?? "01-01-1900 00:00:00");

            return dateB.getTime() - dateA.getTime();

        });

        const dataMedicao = dateStringToDate(stationData[0]["Data_Hora_Medicao"]) ?? null;
        const dataAtualizacao = dateStringToDate(stationData[0]["Data_Atualizacao"]) ?? null;

        const coords: LatLngLiteral = {
            lat: stationData[0].lat,
            lng: stationData[0].lon,
        };

        const properties: TelemetricaStationData = {
            codigo: stationData[0].codigoestacao ?? 0,
            nome: station,
            dataAtualizacao: dataAtualizacao,
            dataMedicao: dataMedicao,
            areaM2: stationData[0]["area"] ?? null,
            vazao: stationData[0]["Vazao_Adotada"] ?? null,
            vazaoStatus: stationData[0]["Vazao_Adotada_Status"] ?? null,
            chuva: stationData[0]["Chuva_Adotada"] ?? null,
            chuvaStatus: stationData[0]["Chuva_Adotada_Status"] ?? null,
            cota: stationData[0]["Cota_Adotada"] ?? null,
            cotaStatus: stationData[0]["Cota_Adotada_Status"] ?? null,
        };

        const style: PaintMarker = {
            color: "primary",
        };
        const teleStation = new TelemetricaStation(coords, properties, style);

        const latLng = teleStation.getLatLng();

        teleStation.bindPopup(`
            <div style="border-radius: 0.938rem; padding: 0.313rem; background-color: #fff; font-family: 'Catamaran', sans-serif;">
                <strong>${station}</strong>
                <div>Telemétrica: ${teleStation.properties.codigo}</div>
                <div>Lat:${latLng.lat.toSignificantDigits("lat")}, Lon:${latLng.lng.toSignificantDigits("lon")}</div>
            </div>
        `);

        teleStations[station] = teleStation;
    }

    return teleStations;
};

export const getDataTelemetricasNow = async (): Promise<{ [key: string]: TelemetricaStation; } | ResultStationRequestError> => {
    const api = getApiSchema();
    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.dataTelemetricasNow(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    const teleStations: { [key: string]: TelemetricaStation; } = {};

    for (const station in response.data) {
        const stationData = response.data[station].sort((a: any, b: any) => {
            // Primeiro, comparar se o valor é nulo ou não
            if (a.Vazao_Adotada === null && b.Vazao_Adotada !== null) return 1;
            if (a.Vazao_Adotada !== null && b.Vazao_Adotada === null) return -1;

            // Se ambos valores são nulos ou ambos não são nulos, comparar pela data
            const dateA = dateStringToDate(a.Data_Hora_Medicao ?? "01-01-1900 00:00:00");
            const dateB = dateStringToDate(b.Data_Hora_Medicao ?? "01-01-1900 00:00:00");

            return dateB.getTime() - dateA.getTime();

        });

        const dataMedicao = dateStringToDate(stationData[0]["Data_Hora_Medicao"]) ?? null;
        const dataAtualizacao = dateStringToDate(stationData[0]["Data_Atualizacao"]) ?? null;

        const coords: LatLngLiteral = {
            lat: stationData[0].lat,
            lng: stationData[0].lon,
        };

        const properties: TelemetricaStationData = {
            codigo: stationData[0].codigoestacao ?? 0,
            nome: station,
            dataAtualizacao: dataAtualizacao,
            dataMedicao: dataMedicao,
            areaM2: stationData[0]["area"] ?? null,
            vazao: stationData[0]["Vazao_Adotada"] ?? null,
            vazaoStatus: stationData[0]["Vazao_Adotada_Status"] ?? null,
            chuva: stationData[0]["Chuva_Adotada"] ?? null,
            chuvaStatus: stationData[0]["Chuva_Adotada_Status"] ?? null,
            cota: stationData[0]["Cota_Adotada"] ?? null,
            cotaStatus: stationData[0]["Cota_Adotada_Status"] ?? null,
        };

        const style: PaintMarker = {
            color: "primary",
        };
        const teleStation = new TelemetricaStation(coords, properties, style);
        const latLng = teleStation.getLatLng();

        teleStation.bindPopup(`
            <div style="border-radius: 0.938rem; padding: 0.313rem; background-color: #fff; font-family: 'Catamaran', sans-serif;">
                <strong>${station}</strong>
                <div>Telemétrica: ${teleStation.properties.codigo}</div>
                <div>Lat:${latLng.lat.toSignificantDigits("lat")}, Lon:${latLng.lng.toSignificantDigits("lon")}</div>
            </div>
        `);

        teleStations[station] = teleStation;
    }

    return teleStations;
};

export const getDataConvencional = async (): Promise<{ [key: string]: FluvioStation; } | ResultStationRequestError> => {
    const api = getApiSchema();
    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.q710(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    const fluStations: { [key: string]: FluvioStation; } = {};

    for (const station in response.data) {
        const stationData = response.data[station];

        const unitAreaKm: UnitFactor = { numerador: Area.km2 };

        const q710: Q710 = {
            AAlfa: stationData["A_alfa"],
            BAlfa: stationData["B_alfa"],
            K: stationData["K"],
            QGumbel: stationData["Q_gumbel"],
            QWeibull: stationData["Q_weibull"],
            alfa: stationData["alfa"],
            alfa2: stationData["alfa2"],
            anoHidrologico: stationData["anohidrologico"],
            anosComFalha: stationData["anosComFalha"],
            anosNaSerie: stationData["anosNaSerie"],
            coefAssimetria: stationData["coef_assimetria"],
            correlGum: stationData["correl_gum"],
            correlWei: stationData["correl_wei"],
            desvioPadrao: stationData["desvio_padrao"],
            estacaoCodigo: stationData["estacao_codigo"],
            id: stationData["id"],
            lnGamma1: stationData["ln_gamma1"],
            lnGamma2: stationData["ln_gamma2"],
            media: stationData["media"],
            lon: stationData["A_alfa"],
            lat: stationData["A_alfa"],
            mu2: stationData["mu2"],
            parametros: stationData["parametros"],
            x: stationData["x"],
            y: stationData["y"],

        };

        const vazao = (q710.correlGum > q710.correlWei ? q710.QGumbel : q710.QWeibull) ?? 0;

        const qEspecifica710: q710 = {
            ...q710,
            area: stationData["area"],
            vazaoArea: vazao / (stationData["area"] * unitFactor(unitAreaKm)),
        };

        const coords: LatLngLiteral = {
            lat: stationData.lat,
            lng: stationData.lon,
        };

        const properties: FluvioStatioData = {
            codigo: stationData.estacao_codigo ?? 0,
            nome: station,
            q710: q710,
            qEspecifica710: qEspecifica710,
            areaM2: stationData["area"] ?? null,
        };

        const style: PaintMarker = {
            color: "primary",
            bgColor: "white",
            iconSize: 20,
        };

        const fluStation = new FluvioStation(coords, properties, style);
        const latLng = fluStation.getLatLng();
        fluStation.bindPopup(`
            <div style="border-radius: 0.938rem; padding: 0.313rem; background-color: #fff; font-family: 'Catamaran', sans-serif;">
                <strong>${station}</strong>
                <div>Convencional: ${fluStation.properties.codigo}</div>
                <div>Lat:${latLng.lat.toSignificantDigits("lat")}, Lon:${latLng.lng.toSignificantDigits("lon")}</div>
            </div>
        `);

        fluStations[station] = fluStation;
    }

    return fluStations;
};

export const getConsumoData = async (): Promise<{ [key: string]: ConsumoData; } | ResultStationRequestError> => {
    const api = getApiSchema();
    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.outorgas(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    const consumoData: { [key: string]: ConsumoData; } = {};

    for (const rio in response.data) {
        const rioData = response.data[rio];

        const consumoTotal = rioData.consumo_total ?? 0;
        const numeroOutorgas = rioData.numero_outorgas ?? 0;

        const outorgas: Outorga[] = rioData.outorgas[0].features.map((outorga: any) => {
            const style: PaintMarker = {
                color: "primary",
                bgColor: "white",
                iconSize: 20,
            };

            const layer = new Outorga(
                new LatLng(outorga.geometry.coordinates[1], outorga.geometry.coordinates[0]),
                outorga.properties,
                style,
            );

            layer.bindPopup(
                `<div style="border-radius: 0.938rem; padding: 0.313rem; background-color: #fff; font-family: 'Catamaran', sans-serif;">
                    <strong>${rio}</strong>
                    <div>Outorga Portaria ${layer.properties.portaria ?? "N/A"}</div>
                    <div>
                        <a href="${layer.properties.link ?? "N/A"}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">Ver detalhes</a>
                    </div>
                </div>`
            );

            return layer;
        });

        consumoData[rio] = {
            consumoTotal,
            numeroOutorgas,
            outorgas
        };
    }

    return consumoData;
};
