import React, { useState } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    Menu,
    MenuItem,
    Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CheckBox } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSelectMap: (mapName: string) => void;
}

export const MapConfigModal: React.FC<ModalProps> = ({
    open,
    onClose,
    onSelectMap,
}) => {
    const [selectedMap, setSelectedMap] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState(false);
    const theme = useTheme();

    const primaryColor = theme.palette.primary.main;
    const secondaryColor = theme.palette.secondary.main;
    const bkColor = theme.palette.background.default;

    const maps = {
        "Fundo Branco": "",
        "Open Street Map": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "Google Maps": "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
        "Google Satellite": "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        "Google Terrain": "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    };

    const handleMenuItemClick = (mapName: string) => {
        setSelectedMap(mapName);
        onSelectMap(mapName);
        handleMenuClose();
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#DCE8EC",
                    padding: 20,
                    borderRadius: 4,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    width: "20vw",
                    height: "20vh",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        marginTop: "-20vh",
                        ml: -15,
                    }}
                >
                    <Typography variant="h4" align="center" gutterBottom>
                        Configurações do mapa
                    </Typography>
                </Box>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            ml: -18,
                        }}
                    >
                        <Button
                            onClick={handleMenuOpen}
                            variant="contained"
                            sx={{
                                backgroundColor: "transparent",
                                textTransform: "none",
                                color: "black",
                                width: "135%",
                                "&:hover": { backgroundColor: "#C2D7DE" },
                            }}
                        >
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    textAlign: "left",
                                }}
                            >
                                {selectedMap || "Selecionar mapa"}
                            </Typography>
                            <MoreVertIcon />
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            ml: -18,
                            mt: 2,
                            borderRadius: 4,
                            width: "195%",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px 20px",
                            "&:hover": { backgroundColor: "#F0F0F0" },
                        }}
                    >
                        <CheckBox />
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: "center",
                            }}
                        >
                            Mostrar a área de drenagem do ponto de estudo
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            ml: -18,
                            mt: 2,
                            borderRadius: 4,
                            width: "195%",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px 20px",
                            "&:hover": { backgroundColor: "#F0F0F0" },
                        }}
                    >
                        <CheckBox />
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: "center",
                            }}
                        >
                            Mostrar os trechos do ponto de estudo
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            ml: -18,
                            mt: 2,
                            borderRadius: 4,
                            width: "195%",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px 20px",
                            "&:hover": { backgroundColor: "#F0F0F0" },
                        }}
                    >
                        <CheckBox />
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: "center",
                            }}
                        >
                            Mostrar os trechos da estação
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            ml: -18,
                            mt: 2,
                            borderRadius: 4,
                            width: "195%",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px 20px",
                            "&:hover": { backgroundColor: "#F0F0F0" },
                        }}
                    >
                        <CheckBox />
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: "center",
                            }}
                        >
                            Mostrar as massas d'agua da estação selecionada
                        </Typography>
                    </Box>

                    <Menu
                        id="map-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {Object.keys(maps).map((mapName) => (
                            <MenuItem
                                key={mapName}
                                onClick={() => handleMenuItemClick(mapName)}
                            >
                                {mapName}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 20,
                            right: 20,
                            zIndex: 1000,
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                textTransform: "none",
                                backgroundColor: "transparent",
                                color: primaryColor,
                                border: `1px solid ${primaryColor}`,
                                "&:hover": { backgroundColor: primaryColor, color: "white" },
                                borderRadius: 5,
                                mr: 1,
                            }}
                        >
                            Feito
                        </Button>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                textTransform: "none",
                                backgroundColor: "background.paper",
                                color: primaryColor,
                                border: `1px solid ${primaryColor}`,
                                "&:hover": { backgroundColor: primaryColor, color: "white" },
                                borderRadius: 5,
                            }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
