import React from 'react';
import { Typography, Card, CardContent, Grid, useMediaQuery, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import FormNoModal from '../formularioCadastro/fomularioNoModal';

export const FinalidadeCards: React.FC = () => {
    const theme = useTheme();
    const isCustomScreen = useMediaQuery(theme.breakpoints.up('custom'));
    const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <Helmet>
                <title>HIDROlogic - Módulo de Cálculo e Observatório Hidrológico</title>
                <meta
                    name="description"
                    content="Explore o módulo de cálculo e o observatório hidrológico do HIDROlogic para análise e gestão eficaz dos recursos hídricos."
                />
                <meta
                    name="keywords"
                    content="HIDROlogic, módulo de cálculo, observatório hidrológico, recursos hídricos, Minas Gerais"
                />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": "HIDROlogic",
                        "description": "Um sistema robusto para a análise e gestão eficaz dos recursos hídricos.",
                        "brand": "HIDROlogic",
                        "offers": {
                            "@type": "Offer",
                            "url": "https://hidrologic.com.br"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <Grid
                container
                spacing={2}
                sx={{ margin: theme.spacing(2), display: 'flex', flexDirection: 'row' }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                >
                    <Grid item xs={12} md={10} sx={{ flexGrow: 1 }}>
                        <Card sx={{ height: '100%', mt: -2 }}>
                            <CardContent sx={{ textAlign: 'left', height: '100%' }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                >
                                    Sobre o Hidrologic
                                </Typography>
                                <Divider sx={{ border: 'solid 0.063rem' }} />
                                <Typography
                                    variant={'body1'}
                                    component="div"
                                    sx={{
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                        textAlign: 'justify',
                                        mt: 2,
                                    }}
                                >
                                    O{' '}
                                    <span
                                        style={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    >
                                        Hidrologic
                                    </span>{' '}
                                    é um sistema desenvolvido para a realização de estudos hídricos
                                    de forma simples e objetiva, utilizando os dados espaciais
                                    hidrológicos mais atualizados disponíveis.
                                    <div>
                                        O{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            Observatório
                                        </span>{' '}
                                        é um{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            módulo de monitoramento
                                        </span>{' '}
                                        do Hidrologic, criado para acompanhar a{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            condição hídrica
                                        </span>{' '}
                                        das principais bacias de Minas Gerais. O cálculo da{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            condição hídrica
                                        </span>{' '}
                                        das bacias é realizado por meio da{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            regionalização das vazões de referência
                                        </span>{' '}
                                        Q7,10 e das{' '}
                                        <span
                                            style={{
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            medições instantâneas das estações telemétricas
                                        </span>
                                        .
                                    </div>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={10} sx={{ flexGrow: 1, mt: isCustomScreen ? 9 : 0 }}>
                        <Card sx={{ height: '100%' }}>
                            <CardContent sx={{ textAlign: 'left', height: '100%' }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                >
                                    Fontes de dados
                                </Typography>
                                <Divider sx={{ border: 'solid 0.063rem' }} />
                                <Typography
                                    variant={'body1'}
                                    component="div"
                                    sx={{
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                        textAlign: 'justify',
                                        mt: 2,
                                    }}
                                >
                                    <div>
                                        <span>
                                            Dados hidrométricos Telemétricos (
                                            <a
                                                href="https://www.snirh.gov.br/hidrotelemetria/Default.html"
                                                style={{ color: '#551A8B' }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                ANA
                                            </a>
                                            )
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Dados hidrométricos Convencionais (
                                            <a
                                                href="https://www.snirh.gov.br/hidroweb/apresentacao"
                                                style={{ color: '#551A8B' }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                ANA
                                            </a>
                                            )
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Dados de captação/bombeamento de Outorgas (
                                            <a
                                                href="http://sistemas.meioambiente.mg.gov.br/licenciamento/site/lista-outorgas"
                                                style={{ color: '#551A8B' }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                SEMAD
                                            </a>
                                            )
                                        </span>
                                    </div>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        maxWidth: '75rem',
                    }}
                >
                    <Card
                        sx={{
                            height: '100%',
                            flexGrow: 1,
                            backgroundColor: theme.palette.background.paper,
                            border: '0.125rem solid #000',
                            boxShadow: theme.shadows[5],
                            borderRadius: '0.938rem',
                            margin: '0 auto',
                            mt: isMdScreen ? -3 : 1,
                        }}
                    >
                        <CardContent sx={{ height: '100%' }}>
                            <FormNoModal />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
