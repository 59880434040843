import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SistemPage } from '../pages';
import { EstacoesFluviometricas } from '../components/hidrico/index';

export const SystemRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/sistema" />} />
            <Route path="/" element={<SistemPage />} />
            <Route path="/regionalizacao/:id/estacoes/fluviometricas" element={<EstacoesFluviometricas />} />
            <Route path='/teste' element={<EstacoesFluviometricas />} />
        </Routes>
    );
};
