import React, { useState, ChangeEvent, FocusEvent } from "react";
import {
    InputAdornment,
    IconButton,
    TextField,
} from "@mui/material";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

export interface SearchFieldProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

export const SearchField: React.FC<SearchFieldProps> = ({ value, onChange, placeholder = "Search" }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => setTimeout(() => setIsFocused(false), 100);
    const handleIconClick = () => console.log("Ícone clicado");

    return (
        <TextField
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            sx={{ borderRadius: 3 }}
            InputProps={{
                endAdornment: (
                    isFocused && (
                        <InputAdornment position="end">
                            <IconButton onMouseDown={(event: { preventDefault: () => any; }) => event.preventDefault()} onClick={handleIconClick}>
                                <ControlPointDuplicateIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                ),
            }}
        />
    );
};
