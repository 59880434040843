import React, { useState } from "react";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemText,
    useTheme,
    Typography,
    Icon,
    Tooltip,
} from "@mui/material";
import { Color } from "../../../core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import WaterIcon from "@mui/icons-material/Water";

import { NoStudyModal } from "../../estudo/noStudyModal";

export function BalancoHidrico({ expanded }: { expanded: boolean; }) {
    const theme = useTheme();
    const textColor = new Color(theme.palette.background.paper);

    const [open, setOpen] = useState(expanded);
    const [openModal, setOpenModal] = useState(false);

    const toggleExpand = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <div>
            <Tooltip title="Balanço Hídrico">
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems="center"
                    onClick={toggleExpand}
                    style={{ cursor: "pointer", color: textColor.toHex }}
                >
                    <Icon
                        sx={{
                            height: "46px",
                            width: "46px",
                            color: textColor.toHex,
                            marginRight: "2vw"
                        }}
                    >
                        swap_vert
                    </Icon>
                    <Typography sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                    }}>Balanço Hídrico</Typography>
                    {open ? (
                        <KeyboardArrowUpIcon style={{ marginLeft: "8vw" }} />
                    ) : (
                        <KeyboardArrowDownIcon style={{ marginLeft: "8vw" }} />
                    )}
                </Box>
            </Tooltip>
            <Collapse in={expanded && open}>
                <Box display="flex">
                    <List>
                        <ListItem button style={{ color: textColor.toHex }}>
                            <ListItemText
                                onClick={() => setOpenModal(true)}
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex }}
                                    >
                                        Oferta Hídrica
                                    </Typography>
                                }
                            />
                            <WaterDropIcon style={{ fontSize: 20, marginLeft: "14vw" }} />
                        </ListItem>
                        <ListItem
                            button
                            style={{ marginTop: "3vh", color: textColor.toHex }}
                        >
                            <ListItemText
                                onClick={() => setOpenModal(true)}
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex }}
                                    >
                                        Demanda Hídrica
                                    </Typography>
                                }
                            />
                            {"(em breve)L/s"}
                            <LocalDrinkIcon style={{ fontSize: 20, marginLeft: "1vw" }} />
                        </ListItem>
                        <ListItem button style={{ color: textColor.toHex }}>
                            <ListItemText
                                onClick={() => setOpenModal(true)}
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontSize: 14,
                                            marginTop: "1vh",
                                            color: textColor.toHex,
                                        }}
                                    >
                                        Disponibilidade Hídrica
                                    </Typography>
                                }
                            />
                            <WaterIcon style={{ fontSize: 20 }} />
                        </ListItem>
                    </List>
                </Box>
            </Collapse>

            <NoStudyModal openModal={openModal} onCloseModal={setOpenModal} />
        </div>
    );
}