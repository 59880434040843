import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {
    TextField,
    Button,
    Box,
    Grid,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useTheme,
} from '@mui/material';
import PhoneInput from './phoneInput';
import { SubscriptionForm, sendSubscriptionForm } from './formService';
import { Helmet } from 'react-helmet-async';

interface FormProps {
    autoFillData?: SubscriptionForm;
}

const FormNoModal: React.FC<FormProps> = ({ autoFillData }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState<SubscriptionForm>({
        nome: '',
        telefone: '',
        email: '',
        empresa: '',
        areaInteresse: '',
        comentarios: '',
    });
    const [successOpen, setSuccessOpen] = useState<boolean>(false);
    const [errorOpen, setErrorOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (autoFillData) {
            setFormData(autoFillData);
        }
    }, [autoFillData]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!formData.email) {
            setMessage('Email is required');
            setErrorOpen(true);
            return;
        }

        if (formData.comentarios.length > 1000) {
            setMessage('Comments must be less than 1000 characters');
            setErrorOpen(true);
            return;
        }

        const sanitizedPhone = formData.telefone.replace(/[()\-\s]/g, '');

        try {
            await sendSubscriptionForm({ ...formData, telefone: sanitizedPhone });
            setMessage('Entraremos em contato em breve!');
            setSuccessOpen(true);
            setFormData({
                nome: '',
                telefone: '',
                email: '',
                empresa: '',
                areaInteresse: '',
                comentarios: '',
            });
        } catch (error) {
            setMessage('Algum erro ocorreu.');
            setErrorOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setSuccessOpen(false);
        setErrorOpen(false);
    };

    return (
        <>
            <Helmet>
                <title>Contato - HIDROlogic</title>
                <meta name="description" content="Entre em contato conosco para mais detalhes sobre nossos serviços e soluções em hidrologia." />
                <meta name="keywords" content="contato, HIDROlogic, hidrologia, recursos hídricos, Minas Gerais" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ContactPage",
                        "name": "Contato - HIDROlogic",
                        "description": "Entre em contato conosco para mais detalhes sobre nossos serviços e soluções em hidrologia.",
                        "url": "https://hidrologic.com.br/contato"
                    }
                    `}
                </script>
            </Helmet>
            <Box
                sx={{
                    margin: '0 auto',
                    mt: 4,
                    width: '90%',
                    maxWidth: '31.25rem',
                }}
            >
                <Typography variant="h6" align="center" gutterBottom fontWeight={'bold'}>
                    Entre em contato com nossos especialistas em desenvolvimento e otimize a gestão de recursos hídricos na sua região.
                </Typography>
                <Divider sx={{
                    border: 'solid 0.063rem'
                }} />
                <form onSubmit={handleSubmit} style={{ marginTop: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="nome"
                                label="Nome"
                                type="text"
                                fullWidth
                                value={formData.nome}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                label="Telefone"
                                name="telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="email"
                                label="E-mail"
                                type="email"
                                fullWidth
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="empresa"
                                label="Empresa"
                                type="text"
                                fullWidth
                                value={formData.empresa}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="areaInteresse"
                                label="Área de Interesse"
                                type="text"
                                fullWidth
                                value={formData.areaInteresse}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="comentarios"
                                label="Comentários"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                value={formData.comentarios}
                                onChange={handleChange}
                                inputProps={{ maxLength: 1000 }}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit" color="primary">
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Dialog
                open={successOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        fontFamily: theme.typography.fontFamily,
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Sucesso"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" >
                        Formulário enviado com sucesso!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={errorOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Erro"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Desculpe, algum erro ocorreu
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormNoModal;