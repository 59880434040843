export type significantType = "area" | "vazao" | "vazaoEspecifica" | "lat" | "lon" | "lng";

Number.prototype.toSignificantDigits = function (dataType: significantType) {
    if (!this || isNaN(this as number)) return "NaN";

    const strVal = this.toFixed(24);
    const digitsBeforeDot = strVal.split(".")[0];
    const digitsAfterDot = strVal.split(".")[1];
    const nDigits = digitsBeforeDot.split(".")[0].length;

    let nDigitsAfterDot = 0;
    if (dataType === "area" && nDigits <= 1) nDigitsAfterDot = 1;
    else if (dataType === "lat" || dataType === "lon" || dataType === "lng") nDigitsAfterDot = 6;
    else if (dataType === "vazao" && nDigits <= 3) nDigitsAfterDot = 3 - nDigits;
    else if (dataType === "vazaoEspecifica" && nDigits <= 3) nDigitsAfterDot = 3 - nDigits;

    const strFormatted = digitsBeforeDot + "." + digitsAfterDot.slice(0, nDigitsAfterDot);
    return strFormatted;
};