import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { DEBUG } from "./enviroment";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
);

// Métricas do app, para saber se o status de latência e etc dele está bom.
if (!DEBUG) {
    reportWebVitals(console.log);
}
