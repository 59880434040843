import axios from "axios";
import { API_URL } from "../../enviroment";
import { SubscriptionForm } from "../../components/observatorio/formularioCadastro/formService";

const areas = async (estado: string) => {
    if (estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/areas/${estado}`);
};

const trechos = async (estado: string) => {
    if (estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/trechos/${estado}`);
};

const q710 = async (estado: string) => {
    if (estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/q_710/${estado}`);
};

const dataTelemetricasCache = async (estado?: string) => {
    if (estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/data_telemetricas_cache/${estado}`);
};

const dataTelemetricasNow = async (estado?: string) => {
    if (estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/data_telemetricas_now/${estado}`);
};

const envioFormulario = async (data: SubscriptionForm) => {
    return await axios.post(`${API_URL}/observatorio/subscription`, data);
};

const dataOUtorgas = async (estado?: string) => {
    if(estado === "") estado = "MG";
    return await axios.get(`${API_URL}/observatorio/consumo/${estado}`);
};

export const ApiObservatorio = {
    areas: areas,
    trechos: trechos,
    q710: q710,
    dataTelemetricasCache: dataTelemetricasCache,
    dataTelemetricasNow: dataTelemetricasNow,
    envioFormulario: envioFormulario,
    outorgas: dataOUtorgas,
};