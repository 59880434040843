import React, { ChangeEvent } from "react";
import {
    Box,
    Checkbox,
    Typography,
} from "@mui/material";

export interface CheckboxWithLabelProps {
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({ checked, onChange, label }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox checked={checked} onChange={onChange} sx={{ ml: -3 }} />
        <Typography sx={{ fontSize: '16px', marginLeft: 2 }}>{label}</Typography>
    </Box>
);