import React, { useEffect, useMemo, useState } from "react";
import { Modal, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Color } from "../../../core";
import { Column, Row } from "../layout/column_and_row";
import HBRLogo from "../../../assets/hidrobrLogo.png";

interface LoadingOpts {
    open: boolean;
    text?: string;
}

export const HidroLoading: React.FC<LoadingOpts> = ({ open, text }) => {
    const theme = useTheme();

    // const bkColor = new Color(theme.palette.background.default);
    const textColor = new Color(theme.palette.secondary.main);

    return (
        <Modal open={open}>
            <Grid>
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "156px",
                    height: text !== undefined ? "186px" : "176px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Column>
                        <Box sx={{
                            height: "100px",
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <img
                                src={HBRLogo}
                                alt="Logo HBR"
                                style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>

                        {text === undefined && <LoadingBarsAnimation />}

                        {text !== undefined && (<div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                            }}>
                            <Typography variant="subtitle2" fontWeight={550} color={textColor.toHex} sx={{
                                userSelect: 'none',
                                whiteSpace: "normal",
                            }}>
                                {text}
                            </Typography>
                            <div style={{ width: "10px" }} />
                            <LoadingPointsAnimation />
                        </div>)}

                    </Column>
                </Box>
            </Grid>
        </Modal>
    );
};


function LoadingBarsAnimation() {
    const theme = useTheme();
    const barColor = new Color(theme.palette.secondary.main);

    const min = 7;
    const max = 36;
    const time = 800; // tempo em ms

    const [stage, setStage] = useState(0);
    const multiplicadores = useMemo(() => [1, 2, 3, 2, 1], []);
    const interval = (max - min) / Math.max(...multiplicadores);

    useEffect(() => {

        const intervalId = setInterval(() => {
            setStage(stage + 1);
            if (stage > 6) setStage(0);

            const ultimoItem = multiplicadores.pop() ?? 1;
            multiplicadores.unshift(ultimoItem);

        }, time / multiplicadores.length);

        return () => clearInterval(intervalId);
    }, [stage, multiplicadores]);

    return (
        <Box sx={{
            height: `${max + 5}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {multiplicadores.map((multi, i) => {
                    return (<Box
                        key={i}
                        sx={{
                            margin: "1.8px",
                            width: '5px',
                            bgcolor: barColor.toHex,
                            height: `${min + interval * multi}px`,
                            transition: `height ${time}ms cubic-bezier(0, 1.5, 1, 1)`,
                            borderRadius: "5px",
                        }}
                    />);
                })}
            </div>
        </Box>
    );
}

function LoadingPointsAnimation() {
    const theme = useTheme();
    const barColor = new Color(theme.palette.secondary.main);

    const min = 5;
    const max = 20;
    const time = 800; // tempo em ms

    const [stage, setStage] = useState(0);
    const multiplicadores = useMemo(() => [1, 2, 1], []);
    const interval = (max - min) / Math.max(...multiplicadores);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setStage(stage + 1);
            if (stage > multiplicadores.length) setStage(0);

            const ultimoItem = multiplicadores.pop() ?? 1;
            multiplicadores.unshift(ultimoItem);

        }, time / multiplicadores.length);

        return () => clearInterval(intervalId);
    }, [stage, multiplicadores]);

    return (
        <Box sx={{
            height: `${max + 5}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Row style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {multiplicadores.map((multi, i) => {
                    return (<Box
                        key={i}
                        sx={{
                            margin: "0.8px",
                            width: '8px',
                            height: `${min + interval * multi}px`,
                            transition: `height ${time}ms cubic-bezier(0, 1.5, 1, 1)`,
                        }}
                    >
                        <Box sx={{
                            backgroundColor: barColor.toHex,
                            width: '6px',
                            height: '6px',
                            borderRadius: "6px",
                        }} />
                    </Box>);
                })}
            </Row>
        </Box>
    );
}