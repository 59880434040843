import axios, { AxiosError, AxiosResponse } from "axios";
import { HidroCryper } from "../../core";
import { requestRefreshAuthToken } from "./apiFunctions";

export function onResponse(response: AxiosResponse, setAuthToken: React.Dispatch<React.SetStateAction<string>>): AxiosResponse {
    if (!response) {
        return response;
    }

    // Sempre que receber um novo token
    if (response.config.url?.endsWith("/auth/login") && response.status === 200 && response.data !== undefined) {
        const encrypted = localStorage.getItem("token") ?? "";
        const startToken = HidroCryper.decrypt(encrypted);
        let token: string = startToken;

        if (response.data.token !== undefined) {
            token = response.data.token;
            token = token.replace("Bearer ", "");

            const criptData = HidroCryper.encrypt(token);
            localStorage.setItem("token", criptData);
        }

        if (response.data.refresh_token !== undefined) {
            let refreshToken: string = response.data.refresh_token;
            refreshToken = refreshToken.replace("Bearer ", "");

            const criptData = HidroCryper.encrypt(refreshToken);
            localStorage.setItem("refreshToken", criptData);
        }

        // Setando o token unico e exclusivamente para notificar o websocket!!!!
        setAuthToken(token);
    }

    // Quando sair
    if (response.config.url?.endsWith("/auth/logout") && response.status === 200) {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
    }

    return response;
}

// Atualizar o token
export async function onError(error: AxiosError | unknown, setAuthToken: React.Dispatch<React.SetStateAction<string>>) {
    if (!axios.isAxiosError(error)) return error;
    if (!error.config || !error.response) return error;
    if (error.config.url?.endsWith("/auth/refresh")) return error;

    const tokenErros = [422];
    const tokenCodes: string[] = [];
    if (tokenErros.indexOf(error.response.status) < 0) return error;

    const tokenRefreshed = await requestRefreshAuthToken();
    if (!tokenRefreshed) return error;

    const encrypted = localStorage.getItem("token") ?? "";
    const token = HidroCryper.decrypt(encrypted);

    error.config.headers.Authorization = token;
    const result = axios.request(error.config);

    // Setando o token unico e exclusivamente para notificar o websocket!!!!
    setAuthToken(token);

    return result;
}