import { Area } from "./area";
import { Distancia } from "./distancia";
import { Tempo } from "./tempo";
import { Volume } from "./volume";

/**
 * Função para calcular o fator de unidade com base em um fator unitário.
 * 
 * m³, s, m, m² entre outros.
 * 
 * Esta função calcula o fator de unidade utilizando um `UnitFactor`.
 *
 * @param {UnitFactor} unitFactor - O objeto contendo os componentes do fator de unidade.
 * 
 * @returns {number} O valor calculado do fator de unidade.
 * 
 * @example
 * // Exemplo de uso da função unitFactor
 * const unidade: UnitFactor = {
 *     numerador: Volume.m3,
 *     denominador: [] 
 * };
 * const resultado = unitFactor(unidade);
 * console.log(resultado);
 * //1
 * 
 * @example
 * // Exemplo de uso da função unitFactor
 * const unidade: UnitFactor = {
 *     numerador: Distancia.km,
 *     denominador: [Tempo.horas] 
 * };
 * const resultado = unitFactor(unidade);
 * console.log(resultado);
 * //1/3.6 ou 0.2778
 * 
 * @example
 * // Exemplo de uso da função unitFactor
 * const unidade: UnitFactor = {
 * numerador: Volume.m3,
 * denominador: [Tempo.segundos, Area.km2]
 * };
 * const resultado = unitFactor(unidade);
 * console.log(resultado);
 * //1/[1*1000000] ou 1E-6
 */
export function unitFactor(unitFactor: UnitFactor): number {
    const denominadorVal = _getValue(unitFactor.denominador);
    const numeradorVal = _getValue(unitFactor.numerador);
    return denominadorVal / numeradorVal;
}

/**
 * Fator de unidade
 * 
 * Representa um fator de multiplicação para conversão entre unidades.
 * 
 * @example
 * 
* // Exemplo de uso com unidade de m³
 * const unidade: UnitFactor = {
 *     numerador: Volume.m3,
 *     denominador: undefined 
 * };
 * 
 * @example
 * // Exemplo de uso da função unitFactor com a unidade de km/h
 * const unidade: UnitFactor = {
 *     numerador: Distancia.km,
 *     denominador: [Tempo.horas] 
 * };
 * 
 * @example
 * // Exemplo de uso da função unitFactor com a unidade m³/s.km² (Vazão específica)
 * const unidade: UnitFactor = {
 * numerador: Volume.m3,
 * denominador: [Tempo.segundos, Area.km2]
 * };
 * 
 * @interface UnitFactor
 */
export interface UnitFactor {
    /**
     * Numerador da unidade composta.
     * Pode ser uma única unidade ou um array de unidades.
     * 
     * @type {(Area | Distancia | Tempo | Volume) | (Area | Distancia | Tempo | Volume)[]}
     */
    numerador: (Area | Distancia | Tempo | Volume) | (Area | Distancia | Tempo | Volume)[];

    /**
     * Denominador da unidade composta.
     * Pode ser uma única unidade ou um array de unidades.
     * Opcional.
     * 
     * @type {undefined | (Area | Distancia | Tempo | Volume) | (Area | Distancia | Tempo | Volume)[]}
     */
    denominador?: (Area | Distancia | Tempo | Volume) | (Area | Distancia | Tempo | Volume)[];
}

// Função auxiliar, apenas para realizar as multplicações do unitFactor
function _getValue(value?: number | number[]): number {
    if (typeof value === 'undefined') {
        return 1;
    }
    if (typeof value === 'number') {
        return value;
    }

    return value.reduce((acc, curr) => acc * curr, 1);
}