import { AxiosResponse, isAxiosError } from "axios";
import { AreaGeom, getAreaGeomByGeoJson } from "../../core/layers_map";
import { getApiSchema } from "../../services";
import { styleNoData, styleState } from "./styles";

export interface ResultArea {
    limiteEstado: AreaGeom;
    areasDrenagem: AreaGeom[];
}
export interface ResultAreaRequestError {
    error: string;
    message: string;
}

export const getAreas = async (): Promise<ResultArea | ResultAreaRequestError> => {
    const api = getApiSchema();

    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.areas(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data || !response.data.limite_estado) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    const limiteEstado = response.data.limite_estado;

    const properties = limiteEstado.features[0].properties;
    properties.nome = state ?? "MG";

    const estado = getAreaGeomByGeoJson(limiteEstado.features[0], styleState);

    const areas = response.data?.areas?.features ?? { "features": [] };

    const areasGeom: AreaGeom[] = [];
    if (!areas.length) {
        return {
            limiteEstado: estado,
            areasDrenagem: areasGeom,
        };
    }

    for (const area of areas) {
        area.properties.areaM2 = area.properties.AreaCalculada;
        area.properties.bindTooltip = true;
        const areaDrenagem = getAreaGeomByGeoJson(area, styleNoData);
        areasGeom.push(areaDrenagem);
    }

    return {
        limiteEstado: estado,
        areasDrenagem: areasGeom,
    };
};