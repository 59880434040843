import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { getApiSchema } from "../../../services";

// Contexto dos dados do usuário
interface User {
    id: string;
    displayName: string;
    email: string;
    inscricao?: string;
    provider?: string;
    active: boolean;
    adm: boolean;
    photoURL?: string;
    estudoId?: string | null;
    companyId?: string | null;
}

interface UserContextData {
    user?: User;
    setUserData: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const useUserContext = (): UserContextData => useContext(UserContext);
const UserContext = createContext({} as UserContextData);

// Contexto do erro de conexão caso ocorra
interface RequestUserError {
    error: string;
    message?: string;
}

interface RequestUserErrorContextData {
    requestUserErrorData?: RequestUserError;
}

export const useRequestUserErrorContext = (): RequestUserErrorContextData => useContext(RequestUserErrorContext);
const RequestUserErrorContext = createContext({} as RequestUserErrorContextData);

// Provedor do contexto do usuário
export const UserProvider: React.FC<Props> = ({ children }) => {
    const api = getApiSchema();

    const [user, setUserData] = useState<User | undefined>(undefined);
    const [requestUserErrorData, setRequestUserErrorData] = useState<RequestUserError | undefined>(undefined);

    useEffect(() => {
        async function getUser() {
            if (user !== undefined) return;

            try {
                const dataUserInRequest = await api.user.getUser();

                if (dataUserInRequest.status === 200) {
                    let estudo = dataUserInRequest.data.estudo ?? undefined;
                    if (estudo === "None") {
                        estudo = null;
                    }

                    setUserData({
                        id: dataUserInRequest.data.id ?? undefined,
                        displayName: dataUserInRequest.data.nome ?? undefined,
                        email: dataUserInRequest.data.email ?? undefined,
                        inscricao: dataUserInRequest.data.inscricao ?? undefined,
                        provider: dataUserInRequest.data.provedor ?? undefined,
                        active: dataUserInRequest.data.ativo ?? undefined,
                        adm: false,
                        photoURL: dataUserInRequest.data.photo ?? undefined,
                        estudoId: estudo,
                        companyId: dataUserInRequest.data.empresa.id,
                    });
                    setRequestUserErrorData(undefined);

                }
            } catch (e) {
                if (axios.isAxiosError(e) && e.response?.data && e.response.data.error) {
                    setRequestUserErrorData({
                        error: e.response.data.error,
                        message: e.response.data.message,
                    });
                } else {
                    setRequestUserErrorData({
                        error: "Erro obtendo os dados do usuário!",
                        message: `${e}`,
                    });
                }
            }
        }

        if ((localStorage.getItem("refreshToken") ?? "") !== "") {
            getUser();
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, setUserData }}>
            <RequestUserErrorContext.Provider value={{ requestUserErrorData }}>
                {children}
            </RequestUserErrorContext.Provider>
        </UserContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};
