import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const ajustarCurva = async (x: number[], y: number[], funcao: string): Promise<AxiosResponse> => {
    const data = {
        x: x,
        y: y,
        func: funcao,
    };

    return await axios.post(`${API_URL}/toolbox/estatistica/ajustar_curva`, { data });
};

export const ApiEstatistica = {
    ajustarCurva: ajustarCurva,
};
