import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserContext } from "../user/user";
import axios from "axios";
import { getApiSchema } from "../../../services";

// Contexto caso de erro na obtenção do estudo
interface RequestEstudoError {
    error: string;
    message: string;
}

const RequestEstudoErrorContext = createContext({} as RequestEstudoError | undefined);
export const useRequestEstudoErrorContext = (): RequestEstudoError | undefined => useContext(RequestEstudoErrorContext);

// Contexto dos dados do estudo
interface Estudo {
    nome: string;
    id: string;

    lat: number;
    lon: number;
    crs: string;

    anoHidrologico?: number[];

    criadoPor?: string;
    criadoEm?: Date;
    ativo?: boolean;

    // Regionalizações
    regionalizacao?: number;
    regionalizacoes?: number[];

    // Outorgas
    outorgas?: number[];

    // Balanço Hídrico
    porcQ710?: number;

    //Área de estudo
    areaId?: number;
}

interface EstudoContextData {
    estudo?: Estudo | null;
    setEstudo: React.Dispatch<React.SetStateAction<Estudo | undefined | null>>;
}

const EstudoContext = createContext({} as EstudoContextData);

export const useEstudoContext = (): EstudoContextData => useContext(EstudoContext);

// Provider dos dados do estudo
export const EstudoProvider: React.FC<Props> = ({ children }) => {
    // Variável que guarda o valor do estudo
    // Caso o estudo seja NULO é por que não existe um estudo para o usuário
    // Caso o estudo seja UNDEFINED o usuário possúi um estudo cadastrado, porém ele não for retornado corretamente
    const [estudo, setEstudo] = useState<Estudo | undefined | null>(undefined);

    // Dados do erro caso ele ocorra
    const [requestEstudoError, setRequestEstudoError] = useState<RequestEstudoError | undefined>(undefined);

    // Contexto da Api
    const api = getApiSchema();

    // Contexto do usuário
    const { user } = useUserContext();

    useEffect(() => {
        async function getEstudoData() {
            if (user?.estudoId) {
                try {
                    const estudoData = await api.estudo.getEstudo(user?.estudoId);

                    if (estudoData.status === 200) {
                        setEstudo({
                            nome: estudoData.data.nome,
                            id: estudoData.data.id,

                            lat: estudoData.data.lat,
                            lon: estudoData.data.lon,
                            crs: estudoData.data.crs,

                            anoHidrologico: estudoData.data.ano_hidrologico,

                            criadoPor: estudoData.data.criado_por,
                            criadoEm: new Date(),
                            ativo: estudoData.data.ativo,

                            regionalizacao: estudoData.data.regionalizacao,
                            regionalizacoes: estudoData.data.regionalizacoes,

                            outorgas: estudoData.data.outorgas,

                            porcQ710: estudoData.data.porcentagem_q7_10,

                            areaId: estudoData.data.area_id,
                        });
                    } else {
                        setEstudo(null);
                    }
                } catch (e) {
                    // Estudo como undefined pois ele tem erro
                    setEstudo(undefined);

                    if (axios.isAxiosError(e) && e.response?.data && e.response.data.error) {
                        setRequestEstudoError({
                            error: e.response.data.error,
                            message: e.response.data.message,
                        });
                    } else {
                        setRequestEstudoError({
                            error: "Erro obtendo os dados do usuário!",
                            message: `${e}`,
                        });
                    }
                }
            } else if (user?.estudoId === null) {
                setEstudo(null);
            }
        }
        getEstudoData();
    }, [user]);

    return (
        <EstudoContext.Provider value={{ estudo, setEstudo }}>
            <RequestEstudoErrorContext.Provider value={requestEstudoError}>
                {children}
            </RequestEstudoErrorContext.Provider>
        </EstudoContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};
