import React, { useEffect } from "react";

type Props = {
    children?: React.ReactNode;
};

export const BeforeUnload: React.FC<Props> = ({ children }) => {

    useEffect(() => {
        const beforeUnload = (event: BeforeUnloadEvent) => {
            console.log("Saindo");
            console.log("_________");

            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', beforeUnload);

        return () => window.removeEventListener("beforeunload", beforeUnload);

    }, []);

    return <div>{children}</div>;
};
