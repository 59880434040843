import { Color, FluvioStation, interpolateColor, TelemetricaStation } from "../../core";

export const pressaoValue = (
    telemetriaData: TelemetricaStation,
    convencionalData: FluvioStation,
): number => {

    const vazaoInstan = telemetriaData.properties.vazao ?? 0;

    let vazaoMinima: number | undefined = 0;
    if ((convencionalData.properties.qEspecifica710?.correlGum ?? 0) > (convencionalData.properties.qEspecifica710?.correlWei ?? 0)) {
        vazaoMinima = convencionalData.properties.qEspecifica710?.QGumbel ?? 0;
    } else {
        vazaoMinima = convencionalData.properties.qEspecifica710?.QWeibull ?? 0;
    }

    if (vazaoMinima <= 0) return 0;

    const pressao = vazaoInstan / vazaoMinima;
    return pressao * 100;
};


export const styleBacias = (
    telemetriaData: TelemetricaStation | null,
    convencionalData: FluvioStation | null,
) => {
    let fillColor: string | undefined = undefined;

    if ((telemetriaData === null || !telemetriaData?.properties.vazao) || convencionalData === null) {
        fillColor = "gray";
    } else {
        const pressaoHidrica = pressaoValue(
            telemetriaData ?? null,
            convencionalData
        );

        if (pressaoHidrica >= 200) {
            fillColor = 'blue';
        } else if (pressaoHidrica >= 100) {
            fillColor = 'green';
        } else if (pressaoHidrica > 70) {
            fillColor = 'yellow';
        } else if (pressaoHidrica > 50) {
            fillColor = 'orange';
        } else {
            fillColor = 'red';
        }
    }

    return {
        fillColor,
        weight: 1,
        opacity: 1,
        color: 'black',
        fillOpacity: 0.5,
    };
};

export const highlightStyleBacias = (
    telemetriaData: TelemetricaStation | null,
    convencionalData: FluvioStation | null,
) => {
    let fillColor: string | undefined = undefined;

    if ((telemetriaData === null || !telemetriaData?.properties.vazao) || convencionalData === null) {
        fillColor = "gray";
    } else {
        const pressaoHidrica = pressaoValue(
            telemetriaData ?? null,
            convencionalData
        );

        if (pressaoHidrica >= 200) {
            fillColor = 'blue';
        } else if (pressaoHidrica >= 100) {
            fillColor = 'green';
        } else if (pressaoHidrica > 70) {
            fillColor = 'yellow';
        } else if (pressaoHidrica > 50) {
            fillColor = 'orange';
        } else {
            fillColor = 'red';
        }
    }

    return {
        fillColor,
        weight: 1.5,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.5,
    };
};


export const styleRios = () => {
    const percentage = 0.2;
    const color1 = new Color(113, 157, 245);
    const color2 = new Color(0, 37, 110);
    return {
        weight: 0.8,
        opacity: 0.8,
        color: interpolateColor(color1, color2, percentage).hex,
    };
};

export const styleRiosStatic = () => {
    const percentage = 0.2;
    const color1 = new Color(113, 157, 245);
    const color2 = new Color(0, 37, 110);

    return {
        weight: 0.8,
        opacity: 0.8,
        color: interpolateColor(color1, color2, percentage).hex,
    };
};

export const styleState = {
    fillColor: "transparent",
    fillOpacity: 0,
    weight: 2,
    opacity: 1,
    color: 'black',
};

export const styleNoData = {
    fillColor: "gray",
    weight: 1,
    opacity: 1,
    color: 'black',
    fillOpacity: 0.5,
};