import React from 'react';
import { HidroLandingAppBar } from '../../components/shared/appBar/appBarLanding';
import { Box} from '@mui/material';
import { FinalidadeCards } from '../../components/observatorio/landingPage/finalidadeCard';
import { Footer } from '../../components/observatorio/landingPage/footerPart';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const LandingPage: React.FC = () => {
    return (
        <HelmetProvider>
            
            <Helmet>
                <title>Hidrologic: Monitore as Principais Bacias Hidrográficas do país</title>
                <meta name="description" content="Fique atualizado com os dados mais recentes sobre vazão dos rios, outorgas e 
                cálculos hidrológicos das principais bacias hidrográficas de Minas Gerais. Acesse informações em 
                tempo real sobre estações fluviais, telemétricas, outorgas, vazões e muito mais." />
                <meta name="keywords" content="hidrologia, bacias hidrográficas, rios de Minas Gerais, 
                dados de vazão, outorgas, cálculos hidrológicos, ana, igam, hidrologia aplicada,
                estações fluviais, vazões, recursos hídricos, hidrologia de Minas Gerais, rios" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="HidroLogic - bacias e estações de MG" />
                <meta property="og:description" content="Fique atualizado com os dados mais recentes sobre vazão dos rios, outorgas e 
                cálculos hidrológicos das principais bacias hidrográficas de Minas Gerais. 
                Acesse informações em tempo real sobre estações fluviais, telemétricas, outorgas, vazões e muito mais." />
                <meta property="og:url" content="https://hidrologic.com.br/landing" />
                <meta property="og:type" content="website" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Hidrologic: Monitore as Principais Bacias Hidrográficas do país",
                        "description": "Fique atualizado com os dados mais recentes sobre vazão dos rios, outorgas e cálculos hidrológicos das principais bacias hidrográficas de Minas Gerais.",
                        "url": "https://hidrologic.com.br/landing"
                    }
                    `}
                </script>
            </Helmet>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                    backgroundColor: 'white',
                    height: '100%',
                    overflowX: 'hidden',
                }}
            >
                <Box sx={{ width: '100%', maxWidth: '100%', position: 'fixed', top: 0, zIndex: 1000 }}>
                    <HidroLandingAppBar />
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '64px' 
                }}>

                    <Box sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        maxWidth: '1200px',
                    }}>
                       

                        <FinalidadeCards />
                    </Box>

                </Box>

                <Footer />
            </Box>
        </HelmetProvider>
    );
};