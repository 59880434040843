import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

interface Schema {
    logout(): Promise<boolean>;
    getUser(): Promise<AxiosResponse>;
    updateUserEstudo(estudoId: string): Promise<AxiosResponse>;
}

const logout = async (): Promise<boolean> => {
    let statusOk = false;
    try {
        const response = await axios.post(`${API_URL}/auth/logout`);
        if (response.status === 200) statusOk = true;
    }
    catch (e) {
        statusOk = false;
    }

    localStorage.removeItem("refreshToken");
    window.location.href = "/";

    return statusOk;
};

const getUser = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/usuario/user`);
};

const updateUserEstudo = async (estudoId: string): Promise<AxiosResponse> => {
    const data = { "estudo_id": estudoId };
    return await axios.put(`${API_URL}/usuario/user`, data);
};


export const ApiUser = {
    logout: logout,
    getUser: getUser,
    updateUserEstudo: updateUserEstudo,
};