import {
    LatLng,
    LatLngBounds,
    Layer,
    LayerGroup,
    markerClusterGroup,
    MarkerClusterOptions,
} from 'leaflet';

export interface HidroLayerData {
    // Dados utilizaveis
    name: string;
    layer: Layer;
    showing: boolean;
}

export interface HidroLayersData {
    // Dados utilizaveis
    name: string;
    showing: boolean;

    // Dados das layers
    layers: (HidroLayer | HidroLayerGroup)[];

    // Para clusterizar os dados
    clusterize?: boolean;
    clusterizeOptions?: MarkerClusterOptions;
}

export class HidroLayer {
    public name: string;
    public layer: Layer;

    private _showing: boolean;
    public get showing(): boolean {
        return this._showing;
    }
    public set showing(val: boolean) {
        if (val !== this._showing) {
            this._showing = val;
            if (this.onShowing) this.onShowing(val);
            if (this.mother && this.mother.onShowing) this.mother.onShowing(val);
        }
    }

    public onShowing?: (show: boolean) => void;
    private mother?: HidroLayer | HidroLayerGroup;

    constructor(data: HidroLayerData) {
        this.name = data.name;
        this._showing = data.showing ?? true;
        this.layer = data.layer;
    }

    public getBounds(): LatLngBounds | null {
        if ("getBounds" in this.layer) return (this.layer.getBounds as () => LatLngBounds)();
        if ("getLatLng" in this.layer) return (this.layer.getLatLng as () => LatLng)().toBounds(0.1);
        return null;
    }

    public setMother(mother: HidroLayer | HidroLayerGroup) {
        this.mother = mother;
    }
}

// TODO: Melhorar o desempenho ao ativar, desativar ou mover as layers
export class HidroLayerGroup {
    public name: string;
    public layers: (HidroLayer | HidroLayerGroup)[];
    public clusterize?: boolean;
    public clusterizeOptions?: MarkerClusterOptions;

    // Controles do show da layer
    private _showing: boolean;
    public get showing(): boolean | undefined {
        let active = false;
        let inactive = false;
        this.layers.forEach((layer) => {
            if (layer.showing === true || layer.showing === undefined) active = true;
            if (layer.showing === false) inactive = true;
        });

        if (this._showing && !inactive && active) return true; // Todas ativas
        if (this._showing && inactive && active) return undefined; // Ativas e inativas
        if (this._showing && inactive && !active) return false; // Todas inativas

        return false; // Caso este grupo não deva ser mostrado independente dos items que ele contém
    }
    public set showing(show: boolean | undefined) {
        this._showing = show ?? false;
        if (this.onShowing) this.onShowing(show ?? false);
        if (this.mother && this.mother.onShowing) this.mother.onShowing(show ?? false);
    }

    // Funções de estado
    private onMoveLayer?: (oldIndex: number, newIndex: number) => void;
    public onShowing?: (show: boolean) => void;
    private mother?: HidroLayerGroup;
    private listeners: ((list: (HidroLayer | HidroLayerGroup)[]) => void)[] = [];

    constructor(data: HidroLayersData) {
        this.name = data.name;
        this._showing = data.showing ?? true;
        this.clusterize = data.clusterize;
        this.clusterizeOptions = data.clusterizeOptions;

        this.onShowing = () => this.notify();
        data.layers.forEach(layer => layer.setMother(this));
        this.layers = data.layers;
    }

    // BBOX
    public getBounds(): LatLngBounds | null {
        const bounds = this.layers.filter((layer) => layer.showing !== false).map((layer) => layer.getBounds()).filter((bounds) => bounds !== null);

        if (!bounds.length) return null;

        const allBounds = bounds[0] as LatLngBounds;
        bounds.forEach((bound) => bound ? allBounds.extend(bound) : null);
        return allBounds;
    }

    // Atualização de estado
    public setMother(mother: HidroLayerGroup) {
        this.mother = mother;
    }

    public subscribe(listener: (list: (HidroLayer | HidroLayerGroup)[]) => void) {
        this.listeners.push(listener);
    }

    public notify() {
        this.listeners.forEach(listener => listener([...this.layers]));
        if (this.mother) this.mother.notify();
    }

    // Ações com as layers
    public push(layer: HidroLayer | HidroLayerGroup) {
        layer.setMother(this);
        layer.onShowing = this.onShowing;

        this.layers.push(layer);

        // Notificar os listners
        this.notify();
    }

    public moveLayer(oldIndex: number, newIndex: number) {
        // Verifica se os índices são válidos
        if (oldIndex < 0 || oldIndex >= this.layers.length || newIndex < 0 || newIndex >= this.layers.length) {
            throw new Error('Índices inválidos');
        }

        // Remove o item do índice antigo
        const [item] = this.layers.splice(oldIndex, 1);

        // Insere o item no novo índice
        this.layers.splice(newIndex, 0, item);

        if (this.onMoveLayer) this.onMoveLayer(oldIndex, newIndex);

        // Notificar os listners
        this.notify();

        return this;
    }

    public removeLayer(layer: HidroLayer | HidroLayerGroup) {
        const index = this.layers.indexOf(layer);
        if (index > -1) {
            this.layers.splice(index, 1);
            this.notify();
        }
    }

    public clearLayers() {
        while (this.layers.length) this.layers.pop();
        this.notify();
    }

    // Obter as layers
    public getLayers(): LayerGroup {
        return this._getLayers(this.layers);
    }

    // Funções privadas
    private _getLayers(layers: (HidroLayer | HidroLayerGroup)[]): LayerGroup {
        const layersNow = layers.filter((layer) => layer.showing !== false).flatMap((layer) => {
            if (layer instanceof HidroLayerGroup) {
                const layerGroup = this._getLayers(layer.layers);
                if (!layer.clusterize) return layerGroup;

                const clusterOpt = layer.clusterizeOptions;
                const cluster = markerClusterGroup(clusterOpt);
                layerGroup.eachLayer((layer) => cluster.addLayer(layer));

                return cluster;
            }
            return layer.layer;
        });

        return new LayerGroup(layersNow);
    }
}
