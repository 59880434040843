import React, { useState } from 'react';
import { Box, Typography, IconButton, AppBar, MenuItem, Toolbar, Tooltip, Avatar, Icon } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { Color, useUserContext } from '../../../core';
import HBRLogo from '../../../assets/hidrobrLogo.png';
import { useOrientation } from '../../../contexts/OrientationContext';
import Menu from '@mui/material/Menu';
import { Spacer } from '../layout/espacador';
import { DEBUG } from '../../../enviroment';
import { getApiSchema } from '../../../services';

interface IconOpts {
    icon?: string;
    src?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    tooltip?: string;
}

interface AppBarOpts {
    leading?: IconOpts | JSX.Element;
    theme?: Theme;
    onReturn?: React.MouseEventHandler<HTMLButtonElement>;
    onTapLogo?: React.MouseEventHandler<HTMLButtonElement>;
    text?: string;
    tooltipRetornar?: string;
    tooltipLogo?: string;
    gradient?: string;
    logoPosition?: 'left' | 'right' | 'center' | 'auto';
    trailing?: IconOpts;
}

interface SettingsItem {
    text: string;
    icon: string;
    onClick: React.MouseEventHandler<HTMLLIElement>;
}

export const LogoHidrologicAppBar = (logoPosition: string, orientation?: string, tooltipLogo?: string, onTapLogo?: React.MouseEventHandler<HTMLButtonElement>, logoTextColor?: string) => {
    const imageLogo = (<Tooltip title={tooltipLogo ?? 'HIDROBR'}>
        <IconButton
            onClick={onTapLogo}
            sx={{
                height: '46px',
                width: '46px',
            }}
        >
            <a href='http://15.228.229.248/login' style={{
                width: 'auto',
                height: '100%',
                objectFit: 'cover',
            }}
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                    alt="HBRLogo"
                    src={HBRLogo}
                    style={{
                        width: 'auto',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                /></a>
        </IconButton>
    </Tooltip>
    );

    const textLogo = (
        <>
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22}>
                    HIDRO
                </Typography>
            )}
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22} color={logoTextColor}>
                    Logic
                </Typography>
            )}
        </>
    );

    return (
        <>
            {logoPosition !== "right" && imageLogo}
            {logoPosition === "right" && textLogo}
            <div style={{ width: "5px" }} />
            {logoPosition === "right" && imageLogo}
            {logoPosition !== "right" && textLogo}
        </>
    );
};

export const HidroAppBar: React.FC<AppBarOpts> = ({ leading, trailing, theme, onReturn, onTapLogo, text, tooltipRetornar, tooltipLogo, logoPosition = 'auto', gradient }) => {
    // Contexto da Api e do usuário
    const api = getApiSchema();
    const user = useUserContext();

    // Icones de opções na lateral
    const options: SettingsItem[] = [];
    options.push({
        text: 'Perfil',
        icon: 'person',
        onClick: () => {
            if (DEBUG) {
                console.log('Iria para a página do usuário');
            } else {
                window.location.href = '/user';
            }
        },
    });
    if (user.user?.adm)
        options.push({
            text: 'ADM',
            icon: 'admin_panel_settings',
            onClick: () => {
                if (DEBUG) {
                    console.log('Iria para a página do ADM');
                } else {
                    window.location.href = '/adm';
                }
            },
        });
    options.push({
        text: 'HIDROLogic',
        icon: 'architecture',
        onClick: () => {
            if (!window.location.href.endsWith('/sistema')) {
                window.location.href = '/sistema';
            }
        },
    });
    options.push({
        text: 'Logout',
        icon: 'logout',
        onClick: () => api.user.logout(),
    });

    // Variaveis de controle de estado
    const orientation = useOrientation();
    const [userPerfilError, setUserPerfilError] = useState(false);
    const [trailingError, setTrailingError] = useState(false);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // Variáveis de tema e organização
    const themeDefaut = useTheme();
    const themeNow = theme ?? themeDefaut;

    const bkColor = new Color(themeNow.palette.primary.dark);
    const textColor = bkColor.contrast();
    const logoTextColor = new Color(themeNow.palette.secondary.main);
    const borderColor = new Color(themeNow.palette.background.default);

    const logoAppBar = LogoHidrologicAppBar(logoPosition, orientation, tooltipLogo, onTapLogo, logoTextColor.toHex);

    let leadingItem = <div />;
    if (logoPosition === 'left') {
        if (onReturn) {
            leadingItem = (
                <>
                    <Tooltip title={tooltipRetornar ?? ''}>
                        <IconButton onClick={onReturn}>
                            <Avatar
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                    backgroundColor: '#0000',
                                }}
                            >
                                <Icon sx={{ color: textColor.toHex }}> arrow_back </Icon>
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                    {logoAppBar}
                </>
            );
        } else {
            leadingItem = logoAppBar;
        }
    } else if (onReturn !== undefined) {
        leadingItem = (
            <>
                <Tooltip title={tooltipRetornar ?? ''}>
                    <IconButton onClick={onReturn}>
                        <Avatar
                            sx={{
                                height: '36px',
                                width: '36px',
                                backgroundColor: '#0000',
                            }}
                        >
                            <Icon sx={{ color: textColor.toHex }}> arrow_back </Icon>
                        </Avatar>
                    </IconButton>
                </Tooltip>
                {text && (
                    <Typography noWrap variant="h6">
                        {' '}
                        {text}{' '}
                    </Typography>
                )}
            </>
        );
    } else if (leading !== undefined) {
        if ((leading as IconOpts).onClick || (leading as IconOpts).icon || (leading as IconOpts).src || (leading as IconOpts).tooltip) {
            leading = leading as IconOpts;
            leadingItem = (
                <Tooltip title={tooltipRetornar ?? ''}>
                    <IconButton onClick={leading.onClick}>
                        <Avatar
                            sx={{
                                height: '36px',
                                width: '36px',
                                backgroundColor: '#0000',
                            }}
                        >
                            {leading.src !== undefined && !userPerfilError ? (
                                <img
                                    alt="userPhoto"
                                    src={leading.src}
                                    onError={() => setUserPerfilError(true)}
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <Icon sx={{ color: textColor.toHex }}> {leading.icon} </Icon>
                            )}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            );
        } else {
            leadingItem = (leading as JSX.Element);
        }
    } else if (text) {
        leadingItem = (
            <Typography noWrap variant="h6">
                {' '}
                {text}{' '}
            </Typography>
        );
    }

    let centerItem = <div />;
    if (logoPosition === 'center' || logoPosition === 'auto') {
        centerItem = logoAppBar;
    } else if (logoPosition !== 'right' && text && orientation === 'landscape') {
        centerItem = (
            <Typography noWrap variant="h6">
                {' '}
                {text}{' '}
            </Typography>
        );
    }

    const trailingItem = (<Box sx={{ flexGrow: 0 }}>
        <Tooltip title={trailing?.tooltip ?? 'Mais opções'}>
            <IconButton
                onClick={(event) => {
                    if (trailing?.onClick !== undefined) {
                        trailing?.onClick(event);
                    } else {
                        handleOpenUserMenu(event);
                    }
                }}
            >
                <Avatar
                    sx={{
                        height: '36px',
                        width: '36px',
                        backgroundColor: '#0000',
                    }}
                >
                    {trailing?.src !== undefined && !trailingError ? (
                        <img
                            alt="trailingPhoto"
                            src={trailing?.src}
                            onError={() => setTrailingError(true)}
                            style={{
                                width: 'auto',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <Icon sx={{ color: textColor.toHex }}> {trailing?.icon} </Icon>
                    )}
                </Avatar>
            </IconButton>
        </Tooltip>
        <Menu
            keepMounted
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            {options.map((opts) => (
                <MenuItem
                    key={opts.text}
                    sx={{ borderBottom: `solid 2px ${borderColor.toHex}` }}
                    onClick={(event) => {
                        handleCloseUserMenu();
                        opts.onClick(event);
                    }}
                >
                    <Typography fontWeight={650} textAlign="center">
                        {opts.text}
                    </Typography>
                    <Spacer />
                    <Spacer width={'15px'} />
                    <Icon fontSize="small">{opts.icon}</Icon>
                </MenuItem>
            ))}
        </Menu>
    </Box>);

    let rightItem = <div />;
    if (logoPosition === 'right') {
        if (trailing && (trailing.icon || trailing.onClick || trailing.src || trailing.tooltip)) {
            rightItem = <>
                {logoAppBar}
                {trailingItem}
            </>;
        } else {
            rightItem = logoAppBar;
        }
    } else {
        rightItem = trailingItem;
    }

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: bkColor.toHex,
                paddingInline: '15px',
                background: gradient,
            }}
        >
            <Toolbar disableGutters>
                {/* Item da esquerda */}
                {leadingItem}

                {/* Item central */}
                <Spacer />
                {centerItem}
                <Spacer />

                {/* Menu suspenso com as informações do usuário ou logo na esquerda*/}
                {rightItem}
            </Toolbar>
        </AppBar>
    );
};
