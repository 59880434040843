import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        custom: true;
    }
}

export const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#005979',
            dark: '#00384d',
            light: '#0184b4',
            contrastText: '#CDE9FD',
        },
        secondary: {
            main: '#00906A',
            dark: '#00694d',
            light: '#00b887',
            contrastText: '#CDE9FD',
        },
        background: {
            default: '#000000',
            paper: '#001227',
        },
    },
    typography: {
        htmlFontSize: 14,
        fontFamily: 'Catamaran',
        fontSize: 14,
        fontWeightBold: 750,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightRegular: 250,
        button: {
            fontWeight: 750,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            custom: 1030,
        },
    },
});
