import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Icon, Typography, useTheme, useMediaQuery, IconButton, Tooltip } from '@mui/material';
import { renderStaticItems } from './renderLegendStatic';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ObservatoryLegendStatic: React.FC = () => {
    const theme = useTheme();
    const textColor = theme.palette.getContrastText(theme.palette.background.paper);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [legendOpen, setLegendOpen] = useState(!isMobile);

    const widthLegendDesktop = 20;
    const widthLegendMobile = 20;
    const padding = 1;
    const collapsedHeight = 1.25;

    const toggleLegend = () => {
        setLegendOpen(!legendOpen);
    };

    if (isMobile) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1000,
                height: '100%',
                display: 'flex',
            }}>
                <Card
                    sx={{
                        color: textColor,
                        width: legendOpen ? `${widthLegendMobile}rem` : 0,
                        height: legendOpen ? 250 : 0,
                        padding: legendOpen ? `${padding}rem` : 0,
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'width 0.3s ease-in-out, padding 0.3s ease-in-out',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {legendOpen && (
                        <>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.25rem' }}>
                                Legenda
                            </Typography>
                            <Box sx={{ overflowY: 'auto', flex: 1, '& > *': { fontSize: '1rem' } }}>
                                {renderStaticItems()}
                            </Box>
                        </>
                    )}
                </Card>
                <Tooltip title="Expandir legenda" arrow>
                    <IconButton
                        onClick={toggleLegend}
                        sx={{
                            position: 'absolute',
                            top: '1.875rem',
                            left: legendOpen ? `${widthLegendMobile - 1}rem` : 0,
                            transform: 'translateY(-50%)',
                            backgroundColor: 'background.paper',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                            transition: 'left 0.3s ease-in-out',
                            zIndex: 1000,
                        }}
                    >
                        {legendOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }

    return (
        <Card
            sx={{
                color: textColor,
                width: legendOpen ? `${widthLegendDesktop}rem` : `${widthLegendDesktop}rem`,
                height: legendOpen ? 300 : `${collapsedHeight}rem`,
                padding: `${padding}rem`,
                borderBottomRightRadius: '0.625rem',
                backgroundColor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                transition: 'width 0.3s, height 0.3s',
                overflow: 'hidden',
            }}
        >
            <Accordion
                expanded={legendOpen}
                onChange={(event, expanded) => {
                    setLegendOpen(expanded);
                }}
                sx={{
                    boxShadow: 'none',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    
                }}
            >
                <AccordionSummary expandIcon={<Icon> keyboard_arrow_down </Icon>} aria-controls="panel1-content" id="panel1-header" sx={{ alignItems: 'center', mt: -3}}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', }}>
                        Legenda
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0.313rem', mt: -4 }}>{renderStaticItems()}</AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default ObservatoryLegendStatic;