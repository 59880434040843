import { divIcon, LatLng, LatLngLiteral, Marker } from "leaflet";
import { paintByConditions, PaintMarker, svgWithColor } from "../style";

import TeleSVG from "../../../assets/telemetrica.svg";

export interface TelemetricaStationData {
    codigo: number;
    nome: string | null;
    areaM2: number | null;
    dataMedicao: Date | null;
    dataAtualizacao: Date | null;
    vazao: number | null;
    vazaoStatus: number | null;
    chuva: number | null;
    chuvaStatus: number | null;
    cota: number | null;
    cotaStatus: number | null;
}

export class TelemetricaStation extends Marker<TelemetricaStationData> {

    public properties: TelemetricaStationData = {
        codigo: 0,
        nome: null,
        areaM2: null,
        dataMedicao: null,
        dataAtualizacao: null,
        vazao: null,
        vazaoStatus: null,
        chuva: null,
        chuvaStatus: null,
        cota: null,
        cotaStatus: null,
    };

    public svgItem = TeleSVG;

    constructor(latlng: LatLng | LatLngLiteral, properties: TelemetricaStationData, paintOptions: PaintMarker) {
        super(latlng, paintOptions);

        this.properties = properties;

        this.feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [latlng.lat, latlng.lng],
            },
            properties
        };

        if (!paintOptions.icon) {
            // Cor normal
            let paintNormal: PaintMarker = paintOptions;
            if (paintOptions.paintCategory) {
                paintNormal = paintByConditions(this.properties, paintOptions.paintCategory);
            }
            svgWithColor(this.svgItem, paintNormal.color ?? "#000000").then(updatedSvg => {
                const urlSVG2 = `data:image/svg+xml;base64,${btoa(updatedSvg)}`;
                this.svgItem = urlSVG2;
                const icon = divIcon({
                    html: this.getIconBySrc(
                        urlSVG2,
                        properties.codigo,
                        paintNormal.color ?? "black",
                        paintNormal.bgColor ?? "white",
                        paintNormal.iconSize ?? 20,
                    ),
                    className: 'custom-svg-icon',
                    iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
                });
                this.setIcon(icon);
            });

            const icon = divIcon({
                html: this.getIconBySrc(
                    this.svgItem,
                    properties.codigo,
                    paintNormal.color ?? "black",
                    paintNormal.bgColor ?? "white",
                    paintNormal.iconSize ?? 20,
                ),
                iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
            });
            this.setIcon(icon);
        }
    }

    private getIconBySrc = (src: string, alt: string | number, color: string, bgColor: string, size: number) => {
        return `<img
            src="${src}"
            alt="${alt}"
            style="
                width: ${size}px;
                height: ${size}px;
                border-radius: 50%;
                background-color: ${bgColor};
                border: 1px solid ${color};
            "/>`;
    };
}