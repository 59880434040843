
import { useState, ChangeEvent } from "react";

export const useIncrementalState = (initialValue: number, step: number) => {
    const [value, setValue] = useState<number>(initialValue);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value.toFixed(2));

    const handleIncrement = () => setValue(prev => parseFloat((prev + step).toFixed(2)));
    const handleDecrement = () => setValue(prev => parseFloat((Math.max(prev - step, 0)).toFixed(2)));
    const handleEditStart = () => {
        setIsEditing(true);
        setInputValue(value.toFixed(2));
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);
    const handleEditEnd = () => {
        const newValue = parseFloat(inputValue);
        if (!isNaN(newValue)) setValue(parseFloat(Math.max(newValue, 0).toFixed(2)));
        setIsEditing(false);
    };

    return {
        value, isEditing, inputValue,
        handleIncrement, handleDecrement, handleEditStart, handleInputChange, handleEditEnd
    };
};
