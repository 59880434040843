import { DebugModal } from "../../components";
import { EstudoProvider } from "../../core/user_estudo/estudo/estudo";
import { BeforeUnload } from "../../services";
import { SystemRoutes } from "../../routes/sistema";

export const SistemContextController = () => {
    return (
        <BeforeUnload >
            <EstudoProvider>
                <DebugModal>
                    <SystemRoutes />
                </DebugModal>
            </EstudoProvider>
        </BeforeUnload >
    );
};
