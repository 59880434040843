import React from 'react';
import { Box, Typography, AppBar, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HidrologicLogo from "../../../assets/LogoHIDROLogicMonitoramento.svg";
import { Spacer } from '../layout/espacador';
import { Helmet } from 'react-helmet-async';

export const HidroLandingAppBar: React.FC = () => {
    const theme = useTheme();

    const bkColor = theme.palette.primary.dark;
    const textColor = theme.palette.getContrastText(bkColor);

    return (
        <>
            <Helmet>
                <title>HIDROlogic - Observatório Hidrológico</title>
                <meta name="description" content="Explore o observatório hidrológico do HIDROlogic para análise e gestão eficaz dos recursos hídricos." />
                <meta name="keywords" content="HIDROlogic, observatório hidrológico, recursos hídricos, Minas Gerais" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "HIDROlogic",
                        "url": "https://hidrologic.com.br",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://hidrologic.com.br/search?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <AppBar position="static" sx={{ backgroundColor: bkColor, height: '4rem' }}>
                <Toolbar disableGutters>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                        <a href="/observatorio" style={{ textDecoration: 'none', color: textColor, display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', mt: '1.2rem' }}>
                                <img src={HidrologicLogo} alt="HidroLogic Logo" style={{ width: '6.25rem', height: '6.25rem' }} />
                            </Box>
                            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold, fontFamily: theme.typography.fontFamily, display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                <span style={{ color: textColor, marginRight: '0.25rem' }}>Observatório</span>
                                <span style={{ color: textColor, marginRight: '0.25rem' }}>|</span>
                                <span style={{ color: textColor }}>HIDRO</span>
                                <span style={{ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold, fontFamily: theme.typography.fontFamily }}>Logic</span>
                            </Typography>
                          
                        </a>
                    </Box>
                    <Spacer />
                </Toolbar>
            </AppBar>
        </>
    );
};