import { Divider, Icon, Typography } from '@mui/material';
import {
    AreaGeom,
    FluvioStation,
    HidroLayer,
    HidroLayerGroup,
    Outorga,
    TelemetricaStation,
    Trechos
} from '../../core';
import { useState } from 'react';
import {
    CircleMarker,
    GridLayer,
    Layer,
    LayerGroup,
    Marker,
    Point,
    Polygon,
    Polyline,
    TileLayer
} from 'leaflet';
import {
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    CircleOutlined,
    ErrorOutlineOutlined,
    IndeterminateCheckBoxOutlined,
    MyLocationOutlined,
    PinDropOutlined,
    PolylineOutlined,
    RectangleOutlined,
    WallpaperOutlined
} from '@mui/icons-material';

const heightComponent = 22;
const iconStyle = {
    fontSize: 18,
    width: 15,
    height: 15,
    padding: 0,
    margin: 0,
};
const titleStyle: React.CSSProperties = {
    height: `${heightComponent}px`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "7px",
    paddingRight: "7px",
};
const timeAnim = 0.3;
const dividerItems = (<div style={{ width: "3px" }} />);

interface Props {
    hidroLayer: HidroLayer | HidroLayerGroup;
    maxWidth?: string | number;
}

export const HidroLayerComponent: React.FC<Props> = (data) => {
    if (data.hidroLayer instanceof HidroLayerGroup) return <TroggleLayer layer={data.hidroLayer} initialOpen={false} maxWidth={data.maxWidth} />;
    return <LayerComponent layer={data.hidroLayer} maxWidth={data.maxWidth} />;
};


interface PropsLayer {
    layer: HidroLayer;
    maxWidth?: string | number;
}
const LayerComponent: React.FC<PropsLayer> = (data) => {
    const [checked, setChecked] = useState<boolean | undefined>(data.layer.showing);
    const setCheck = () => {
        data.layer.showing = !checked;
        setChecked(!checked);
    };

    const layer = data.layer.layer;
    const icon = getIcon(layer);

    return <div style={{ ...titleStyle, maxWidth: data.maxWidth }}>
        {getCheck(checked, setCheck)}
        {dividerItems}

        {icon}
        {dividerItems}

        <Typography
            fontSize={14}
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                fontWeight: "400",
            }}>
            {data.layer.name}
        </Typography>

        <div style={{ flex: 1 }} />
    </div>;
};

interface PropsTroggle {
    initialOpen: boolean;
    layer: HidroLayerGroup;
    maxWidth?: string | number;
}
const TroggleLayer: React.FC<PropsTroggle> = (data) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBox = () => setIsOpen(!isOpen);

    const [checked, setChecked] = useState<boolean>(data.layer.showing ?? true);
    const setCheck = () => {
        const openAns = isOpen;

        data.layer.showing = !checked;
        setChecked(!checked);
        setTimeout(() => setIsOpen(openAns), 0);
    };

    return <div style={{
        maxWidth: data.maxWidth,
        backgroundColor: "transparent",
    }}>
        <div onClick={toggleBox} style={{ ...titleStyle, maxWidth: data.maxWidth, cursor: "pointer" }}>
            {getCheck(data.layer.showing, setCheck)}
            {dividerItems}

            <Icon
                onClick={toggleBox}
                sx={{
                    ...iconStyle,
                    transform: `rotate(${isOpen ? -45 : -90}deg)`,
                    transition: `transform ${timeAnim}s ease-in-out`
                }}
            >
                arrow_drop_down
            </Icon>
            {dividerItems}

            <Typography
                onClick={toggleBox}
                fontSize={14}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    fontWeight: "600",
                }}>
                {data.layer.name}
            </Typography>
        </div>

        <div style={{
            paddingLeft: !isOpen ? 0 : '15px',
            maxHeight: !isOpen ? 0 : "101%",
            transition: `max-height ${timeAnim}s ease-out`,
            overflowY: "hidden",
        }}>
            {isOpen && data.layer.layers.map((layer, index) => {
                if (layer instanceof HidroLayer) return <LayerComponent key={index} layer={layer} maxWidth={data.maxWidth} />;
                return <TroggleLayer key={index} layer={layer} initialOpen={false} maxWidth={data.maxWidth} />;
            })}
        </div>

        {isOpen && <Divider sx={{ marginY: "2px", marginX: "5px" }} />}

    </div>;
};

// TODO: Implementar os estilos personalizados por valores
const getIcon = (layer: Layer) => {

    if (layer instanceof Trechos) {
        return <Icon sx={{ ...iconStyle, color: layer.normalStyle.color }}>polyline</Icon>;
    } else if (layer instanceof AreaGeom) {
        const strokeColor = layer.options.color;
        const strokeOpacity = layer.options.opacity;
        const strokeWidth = layer.options.weight;
        const strokeArray = layer.options.dashArray;
        const strokeOffset = layer.options.dashOffset;
        const fillColor = layer.options.fillColor;
        const fillOpacity = layer.options.fillOpacity;


        return <svg width={iconStyle.width} height={iconStyle.height} >
            <rect
                width={iconStyle.width - (strokeWidth ?? 0)}
                height={iconStyle.height - (strokeWidth ?? 0)}
                fill={fillColor}
                fillOpacity={fillOpacity}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeOpacity={strokeOpacity}
                strokeDasharray={strokeArray ? strokeArray[0] : undefined}
                strokeDashoffset={strokeOffset}
            />
        </svg>;
    } else if (layer instanceof TelemetricaStation || layer instanceof FluvioStation || layer instanceof Outorga) {
        return <img
            width={iconStyle.width - 3}
            height={iconStyle.height - 3}
            src={layer.svgItem}
            alt={layer.getLatLng().toString()}
            style={{
                borderRadius: "50%",
                backgroundColor: "white",
                border: "1px solid black",
            }
            }
        />;
    } else if (layer instanceof Polygon) {
        return <RectangleOutlined sx={iconStyle} />;
    } else if (layer instanceof Polyline) {
        return <Icon sx={iconStyle}>polyline</Icon>;
    } else if (layer instanceof Marker) {
        return <PinDropOutlined sx={iconStyle} />;
    } else if (layer instanceof Point) {
        return <MyLocationOutlined sx={iconStyle} />;
    } else if (layer instanceof CircleMarker) {
        return <CircleOutlined sx={iconStyle} />;
    } else if (layer instanceof TileLayer) {
        return <WallpaperOutlined sx={iconStyle} />;
    } else if (layer instanceof GridLayer) {
        return <Icon sx={iconStyle}>stacks</Icon>;
    } else if (layer instanceof LayerGroup) {
        const icons = new Set<string>();
        const types = new Set<string>();
        const colors = new Set<string>();
        layer.eachLayer((layer) => {
            if (layer instanceof Marker || layer instanceof CircleMarker || layer instanceof Point) {
                if ("svgItem" in layer && layer.svgItem) icons.add(layer.svgItem as string);
                types.add("point");
            } else if (layer instanceof Polygon) {
                types.add("polygon");
            } else if (layer instanceof Polyline) {
                types.add("polyline");
            }

            if ("color" in layer.options) colors.add((layer.options.color as string) ?? "black");
        });

        if (icons.size === 1) {
            const icon = icons.values().next().value;
            return <img
                width={iconStyle.width - 3}
                height={iconStyle.height - 3}
                src={icon}
                alt={icon}
                style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    border: "1px solid black",
                }
                }
            />;
        }

        let color = undefined;
        let gradient = undefined;
        if (colors.size == 1) {
            color = colors.values().next().value;
        } else if (colors.size > 1) {
            const colorArray = Array.from(colors);
            const gradientColors = colorArray.join(', ');
            gradient = `linear-gradient(to right, ${gradientColors})`;
        }

        const style = {
            ...iconStyle,
            backgroundColor: color,
            background: gradient,
        };

        if (types.size > 1) return <Icon sx={style}>stacks</Icon>;
        if (types.has("point")) return <Icon sx={style}>circle</Icon>;
        if (types.has("polygon")) return <RectangleOutlined sx={style} />;
        if (types.has("polyline")) return <PolylineOutlined sx={style} />;

        return <ErrorOutlineOutlined sx={style} />;
    }

    return <ErrorOutlineOutlined sx={iconStyle} />;
};

const getCheck = (checked: boolean | undefined, setCheck: () => void) => {
    return <>
        {checked === true && <CheckBoxOutlined onClick={setCheck} sx={{ ...iconStyle, cursor: "pointer" }} />}
        {checked === false && <CheckBoxOutlineBlank onClick={setCheck} sx={{ ...iconStyle, cursor: "pointer" }} />}
        {checked === undefined && <IndeterminateCheckBoxOutlined onClick={setCheck} sx={{ ...iconStyle, cursor: "pointer" }} />}
    </>;
};