import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const getOutorgas = async (online: boolean, outorgas?: number[], byEstudo?: boolean): Promise<AxiosResponse> => {
    const data = {
        online: online,
        outorgas: outorgas,
        "by_estudo": byEstudo,
    };
    return await axios.post(`${API_URL}/outorgas`, data);
};

const getConsumo = async (online: boolean, outorgas?: number[], byEstudo?: boolean): Promise<AxiosResponse> => {
    const data = {
        online: online,
        outorgas: outorgas,
        "by_estudo": byEstudo,
    };
    return await axios.post(`${API_URL}/toolbox/outorgas/consumo`, data);
};

export const ApiOutorgas = {
    getOutorgas: getOutorgas,
    getConsumo: getConsumo,

};
