import React, { useState } from "react";
import {
    IconButton,
    Divider,
    Box,
    useTheme,
    Icon,
    Tooltip,
} from "@mui/material";
import { BalancoHidrico } from "./balancoHidrico";
import { EscolherEstudo } from "./escolherEstudo";
import { AreaDeEstudo } from "./areaDeEstudo";
import { Column, Row, Spacer } from "../../shared";
import { Color } from "../../../core";
import { ExporterEstudoBtn } from "../../exporters";

export function DrawerMenu() {
    const [open, setOpen] = useState<boolean>(false);
    const [openEstudos, setOpenEstudos] = useState<boolean>(false);
    const [openArea, setOpenArea] = useState<boolean>(false);
    const [openBalanco, setOpenBalanco] = useState<boolean>(false);

    // Cores
    const theme = useTheme();
    const bkColor = new Color(theme.palette.primary.dark);
    const textColor = new Color(theme.palette.background.paper);

    // Variáveis

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: open ? "35vw" : "57px",
                transition: "width 500ms",
                backgroundColor: bkColor.toHex,
                padding: 0,
            }}
        >
            {/* Items da lista */}
            <Column>
                <>
                    {itemDrawer({
                        open: open,
                        childClose: (
                            <Tooltip title="Escolher estudo">
                                <IconButton
                                    onClick={(event: React.SyntheticEvent) => {
                                        setOpenEstudos(true);
                                        setOpen(true);
                                    }}
                                    sx={{
                                        height: "46px",
                                        width: "46px",
                                        color: textColor.toHex,
                                    }}
                                >
                                    <Icon>list_alt</Icon>
                                </IconButton>
                            </Tooltip>
                        ),
                        childOpen: (
                            <EscolherEstudo expanded={open} />
                        )
                    })}
                </>
                <Divider sx={{ borderColor: textColor.toHex }} />
                <>
                    {itemDrawer({
                        open: open,
                        childClose: (
                            <Tooltip title="Área de estudo">
                                <IconButton
                                    onClick={(event: React.SyntheticEvent) => {
                                        setOpenArea(true);
                                        setOpen(true);
                                    }}
                                    sx={{
                                        height: "46px",
                                        width: "46px",
                                        color: textColor.toHex,
                                    }}
                                >
                                    <Icon>all_out_outlined</Icon>
                                </IconButton>
                            </Tooltip>
                        ),
                        childOpen: (
                            // TODO: Adicionar os icones do estudo
                            <AreaDeEstudo expanded={open} />
                        )
                    })}
                </>
                <Divider sx={{ borderColor: textColor.toHex }} />


                {itemDrawer({
                    open: open,
                    childClose: (
                        <Tooltip title="Balanço hídrico">
                            <IconButton
                                onClick={(event: React.SyntheticEvent) => {
                                    setOpenBalanco(true);
                                    setOpen(true);
                                }}
                                sx={{
                                    height: "46px",
                                    width: "46px",
                                    color: textColor.toHex,
                                }}
                            >
                                <Icon>swap_vert</Icon>
                            </IconButton>
                        </Tooltip>
                    ),
                    childOpen: (
                        <BalancoHidrico expanded={openBalanco} />
                    )
                })}
                <Divider sx={{ borderColor: textColor.toHex }} />

                <Spacer />
            </Column>

            <Divider sx={{ borderColor: textColor.toHex }} />

            {/* Item de export */}
            {
                itemDrawer({
                    open: open,
                    childOpen: (
                        <Box sx={{
                            height: "56px",
                        }}>
                            <Row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Spacer />
                                <ExporterEstudoBtn theme={theme} />
                                <Spacer />

                                <IconButton onClick={(event: React.SyntheticEvent) => setOpen(false)}
                                    sx={{
                                        height: "46px",
                                        width: "46px",
                                        color: textColor.toHex,
                                    }}
                                >
                                    <Icon>
                                        arrow_back
                                    </Icon>
                                </IconButton>
                            </Row>
                        </Box>

                    ),
                    childClose: (
                        <IconButton onClick={(event: React.SyntheticEvent) => setOpen(true)}
                            sx={{
                                height: "46px",
                                width: "46px",
                                color: textColor.toHex,
                            }}
                        >
                            <Icon>
                                menu
                            </Icon>
                        </IconButton>
                    )
                })
            }
        </div>
    );
}

function itemDrawer({
    open,
    childOpen,
    childClose
}: {
    open: boolean;
    childOpen: React.ReactNode[] | React.ReactNode;
    childClose: React.ReactNode[] | React.ReactNode;
}) {
    return (

        <Box sx={{
            height: open ? undefined : "56px",
            display: open ? undefined : 'flex',
            justifyContent: open ? undefined : 'center',
            alignItems: 'center',
            padding: 0,
        }}>
            {open && childOpen}
            {!open && childClose}
        </Box>
    );
}
