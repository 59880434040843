import { useState } from "react";
import { Box } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { useOrientation } from "../../../contexts/OrientationContext";
import { ItemExporter } from "./items_exporter";
import { Color } from "../../../core/color/color";
import { ExporterItem } from "./exporter_item";
import { Column, Row } from "../../shared/layout/column_and_row";

interface Props {
    width?: any;
    height?: any;
    theme?: Theme;
    data: ItemExporter[];
}

export function ExporterEstudo({ width, height, data, theme }: Props) {
    const [indexShow, setIndex] = useState<number[]>([0, 1, 0]);

    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;

    const backgroundColor = new Color(currentTheme.palette.background.default);
    const textColor = new Color(currentTheme.palette.text.primary);

    let isLandscape = false;
    const orientation = useOrientation();
    if (orientation === undefined) {
        if (window.matchMedia("(orientation: landscape)").matches) {
            isLandscape = true;
        }
    } else if (orientation === "landscape") {
        isLandscape = true;
    }

    let itemChildrenAns = data;

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor.toHex,
                height: height ?? "100%",
                width: width ?? "100%",
                color: textColor.toHex,
                overflowX: "auto",
                overflowY: "hidden",
            }}
        >
            <Row style={{ display: "inline-block" }}>
                {indexShow.map((index, i) => {

                    const items: ItemExporter[] = itemChildrenAns;
                    let indexNext = -1;
                    if (i !== (indexShow.length - 1)) {
                        indexNext = indexShow[i + 1];
                        itemChildrenAns =
                            itemChildrenAns[indexNext].children ?? [];
                    }

                    return (
                        <Column
                            key={`${i}`}
                            style={{
                                // eslint-disable-next-line max-len
                                borderRight: `2px solid ${backgroundColor.contrast().toHex}`,
                                display: 'inline-block',
                                height: "99%",
                            }}>
                            {

                                items.map((item, j) => {
                                    const onSelect = () => {
                                        if (item.children !== undefined) {

                                            // eslint-disable-next-line max-len
                                            const newIndexShow = indexShow.slice(0, i + 1);
                                            newIndexShow.push(j);

                                            console.log("Adicionando");
                                            setIndex(newIndexShow);
                                        }
                                    };

                                    return ExporterItem({
                                        key: `${i}${j}`,
                                        item: item,
                                        landscape: isLandscape,
                                        selected: j === indexNext,
                                        onSelect: onSelect
                                    });
                                })
                            }
                        </Column>
                    );
                })}
            </Row>
        </Box>
    );
}