export function interpolateColor(color1: Color, color2: Color, percentage: number) {
    if (percentage < 0 || percentage > 1) throw Error("A porcentagem deve estar entre 0 e 1");

    // Calcula a cor interpolada
    const r = Math.round(color1.r + (color2.r - color1.r) * percentage);
    const g = Math.round(color1.g + (color2.g - color1.g) * percentage);
    const b = Math.round(color1.b + (color2.b - color1.b) * percentage);

    // Converte a cor interpolada de volta para Color
    return new Color(r, g, b, 1);
}

export class Color {

    constructor(rOrHexOrRGBA: string);
    constructor(rOrHexOrRGBA: number, g: number, b: number, o: number);
    constructor(rOrHexOrRGBA: number, g: number, b: number);
    constructor(rOrHexOrRGBA: number | string, g?: number, b?: number, o?: number) {
        if (typeof rOrHexOrRGBA === 'string') {

            if (rOrHexOrRGBA.length < 5) {
                let rgbVal: string = rOrHexOrRGBA.replaceAll("#", "");
                rgbVal = rgbVal[rgbVal.length - 1];

                rOrHexOrRGBA = "#" + rOrHexOrRGBA.replaceAll("#", "").slice(0, 2);
                rOrHexOrRGBA += rgbVal + rgbVal + rgbVal + rgbVal + rgbVal + rgbVal;
            }

            if (rOrHexOrRGBA.startsWith("rgb")) {
                this.setRGBA(rOrHexOrRGBA);
            } else {
                this.setHex(rOrHexOrRGBA);
            }
            return;
        } else if (g !== undefined && b !== undefined) {
            this.setRgb(rOrHexOrRGBA, g, b, o);
            return;
        }
        throw Error("O tipo Color deve ser inicializado por uma string com o Hex, por RGB ou por RGBA");
    }

    static readonly contrastFactor = 50;
    static readonly rBrightVal = 0.21;
    static readonly gBrightVal = 0.72;
    static readonly bBrightVal = 0.07;

    static readonly hexRegEx = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{0,2})$/i;

    static get random(): Color {
        return new Color(...Color.randomRgb());
    }
    static get transparent(): Color {
        return new Color(0, 0, 0, 0);
    }
    static get white(): Color {
        return new Color(255, 255, 255);
    }
    static get black(): Color {
        return new Color(0, 0, 0);
    }
    static get grey(): Color {
        return new Color('#d2d9d8');
    }
    static get lightGrey(): Color {
        return new Color('#f4f6f6');
    }
    static get darkGrey(): Color {
        return new Color('#949494');
    }
    static get red(): Color {
        return new Color(255, 0, 0);
    }
    static get green(): Color {
        return new Color(0, 255, 0);
    }
    static get blue(): Color {
        return new Color(0, 0, 255);
    }

    static get rdRed(): Color {
        return new Color('#dd385b');
    }
    static get rdLightRed(): Color {
        return new Color('#de7287');
    }
    static get rdLighterRed(): Color {
        return new Color('#f1dadd');
    }
    static get rdGrey(): Color {
        return new Color('#d2d9d8');
    }
    static get rdLightGrey(): Color {
        return new Color('#f4f6f6');
    }
    static get rdBlack(): Color {
        return new Color('#2b363f');
    }
    static get rdTeal(): Color {
        return new Color('#66bcab');
    }
    static get rdBlue(): Color {
        return new Color('#2b363f');
    }

    // tslint:disable: no-bitwise
    static rgbFromString(str: string): [number, number, number] {
        const rgb: [number, number, number] = [0, 0, 0];
        if (str.length === 0) {
            return rgb;
        }

        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 255;
            rgb[i] = value;
        }
        return rgb;
    }
    // tslint:enable: no-bitwise

    static hexFromString(str: string): string {
        return Color.rgbToHex(...Color.rgbFromString(str));
    }

    static hexToRgba(hex: string): number[] {
        const colorNameToHex: { [key: string]: string; } = {
            black: "#000000",
            white: "#FFFFFF",
            red: "#FF0000",
            green: "#008000",
            blue: "#0000FF",
            yellow: "#FFFF00",
            cyan: "#00FFFF",
            magenta: "#FF00FF",
        };

        const hexColor = colorNameToHex[hex.toLowerCase()];
        const result = Color.hexRegEx.exec(hexColor ?? hex);

        if (result === null) {
            throw new Error('Invalid hex value.');
        }

        const alpha = parseInt(result[4] || 'ff', 16) / 255;

        return result ? [parseInt(result[1], 16), parseInt(result[2] || '00', 16), parseInt(result[3] || '00', 16), alpha] : [];
    }

    static rgbToHex(r: number, g: number, b: number, o = 1): string {
        return `#${Color.numToHex(r)}${Color.numToHex(g)}${Color.numToHex(b)}${Color.numToHex(Math.floor(o * 255))}`;
    }

    static fromString(str: string): Color {
        return new Color(...Color.rgbFromString(str));
    }

    static randomRgb(): [number, number, number] {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return [r, g, b];
    }

    static randomRgba(): [number, number, number, number] {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        const o = Math.floor(Math.random() * 100) / 100;
        return [r, g, b, o];
    }

    static randomHex(): string {
        const rgb = Color.randomRgb();
        return Color.rgbToHex(rgb[0], rgb[1], rgb[2]);
    }

    private static numToHex(num: number) {
        const hex = num.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }

    private validate() {
        this.clamp();

        this.hexVal = this.toHex;

        this.rgbVal = [this.rVal, this.gVal, this.bVal, this.oVal];
        this.invVal = [255 - this.rVal, 255 - this.gVal, 255 - this.bVal];
        this.pBrightVal = (Color.rBrightVal * this.rVal + Color.gBrightVal * this.gVal + Color.bBrightVal * this.bVal) / 3;
    }

    private hexVal = "";
    private rgbVal: number[] = [];
    private rgbaVal: number[] = [];
    private invVal: number[] = [];
    private pBrightVal = 0;

    private rVal = 0;
    private gVal = 0;
    private bVal = 0;
    private oVal = 0;

    public get hex() {
        return this.hexVal;
    }
    public set hex(hex: string) {
        this.setHex(hex);
    }

    public get rgb() {
        return this.rgbVal;
    }
    public get rgba() {
        return this.rgbaVal;
    }

    public get inverted() {
        return this.invVal;
    }
    public get brightness() {
        return this.pBrightVal;
    }

    public get r() {
        return this.rVal;
    }
    public set r(r: number) {
        this.rVal = r;
        this.validate();
    }
    public get g() {
        return this.gVal;
    }
    public set g(g: number) {
        this.gVal = g;
        this.validate();
    }
    public get b() {
        return this.bVal;
    }
    public set b(b: number) {
        this.bVal = b;
        this.validate();
    }
    public get o() {
        return this.oVal;
    }
    public set o(o: number) {
        this.oVal = o;
        this.validate();
    }

    private clamp(): void {
        this.rVal = Math.max(Math.min(Math.floor(this.rVal), 255), 0);
        this.gVal = Math.max(Math.min(Math.floor(this.gVal), 255), 0);
        this.bVal = Math.max(Math.min(Math.floor(this.bVal), 255), 0);
        this.oVal = Math.max(Math.min(this.oVal, 1), 0);
    }

    private setHex(hex: string) {
        if (hex) {
            const rgba: number[] = Color.hexToRgba(hex);
            this.rVal = rgba[0] || 0;
            this.gVal = rgba[1] || 0;
            this.bVal = rgba[2] || 0;
            this.oVal = rgba[3] || 1;

            this.validate();
        }
    }

    private setRGBA(rgba: string) {
        let o = 1;
        let r = 255;
        let g = 255;
        let b = 255;

        const rgbaList = rgba.replaceAll("rgba", "").replaceAll("rgb", "").replaceAll("(", "").replaceAll(")", "").split(", ");
        r = parseInt(rgbaList[0]);
        g = parseInt(rgbaList[1]);
        b = parseInt(rgbaList[2]);
        if (rgbaList.length > 3) {
            o = parseFloat(rgbaList[3]);
        }

        this.rVal = r;
        this.gVal = g;
        this.bVal = b;
        this.oVal = o ?? 1;

        this.validate();
    }

    private setRgb(r: number, g: number, b: number, o?: number) {
        this.rVal = r;
        this.gVal = g;
        this.bVal = b;
        this.oVal = o ?? 1;

        this.validate();
    }

    public invert() {
        this.rVal = this.invVal[0] || 0;
        this.gVal = this.invVal[1] || 0;
        this.bVal = this.invVal[2] || 0;
    }

    public toRgbString(): string {
        return `rgb(${this.rVal}, ${this.gVal}, ${this.bVal});`;
    }

    public toRgbaString(): string {
        return `rgba(${this.rVal}, ${this.gVal}, ${this.bVal}, ${this.oVal});`;
    }

    public get toHex(): string {
        return Color.rgbToHex(this.rVal, this.gVal, this.bVal, this.oVal);
    }

    public shouldContrast(): boolean {
        return this.pBrightVal < Color.contrastFactor;
    }

    public contrast(): Color {
        return this.shouldContrast() ? Color.rdLightGrey : Color.rdBlack;
    }

    public distanceFrom(color: Color): number {
        const rDist = this.rVal - color.r;
        const gDist = this.gVal - color.g;
        const bDist = this.bVal - color.b;
        return (rDist + gDist + bDist) / 3;
    }

    public darken(amount: number): void {
        this.r = Math.max(this.r - amount, 0);
        this.g = Math.max(this.g - amount, 0);
        this.b = Math.max(this.b - amount, 0);
    }

    public brighten(amount: number): void {
        this.r = Math.min(this.r + amount, 255);
        this.g = Math.min(this.g + amount, 255);
        this.b = Math.min(this.b + amount, 255);

    }

    public transparentize(opacity: number): void {
        this.o = Math.max(0, this.oVal - opacity);
    }
}