import { MapContainer, MapContainerProps } from 'react-leaflet';
import { HidroLayer, HidroLayerGroup } from '../../core';
import { LatLngBounds, Map, TileLayer } from 'leaflet';
import { useEffect, useRef, useState } from 'react';

interface Props extends MapContainerProps {
    hidroLayers: HidroLayerGroup;
    children?: React.ReactNode;
}

export const HidroMap: React.FC<Props> = (data) => {
    if (!data.zoom) data.zoom = 15;
    if (!data.maxBoundsViscosity) data.maxBoundsViscosity = 2;
    if (!data.maxZoom) data.maxZoom = 18;
    if (!data.minZoom) data.minZoom = 6;
    if (!data.hidroLayers.layers.length) {
        const layer = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        });
        data.hidroLayers.layers.push(
            new HidroLayer({
                name: 'Mapa',
                layer: layer,
                showing: true,
            })
        );
    }

    const mapRef = useRef<Map | null>(null);
    const boundsRef = useRef<LatLngBounds | null>(null);

    const [layers, setLayers] = useState<(HidroLayer | HidroLayerGroup)[]>(data.hidroLayers.layers);

    useEffect(() => {
        data.hidroLayers.subscribe(setLayers);
    }, []);

    useEffect(() => {
        if (!mapRef.current) return;

        mapRef.current.eachLayer((layer) => (mapRef.current as Map).removeLayer(layer));
        mapRef.current.addLayer(data.hidroLayers.getLayers());

        const bounds = data.hidroLayers.getBounds();
        if (!bounds) return;
        if (!boundsRef.current) {
            boundsRef.current = bounds;
            mapRef.current.fitBounds(bounds);
        }
        mapRef.current.setMaxBounds(bounds);
    }, [mapRef.current, layers]);

    return <MapContainer ref={mapRef} {...data}>{data.children}</MapContainer>;
};
