import React, { ReactNode } from "react";
import { Button, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CustomModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    content: ReactNode;
}

export const InfoModal: React.FC<CustomModalProps> = ({
    open,
    onClose,
    title,
    content,
}) => {
    const theme = useTheme();

    const primaryColor = theme.palette.primary.main;
    const bkColor = theme.palette.background.paper;

    return (
        <Modal open={open} onClose={onClose}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: bkColor,
                    padding: "20px",
                    borderRadius: 10,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1">{content}</Typography>
                <div style={{ textAlign: "right", marginTop: "20px" }}>
                    <Button sx={{ border: "1px solid", borderRadius: 6 }} onClick={onClose}>
                        OK
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
