import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Button, IconButton, Tooltip, Typography, Alert } from '@mui/material';
import logoHbr from '../../assets/logo-hbr.svg';
import googleIcon from '../../assets/google-color-svgrepo-com.svg';
import microsoftIcon from '../../assets/microsoft-svgrepo-com.svg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MapIcon from '@mui/icons-material/Map';

import { Column, Row } from '../../components/shared/layout/column_and_row';
import { useOrientation } from '../../contexts/OrientationContext';
import { Charts } from './grafic';
import { HidroTextField } from '../../components/shared/inputs/hidro_text_field';
import { Spacer } from '../../components';
import { useAppThemeContext } from '../../contexts';
import { getApiSchema } from '../../services';
import { InfoModal } from '../../components/modals/infoModal';
interface LoginFormState {
    username: string;
    password: string;
}

// Funções de login
async function loginMs() {
    // Dados da API
    const api = getApiSchema();
    const response = await api.auth.loginMS();

    if (response.status === 200) {
        const redirectTo = response.data['url'];
        window.location.href = redirectTo;
    }
}
async function loginGoogle() {
    // Dados da API
    const api = getApiSchema();
    const response = await api.auth.loginGoogle();

    if (response.status === 200) {
        const redirectTo = response.data['url'];
        window.location.href = redirectTo;
    }
}
async function login(loginData: LoginFormState) {
    // Dados da API
    const api = getApiSchema();

    try {
        await api.auth.login(loginData.username, loginData.password);
    } catch (e) {
        //TODO: Tratamento de erro da tela de login
    }
}

export function LoginPage() {
    useEffect(() => {
        document.title = 'Login';
    });

    // Obter o tema atual
    const theme = useTheme();
    const { themeType } = useAppThemeContext();

    // Acessar as propriedades do tema
    const primaryColor = theme.palette.primary.main;
    const secondaryColor = theme.palette.secondary.main;
    const bkColor = theme.palette.background.default;

    const [showAlert, setShowAlert] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    let width = '80vw';
    const orientation = useOrientation();
    if (orientation === undefined) {
        if (window.matchMedia('(orientation: landscape)').matches) {
            width = '30vw';
        }
    } else if (orientation === 'landscape') {
        width = '30vw';
    }

    // Item complementares
    function LoginItems() {
        const theme = useTheme();

        const backgroundColor = themeType ? theme.palette.primary.main : theme.palette.secondary.main;
        const background = theme.palette.text.secondary;
        const backgroundText = theme.palette.background.paper;

        const newTheme = React.useMemo(
            () => ({
                ...theme,
                palette: {
                    ...theme.palette,
                    background: {
                        ...theme.palette.background,
                        default: 'transparent',
                    },
                },
            }),
            [theme]
        );

        const [formData, setFormData] = useState<LoginFormState>({
            username: '',
            password: '',
        });

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        };

        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            login(formData);
        };

        const navigate = useNavigate();

        const clickToObserver = () => {
            navigate('/observatorio');
        };

        const handleOpenDialog = () => {
            setOpenDialog(true);
        };

        return (
            <Box
                sx={{
                    background: backgroundColor,
                    width: '100%',
                    height: '80vh',
                    borderRadius: '10px',
                    borderTopLeftRadius: '85px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    boxShadow: 10,
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        width: '80%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Column theme={newTheme}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h4" color={backgroundText}>
                                Olá! <br />
                                Bem-vindo de volta. <br />
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h5" color={backgroundText}>
                                Entrar no sistema
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title="Login com Google">
                                <IconButton
                                    // onClick={loginGoogle}
                                    onClick={handleOpenDialog}
                                    sx={{
                                        backgroundColor: backgroundText,
                                        color: 'black',
                                        borderRadius: '13px',
                                        fontSize: '16px',
                                        display: 'flex',
                                        padding: '8px',
                                        width: '40%',
                                        height: '36px',
                                        border: `2px solid ${background}`,
                                        '&:hover': {
                                            backgroundColor: backgroundText,
                                        },
                                    }}
                                >
                                    <div style={{ flexGrow: 1 }} />
                                    <img src={googleIcon} alt="GOOGLE" width="20px" height="20px" />
                                    <div style={{ flexGrow: 1 }} />
                                    <Typography variant="button">Google</Typography>
                                    <div style={{ flexGrow: 1 }} />
                                </IconButton>
                            </Tooltip>

                            <div style={{ width: '15px' }} />

                            <Tooltip title="Login com Microsoft">
                                <IconButton
                                    // onClick={loginMs}
                                    onClick={handleOpenDialog}
                                    sx={{
                                        backgroundColor: backgroundText,
                                        color: 'black',
                                        borderRadius: '13px',
                                        fontSize: '16px',
                                        display: 'flex',
                                        padding: '8px',
                                        width: '40%',
                                        border: `2px solid ${background}`,
                                        height: '36px',
                                        '&:hover': {
                                            backgroundColor: backgroundText,
                                        },
                                    }}
                                >
                                    <div style={{ flexGrow: 1 }} />
                                    <img src={microsoftIcon} alt="MS" width="20px" height="20px" />
                                    <div style={{ flexGrow: 1 }} />
                                    <Typography variant="button">Microsoft</Typography>
                                    <div style={{ flexGrow: 1 }} />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{ height: '35px' }} />

                        <form onSubmit={handleSubmit} style={{ marginLeft: '30px', marginRight: '30px' }}>
                            <Grid container spacing={'10px'} justifyContent="center" alignItems="center" display="flex">
                                <Grid item xs={12}>
                                    <HidroTextField id="username" name="username" label="Nome de usuário" value={formData.username} onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <HidroTextField id="password" name="password" type="password" label="Senha" showPass={true} value={formData.password} onChange={handleInputChange} />
                                </Grid>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '12px',
                                    }}
                                >
                                    <div style={{ display: 'flex', flex: '1' }} />
                                    <Button
                                        // type="submit"
                                        onClick={handleOpenDialog}
                                        sx={{
                                            borderRadius: '20px',
                                            backgroundColor: background,
                                            color: backgroundText,
                                            width: '10vw',
                                            '&:hover': {
                                                backgroundColor: background,
                                            },
                                        }}
                                    >
                                        Entrar
                                    </Button>

                                    <Button
                                        sx={{
                                            ml: 2,
                                            borderRadius: '20px',
                                            backgroundColor: background,
                                            color: backgroundText,
                                            width: '10vw',
                                            '&:hover': {
                                                backgroundColor: background,
                                            },
                                        }}
                                        onClick={clickToObserver}
                                    >
                                        Observatório
                                    </Button>
                                </div>
                            </Grid>
                        </form>

                        <div style={{ height: '15px' }} />
                    </Column>
                </Box>
            </Box>
        );
    }

    function ComunicationItems() {
        return (
            <Row>
                <Spacer />

                <Tooltip title="LinkedIn">
                    <IconButton
                        component="a"
                        href="https://www.linkedin.com/company/hidrobr-consultoria"
                        target="_blank"
                        sx={{
                            height: '45px',
                            width: '45px',
                        }}
                    >
                        <LinkedInIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Nosso site">
                    <IconButton
                        component="a"
                        href="https://www.hidrobr.com/"
                        target="_blank"
                        sx={{
                            height: '45px',
                            width: '45px',
                        }}
                    >
                        <LanguageIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="(31) 3504-2733">
                    <IconButton
                        component="a"
                        href="tel:+553135042733"
                        target="_blank"
                        sx={{
                            height: '45px',
                            width: '45px',
                        }}
                    >
                        <LocalPhoneIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Como chegar">
                    <IconButton
                        component="a"
                        // eslint-disable-next-line max-len
                        href="https://www.google.com.br/maps/place/HIDROBR/@-19.9272503,-43.9300457,17z/data=!4m9!1m2!2m1!1sAvenida+Brasil,+n%C2%BA+888+14%C2%BA+Andar+Belo+Horizonte+30140-000+Minas+Gerais+BR!3m5!1s0xa6976333f7c8bd:0x3592e740ce7a3398!8m2!3d-19.9272138!4d-43.927828!15sCktBdmVuaWRhIEJyYXNpbCwgbsK6IDg4OCAxNMK6IEFuZGFyIEJlbG8gSG9yaXpvbnRlIDMwMTQwLTAwMCBNaW5hcyBHZXJhaXMgQlKSARJoeWRyYXVsaWNfZW5naW5lZXLgAQA"
                        target="_blank"
                        sx={{
                            height: '45px',
                            width: '45px',
                        }}
                    >
                        <MapIcon />
                    </IconButton>
                </Tooltip>

                <div style={{ width: '15px' }} />
            </Row>
        );
    }

    return (
        <Box
            sx={{
                background: `linear-gradient(to left, ${themeType ? secondaryColor : primaryColor}, ${themeType ? primaryColor : secondaryColor})`,
                height: '100vh',
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <Box
                width={'80vw'}
                height={'80vh'}
                borderRadius={'10px'}
                sx={{
                    position: 'relative',
                    borderBottomRightRadius: '85px',
                    borderTopLeftRadius: orientation === 'portrait' || orientation === undefined ? '85px' : undefined,
                    boxShadow: 10,
                    overflow: 'hidden',
                    display: orientation === 'landscape' ? undefined : 'flex',
                    backgroundColor: bkColor,
                }}
            >
                {showAlert && (
                    <Alert
                        severity="warning"
                        onClose={() => setShowAlert(false)}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                        }}
                    >
                        O sistema ainda está em implementação.
                    </Alert>
                )}

                {orientation === 'landscape' && (
                    <Row>
                        <Grid item>
                            <Column>
                                <Box height="65vh" width="50vw" margin="10px">
                                    <Charts />
                                </Box>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '30px',
                                        left: '30px',
                                    }}
                                >
                                    <img
                                        src={logoHbr}
                                        alt="Logo HBR"
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            background: bkColor,
                                        }}
                                    />
                                </div>
                                <Box sx={{ height: '10%' }}>
                                    <ComunicationItems />
                                </Box>
                            </Column>
                        </Grid>
                        <Box width={width}>
                            <LoginItems />
                        </Box>
                    </Row>
                )}

                {(orientation === 'portrait' || orientation === undefined) && (
                    <Column>
                        <Box sx={{ height: '81%', display: 'flex', flex: 1 }}>
                            <LoginItems />
                        </Box>
                        <Box sx={{ height: '10%', flex: 1 }}>
                            <ComunicationItems />
                        </Box>
                        <Box sx={{ height: '10%', flex: 1 }} />
                    </Column>
                )}
                <InfoModal
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    title="
                        Olá, agradecemos o seu interesse! Sistema ainda em
                        desenvolvimento. "
                    content={
                        <Typography>
                            {' '}
                            Convidamos você a explorar o "Observatório", nossa área de demonstração, onde você pode ter um vislumbre das funcionalidades e recursos que estão por vir. <br />
                            Para acessar o Observatório, por favor,{' '}
                            <a href="/observatorio" rel="noopener noreferrer">
                                clique aqui
                            </a>
                            . Agradecemos a sua paciência e compreensão.{' '}
                        </Typography>
                    }
                />
            </Box>
        </Box>
    );
}
