import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { LoginPage, SistemContextController } from '../pages';
import ObservatorioPage from '../pages/observatorio/observatorio';
import { setClaritySesion, useUserContext } from '../core';
import { LandingPage } from '../pages/landingPage/landingPage';

type PrivateRouteProps = {
    element: React.ComponentType;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Element }) => {
    const { user } = useUserContext();
    return user ? <Element /> : <Navigate to="/login" />;
};

export const AppRoutes: React.FC = () => {
    //Inicializando o clarity para o usuário corrente
    const userContext = useUserContext();
    setClaritySesion(userContext.user?.email);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ObservatorioPage />} />
                <Route path="*" element={<ObservatorioPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/observatorio" element={<ObservatorioPage />} />
                <Route path="/landing" element={<LandingPage />} />

                {/* Páginas do sistema, atualizam o contexto do sistema */}
                <Route path="/sistema/*" element={<SistemContextController />} />
            </Routes>
        </BrowserRouter>
    );
};