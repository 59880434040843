import React, { useEffect, useState, useRef } from 'react';
import { markerClusterGroup, MarkerClusterOptions, TileLayer } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getTooltipArea, HidroLoading } from '../../components';
import { HidroAppBar } from '../../components/shared/appBar/appBarObservatorio';
import { LatLng } from 'leaflet';
import { Snackbar, Alert, Box, AlertTitle, Typography, Divider, useMediaQuery, Theme } from '@mui/material';

import { AreaGeom, ConsumoData, FluvioStation, HidroLayer, HidroLayerData, HidroLayerGroup, HidroLayersData, TelemetricaStation, Trechos, TrechosError } from '../../core';
import { ResultArea, ResultAreaRequestError, getAreas } from './get_areas';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import { highlightStyleBacias, styleBacias } from './styles';
import { ResultStationRequestError, getConsumoData, getDataConvencional, getDataTelemetricasCache, getDataTelemetricasNow } from './get_stations_data';
import FormModal from '../../components/observatorio/formularioCadastro/formulario';
import { DEBUG, observatorioCancelSendForm, observatorioOpenOutrasAreas } from '../../enviroment';
import { getTrechos } from './get_trechos';
import { HidroMap } from '../../components/mapa/mapa';
import { HidroLayersComponent } from '../../components/mapa/layersComponent';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ObservatoryLegendStatic from '../../components/observatorio/legenda/observatoryLegendStatic';
import CustomZoomControl from '../../components/observatorio/zoomControl';
import { ButtonAndInfo } from '../../components/observatorio/button_and_info';
import HidroLayersComponentCollapsible from './HidroLayersComponentCollapsible ';

interface RequestError {
    error: string;
    message: string;
}

interface IndexProps {
    limiteEstado: HidroLayerGroup;
}

const ObservatorioPage: React.FC = () => {
    // Layers do mapa
    const tileLayer = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });
    const hidroTileLayer = new HidroLayer({
        name: 'Open Street Map',
        layer: tileLayer,
        showing: true,
    });
    const layerRef = useRef<HidroLayerGroup>(
        new HidroLayerGroup({
            name: 'name',
            showing: true,
            layers: [hidroTileLayer],
        })
    );

    // Dados da clusterização
    const clusterOptions: MarkerClusterOptions = {
        maxClusterRadius: (zoom: number) => {
            if (zoom < 10) return 25;
            if (zoom < 14) return 10;
            return 25;
        },
        disableClusteringAtZoom: 18,
    };

    // Gerenciamento de estado
    const limiteEstado = useRef<HidroLayer | undefined>(undefined);
    const [areasDrenagem, setAreasDrenagem] = useState<AreaGeom[] | undefined>(undefined);

    const [telemetriaData, setTelemetriaData] = useState<{ [key: string]: TelemetricaStation | null; } | undefined>(undefined);
    const [telemetriaDataCache, setTelemetriaDataCache] = useState<{ [key: string]: TelemetricaStation | null; } | undefined>(undefined);
    const [convencionalData, setVazaoMinima] = useState<{ [key: string]: FluvioStation | null; } | undefined>(undefined);
    const [consumoData, setConsumoData] = useState<{ [key: string]: ConsumoData; } | undefined>(undefined);

    const [error, setError] = useState<RequestError | null>(null);
    const [formOpen, setFormOpen] = useState(false);

    // Obtendo as geometrias das áreas e dos trechos
    useEffect(() => {
        const getData = async () => {
            const result: ResultArea | ResultAreaRequestError = await getAreas();
            if ('error' in result) {
                setError(result);
                return;
            }

            const layer = new HidroLayer({
                name: "Limite MG",
                layer: result.limiteEstado,
                showing: true,
            });
            limiteEstado.current = layer;
            layerRef.current.push(layer);

            const resultTrechos: { [key: string]: Trechos; } | TrechosError = await getTrechos();
            if ('error' in resultTrechos) {
                setError(resultTrechos as TrechosError);
                return;
            }

            for (const area of result.areasDrenagem) {
                const nome = area.properties.nome;
                const trecho = resultTrechos[nome];
                if (trecho) area.trechos = trecho;
            }

            setAreasDrenagem(result.areasDrenagem);
        };

        getData();
    }, []);

    // Loop dos dados das telemétricas
    useEffect(() => {
        const getData = async () => {
            const result: { [key: string]: TelemetricaStation; } | ResultStationRequestError = await getDataTelemetricasNow();
            if ('error' in result) {
                setError(result as RequestError);
                return;
            }
            setTelemetriaData(result);
        };

        getData();
        const intervalId = setInterval(getData, 600000);
        return () => clearInterval(intervalId);
    }, []);

    // Dados das estações convencionais
    useEffect(() => {
        const getData = async () => {
            const result: { [key: string]: FluvioStation; } | ResultStationRequestError = await getDataConvencional();
            if ('error' in result) {
                setError(result as RequestError);
                return;
            }
            setVazaoMinima(result);
        };

        getData();
    }, []);

    // Dados das estações telemétricas
    useEffect(() => {
        const getData = async () => {
            const result: { [key: string]: TelemetricaStation; } | ResultStationRequestError = await getDataTelemetricasCache();
            if ('error' in result) {
                setError(result as RequestError);
                return;
            }
            setTelemetriaDataCache(result);
        };
        getData();
    }, []);

    // Consumo e outorgas
    useEffect(() => {
        const getData = async () => {
            const result = await getConsumoData();
            if ('error' in result) {
                setError(result as RequestError);
                return;
            }
            setConsumoData(result);
        };

        getData();
    }, []);

    // Carregando estilos com os dados das estações
    useEffect(() => {
        if (!areasDrenagem || (!telemetriaData && !telemetriaDataCache) || !convencionalData) return;
        const telemetria = (telemetriaData ?? telemetriaDataCache) as { [key: string]: TelemetricaStation; };

        for (const area of areasDrenagem) {
            const areaName = area.properties.nome;
            const areaM2 = area.properties.areaM2;

            const tele = telemetria[areaName];
            const conv = convencionalData[areaName] as FluvioStation;

            const style = styleBacias(tele, conv);
            const higlineStyle = highlightStyleBacias(tele, conv);

            area.normalStyle = style;
            area.highlightStyle = higlineStyle;
            area.setStyle(style);

            if (!consumoData || !consumoData[areaName]) return;
            const outo = consumoData[areaName];

            const tooltip = getTooltipArea(areaName, areaM2, outo, tele, conv);
            area.bindTooltip(tooltip, {
                permanent: false,
                direction: 'top',
            });
        }
    }, [areasDrenagem, telemetriaData, telemetriaDataCache, convencionalData, consumoData]);

    // Adicionando todas as layers
    useEffect(() => {
        if (!areasDrenagem) return;

        layerRef.current.clearLayers();

        for (const area of areasDrenagem) {
            const name = area.properties.nome;
            const layersAdd: (HidroLayerData | HidroLayersData)[] = [];

            // Outorgas
            if (consumoData && consumoData[name]) {
                const cluster = markerClusterGroup(clusterOptions);
                consumoData[name].outorgas.forEach((layer) => cluster.addLayer(layer));
                layersAdd.push({
                    name: "Outorgas",
                    layer: cluster,
                    showing: false,
                });
            }

            // Telemétricas
            const teleData = telemetriaData ?? telemetriaDataCache;
            if (teleData && teleData[name]) {
                layersAdd.push({
                    name: "Estação Telemétrica",
                    layer: teleData[name] as TelemetricaStation,
                    showing: true,
                });
            }

            // Convencionais
            if (convencionalData && convencionalData[name]) {
                const conv = convencionalData[name] as FluvioStation;
                const latlng = conv.getLatLng();

                const offsetLat = latlng.lat + (Math.random() - 0.5) * 0.001;
                const offsetLon = latlng.lng + (Math.random() - 0.5) * 0.001;
                conv.setLatLng(new LatLng(offsetLat, offsetLon));

                layersAdd.push({
                    name: "Estação Convencional",
                    layer: convencionalData[name] as FluvioStation,
                    showing: true,
                });
            }

            // Dados da bacia
            const layersBacia: HidroLayer[] = [
                new HidroLayer({
                    name: "Área de Drenagem",
                    showing: true,
                    layer: area,
                })
            ];

            if (area.trechos instanceof Trechos) {
                layersBacia.push(
                    new HidroLayer({
                        name: "Hidrografia",
                        layer: area.trechos,
                        showing: true,
                    })
                );
            }


            layersAdd.push({
                name: "Bacia",
                layers: layersBacia,
                showing: true,
            });

            const layersGroup: (HidroLayer | HidroLayerGroup)[] = layersAdd.map((layer) => {
                if ("layers" in layer) return new HidroLayerGroup(layer);
                return new HidroLayer(layer);
            });

            layerRef.current.push(
                new HidroLayerGroup({
                    name: "Bacia do " + name,
                    layers: layersGroup,
                    showing: true,
                }),
            );
        }

        if (limiteEstado.current) layerRef.current.push(limiteEstado.current);
        layerRef.current.push(hidroTileLayer);
    }, [areasDrenagem, telemetriaData, telemetriaDataCache, convencionalData, consumoData]);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const handleShowForm = () => {
        if (!DEBUG) window.clarity('event', observatorioOpenOutrasAreas);
        setFormOpen(true);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Observatório Hidrológico - HIDROlogic</title>
                <meta name="description" content="Explore o observatório hidrológico do HIDROlogic para análise e gestão eficaz dos recursos hídricos." />
                <meta name="keywords" content="HIDROlogic, observatório hidrológico, recursos hídricos, Minas Gerais" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Observatório Hidrológico - HIDROlogic",
                        "description": "Explore o observatório hidrológico do HIDROlogic para análise e gestão eficaz dos recursos hídricos.",
                        "url": "https://hidrologic.com.br"
                    }
                    `}
                </script>
            </Helmet>

            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <HidroAppBar logoPosition="center" text={`Observatório Hidrológico`} tooltipLogo="HIDROLogic" />

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        overflowY: 'auto',
                        position: 'relative',
                    }}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1000
                        }}
                    >
                        <ObservatoryLegendStatic />
                    </Box>

                    <Box style={{ flex: 1, position: 'relative' }}>
                        <HidroMap
                            hidroLayers={layerRef.current}
                            style={{ height: '100%', width: '100%' }}
                            center={limiteEstado.current ? undefined : new LatLng(-19.927384522375732, -43.92783170138633)}
                            zoom={15}
                            maxBoundsViscosity={2}
                            maxBounds={limiteEstado.current?.getBounds() ?? undefined}
                            bounds={limiteEstado.current?.getBounds() ?? undefined}
                            maxZoom={18}
                            minZoom={6}
                        >
                            <CustomZoomControl />
                        </HidroMap>
                    </Box>

                    {isMobile ? (
                        <HidroLayersComponentCollapsible
                            hidroLayers={layerRef.current}
                            onShowForm={handleShowForm}
                        />
                    ) : (
                        <div style={{
                            width: "17.25rem",
                            backgroundColor: "background",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0.75rem",
                        }}>
                            <Typography fontSize={20}>Camadas:</Typography>
                            <Divider />
                            <div style={{ height: "0.313rem" }} />
                            <HidroLayersComponent hidroLayers={layerRef.current} />

                            <ButtonAndInfo onShowForm={handleShowForm} />
                        </div>
                    )}
                </Box>

                <FormModal
                    open={formOpen}
                    onClose={() => {
                        if (!DEBUG) {
                            window.clarity('event', observatorioCancelSendForm);
                        }
                        setFormOpen(false);
                    }}
                />

                <HidroLoading open={!(layerRef.current.layers.length > 1)} />

                <Snackbar open={error !== null} message={error?.error ?? ''}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        <AlertTitle>{error?.error}</AlertTitle>
                        {error?.message ?? ''}
                    </Alert>
                </Snackbar>

            </Box>
        </HelmetProvider>
    );
};

export default ObservatorioPage;
