import { LatLngExpression, LayerGroup, Polyline } from "leaflet";
import { paintByConditions, PaintLineString } from "../style";
import * as geojson from "geojson";

export interface TrechosError {
    error: string;
    message: string;
}

export interface TrechoData {
    coTrecho: number | null;
    noDestino?: number | null;
    noOrigem?: number | null;
    coDesague?: number | null;
    coCursoAgua?: number | null;
    distFozBacia?: number | null;
    distFozCurso?: number | null;
    distFozRio?: number | null;
    distFozCosta?: number | null;
    compTrecho?: number | null;
    compRio?: number | null;
    areaContri?: number | null;
    areaReferente?: number | null;
    areaMontante?: number | null;
    ordemCurso?: number | null;
    nuStrahler?: number | null;
    coBacia?: string | null;
    nomeCompleto?: string | null;
    nomeGenerico?: string | null;
    nomeLigacao?: string | null;
    nomeEspecifico?: string | null;
    nomeOriginal?: string | null;
}

export class Trecho<T extends TrechoData = TrechoData> extends Polyline<geojson.LineString, T> {

    // Propriedades da geometria
    public properties: T;

    public bbox = this.getBounds();

    // Estilos
    public normalStyle: PaintLineString = {};
    public highlightStyle: PaintLineString | undefined = undefined;
    public clickStyle: PaintLineString | undefined = undefined;

    // Contrutor da Classe de Área
    constructor(
        latlng: LatLngExpression[],
        properties: T,
        paintOptions: PaintLineString,
    ) {
        super(latlng, paintOptions);

        this.properties = properties;

        // Definindo os estilos para cada tipo de condição
        if (paintOptions.paintCategory) {
            this.normalStyle = paintByConditions(this.properties, paintOptions.paintCategory);
        } else {
            this.normalStyle = paintOptions;
        }

        if (paintOptions.paintClick?.paintCategory) {
            this.clickStyle = paintByConditions(this.properties, paintOptions.paintClick?.paintCategory);
        } else {
            this.clickStyle = paintOptions.paintClick;
        }

        if (paintOptions.paintHovered?.paintCategory) {
            this.highlightStyle = paintByConditions(this.properties, paintOptions.paintHovered?.paintCategory);
        } else {
            this.highlightStyle = paintOptions.paintHovered;
        }

        // Inicializando os operadores de estilo
        this.on({
            mouseover: this.highlightFeature,
            mouseout: this.resetStyle,
            click: this.clickFeature,
        });

    }

    private resetStyle = () => this.setStyle(this.normalStyle);
    private highlightFeature = () => this.highlightStyle ? this.setStyle(this.highlightStyle) : null;
    private clickFeature = () => this.clickStyle ? this.setStyle(this.clickStyle) : null;

}

export class Trechos extends LayerGroup<Trecho> {
    // Estilos
    private _normalStyle: PaintLineString = {};
    public get normalStyle(): PaintLineString { return this._normalStyle; }
    public set normalStyle(val: PaintLineString) {
        this._normalStyle = val;
        this.eachLayer((layer) => {
            const lay = layer as Trecho;
            lay.normalStyle = this._normalStyle;
            lay.setStyle(this._normalStyle);
        });
    }

    private _highlightStyle: PaintLineString | undefined = {};
    public get highlightStyle(): PaintLineString | undefined { return this._highlightStyle; }
    public set highlightStyle(val: PaintLineString | undefined) {
        this._highlightStyle = val;
        if (this._highlightStyle) {
            this.eachLayer((layer) => {
                const lay = layer as Trecho;
                lay.highlightStyle = this._highlightStyle;
            });
        }
    }

    private _clickStyle: PaintLineString | undefined = {};
    public get clickStyle(): PaintLineString | undefined { return this._clickStyle; }
    public set clickStyle(val: PaintLineString | undefined) {
        this._clickStyle = val;
        if (this._clickStyle) {
            this.eachLayer((layer) => {
                const lay = layer as Trecho;
                lay.clickStyle = this._clickStyle;
            });
        }
    }

    constructor(layers: Trecho[], paintOptions: PaintLineString) {
        super(layers, paintOptions);

        // Definindo os estilos para cada tipo de condição
        this.normalStyle = paintOptions;
        this.clickStyle = paintOptions.paintClick;
        this.highlightStyle = paintOptions.paintHovered;

        this.eachLayer((layer) => {
            const lay = layer as Trecho;
            lay.normalStyle = this.normalStyle;
            lay.highlightStyle = this.highlightStyle;
            lay.clickStyle = this.clickStyle;
            lay.setStyle(this.normalStyle);
        });

    }
}


// Funções para gerar os dados
export function getTrechoByGeoJson<T extends TrechoData = TrechoData>(
    feature: geojson.Feature<geojson.LineString, T>,
    paintOptions: PaintLineString,
) {
    const geometry = feature.geometry;
    const latLngs = positionsToLatLngs(geometry.coordinates);
    return new Trecho<T>(latLngs, feature.properties, paintOptions);
}

export function getTrechosByGeoJson<T extends TrechoData = TrechoData>(
    collection: geojson.FeatureCollection<geojson.LineString, T>,
    paintOptions: PaintLineString,
) {
    const features: Trecho[] = [];

    collection.features.forEach((feature) => {
        const trecho = getTrechoByGeoJson(feature, paintOptions);
        features.push(trecho);
    });
    return new Trechos(features, paintOptions);
}


// Funções apra transformar de geojson.Position para LatLngExpression !!! Incompatíveis
const positionToLatLng = (position: geojson.Position): LatLngExpression => {
    const [longitude, latitude] = position;
    return [latitude, longitude];
};

const positionsToLatLngs = (positions: geojson.Position[]): LatLngExpression[] => positions.map(positionToLatLng);
