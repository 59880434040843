// Função para criar um ícone SVG com cor variável
export const svgWithColor = async (svgUrl: string, color: string): Promise<string> => {
    // Função para obter o SVG como texto e substituir a cor
    const fetchSvg = async (url: string): Promise<string> => {
        const response = await fetch(url);
        return response.text();
    };

    return fetchSvg(svgUrl).then(svgText => {
        // Substituir a cor de preenchimento (fill) no SVG
        const updatedSvg = svgText.replace(/fill="[^"]*"/g, `fill="${color}"`);
        return updatedSvg;
    });
};