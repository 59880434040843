import { InternalAxiosRequestConfig } from "axios";
import { HidroCryper } from "../../core";

export async function onRequest(config: InternalAxiosRequestConfig) {
    if (!config.url?.endsWith("/auth/refresh")) {
        const encryptedData: string = localStorage.getItem("token") ?? "";
        const token = HidroCryper.decrypt(encryptedData);
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.withCredentials = true;
    return config;
}