import React, { useState } from 'react';
import { Box, Typography, IconButton, AppBar, Toolbar, Tooltip, Avatar, Icon } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { Color, useUserContext } from '../../../core';
import HBRLogo from '../../../assets/hidrobrLogo.png';
import { useOrientation } from '../../../contexts/OrientationContext';
import { DEBUG } from '../../../enviroment';
import { getApiSchema } from '../../../services';

interface IconOpts {
    icon?: string;
    src?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    tooltip?: string;
}

interface AppBarOpts {
    leading?: IconOpts | JSX.Element;
    theme?: Theme;
    onReturn?: React.MouseEventHandler<HTMLButtonElement>;
    onTapLogo?: React.MouseEventHandler<HTMLButtonElement>;
    text?: string;
    tooltipRetornar?: string;
    tooltipLogo?: string;
    gradient?: string;
    logoPosition?: 'left' | 'right' | 'center' | 'auto';
    trailing?: IconOpts;
}

interface SettingsItem {
    text: string;
    icon: string;
    onClick: React.MouseEventHandler<HTMLLIElement>;
}

export const LogoHidrologicAppBar = (logoPosition: string, orientation?: string, tooltipLogo?: string, onTapLogo?: React.MouseEventHandler<HTMLButtonElement>, logoTextColor?: string) => {
    const imageLogo = (<Tooltip title={tooltipLogo ?? 'HIDROBR'}>
        <IconButton
            onClick={onTapLogo}
            sx={{
                height: '2.875rem',
                width: '2.875rem',
            }}
        >
            <a href='http://15.228.229.248/login' style={{
                width: 'auto',
                height: '100%',
                objectFit: 'cover',
                
            }}
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                    alt="HBRLogo"
                    src={HBRLogo}
                    style={{
                        width: 'auto',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                /></a>
        </IconButton>
    </Tooltip>
    );

    const textLogo = (
        <><a href='http://15.228.229.248/login' style={{
            width: 'auto',
            height: '100%',
            objectFit: 'cover',
            textDecoration: 'none',
            display: 'flex', 
            flexDirection: 'row',    
        }}
        target="_blank"
        rel="noopener noreferrer"
        >
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22} color={'white'}>
                    HIDRO
                </Typography>
            )}
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22} color={logoTextColor}>
                    Logic
                </Typography>
            )}
        </a>
        </>
    );

    return (
        <>
            {logoPosition !== "right" && imageLogo}
            {logoPosition === "right" && textLogo}
            <div style={{ width: "0.313rem" }} />
            {logoPosition === "right" && imageLogo}
            {logoPosition !== "right" && textLogo}
        </>
    );
};

export const HidroAppBar: React.FC<AppBarOpts> = ({ leading, theme, onReturn, onTapLogo, text, tooltipRetornar, tooltipLogo, logoPosition = 'auto', gradient }) => {
    // Contexto da Api e do usuário
    const api = getApiSchema();
    const user = useUserContext();

    // Icones de opções na lateral
    const options: SettingsItem[] = [];
    options.push({
        text: 'Perfil',
        icon: 'person',
        onClick: () => {
            if (DEBUG) {
                console.log('Iria para a página do usuário');
            } else {
                window.location.href = '/user';
            }
        },
    });
    if (user.user?.adm)
        options.push({
            text: 'ADM',
            icon: 'admin_panel_settings',
            onClick: () => {
                if (DEBUG) {
                    console.log('Iria para a página do ADM');
                } else {
                    window.location.href = '/adm';
                }
            },
        });
    options.push({
        text: 'HIDROLogic',
        icon: 'architecture',
        onClick: () => {
            if (!window.location.href.endsWith('/sistema')) {
                window.location.href = '/sistema';
            }
        },
    });
    options.push({
        text: 'Logout',
        icon: 'logout',
        onClick: () => api.user.logout(),
    });

    // Variaveis de controle de estado
    const orientation = useOrientation();
    const [userPerfilError, setUserPerfilError] = useState(false);

    // Variáveis de tema e organização
    const themeDefaut = useTheme();
    const themeNow = theme ?? themeDefaut;

    const bkColor = new Color(themeNow.palette.primary.dark);
    const textColor = bkColor.contrast();
    const logoTextColor = new Color(themeNow.palette.secondary.main);

    const logoAppBar = LogoHidrologicAppBar(logoPosition, orientation, tooltipLogo, onTapLogo, logoTextColor.toHex);

    let leadingItem = <div />;
    if (logoPosition === 'left') {
        if (onReturn) {
            leadingItem = (
                <>
                    <Tooltip title={tooltipRetornar ?? ''}>
                        <IconButton onClick={onReturn}>
                            <Avatar
                                sx={{
                                    height: '2.25rem',
                                    width: '2.25rem',
                                    backgroundColor: '#0000',
                                }}
                            >
                                <Icon sx={{ color: textColor.toHex }}> arrow_back </Icon>
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                    {logoAppBar}
                </>
            );
        } else {
            leadingItem = logoAppBar;
        }
    } else if (onReturn !== undefined) {
        leadingItem = (
            <>
                <Tooltip title={tooltipRetornar ?? ''}>
                    <IconButton onClick={onReturn}>
                        <Avatar
                            sx={{
                                height: '2.25rem',
                                width: '2.25rem',
                                backgroundColor: '#0000',
                            }}
                        >
                            <Icon sx={{ color: textColor.toHex }}> arrow_back </Icon>
                        </Avatar>
                    </IconButton>
                </Tooltip>
                {text && (
                    <Typography noWrap variant="h6">
                        {' '}
                        {text}{' '}
                    </Typography>
                )}
            </>
        );
    } else if (leading !== undefined) {
        if ((leading as IconOpts).onClick || (leading as IconOpts).icon || (leading as IconOpts).src || (leading as IconOpts).tooltip) {
            leading = leading as IconOpts;
            leadingItem = (
                <Tooltip title={tooltipRetornar ?? ''}>
                    <IconButton onClick={leading.onClick}>
                        <Avatar
                            sx={{
                                height: '2.25rem',
                                width: '2.25rem',
                                backgroundColor: '#0000',
                            }}
                        >
                            {leading.src !== undefined && !userPerfilError ? (
                                <img
                                    alt="userPhoto"
                                    src={leading.src}
                                    onError={() => setUserPerfilError(true)}
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <Icon sx={{ color: textColor.toHex }}> {leading.icon} </Icon>
                            )}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            );
        } else {
            leadingItem = (leading as JSX.Element);
        }
    } else if (text) {
        leadingItem = (
            <Typography noWrap variant="h6">
                {' '}
                {text}{' '}
            </Typography>
        );
    }

    let centerItem = <div />;
    if (logoPosition === 'center' || logoPosition === 'auto') {
        centerItem = logoAppBar;
    } else if (logoPosition !== 'right' && text && orientation === 'landscape') {
        centerItem = (
            <Typography noWrap variant="h6">
                {' '}
                {text}{' '}
            </Typography>
        );
    }

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: bkColor.toHex,
                paddingInline: '0.938rem',
                background: gradient,
                height: '4rem',
                boxSizing: 'border-box',
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingY: 1,
                    height: '100%',
                }}
            >
                {/* Item da esquerda */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {leadingItem}
                </Box>

                {/* Item central */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ml: { xs: 0, sm: -35 }
                    }}
                >
                    {centerItem}
                </Box>

            </Toolbar>
        </AppBar>
    );
};
