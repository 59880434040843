import { useState } from 'react';
import { Box, Button, Icon, Link, List, ListItem, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InfoModal } from '../modals/infoModal';
import { Spacer } from '../shared';


export const ButtonAndInfo = ({ onShowForm }: { onShowForm?: () => void; }) => {
    const theme = useTheme();
    const textColor = theme.palette.getContrastText(theme.palette.background.paper);

    const [isModalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => setModalOpen(false);

    const navigate = useNavigate();

    const handleClick = () => navigate('/landing');


    return (
        <Box
            sx={{
                color: textColor,
                width: "95%",
                padding: "7px",
                backgroundColor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
            }}
        >

            <div style={{ flex: 1, minHeight: '10px' }} />

            {/* Botão */}
            <Tooltip title="Você pode solicitar informações adicionais e/ou ajustar a escala para diferentes bacias hidrográficas.">
                <Button
                    variant="contained"
                    onClick={handleClick}
                    color="secondary"
                    sx={{ height: "56px" }}
                >
                    Ver outras bacias
                </Button>
            </Tooltip>

            {/* modal informativo */}
            <InfoModal open={isModalOpen} onClose={handleCloseModal} title='Informações'
                content={
                    <Box>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    As regionalizações foram realizadas utilizando o Hidro
                                </Typography>
                                <Typography variant="body1" color="secondary" sx={{ fontWeight: 'bold' }}>
                                    Logic
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                através da obtenção de dados públicos pelas seguintes fontes.
                            </Typography>

                            <Spacer />

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <List sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                        <Typography variant="body1" fontWeight="bold">
                                            - Agência Nacional de águas (ANA)
                                        </Typography>
                                    </ListItem>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, mt: -1, mb: -1 }}>
                                        <Link
                                            href="https://www.snirh.gov.br/hidrotelemetria/gerarGrafico.aspx"
                                            color="inherit"
                                            underline="hover"
                                            target="_blank"
                                            sx={{ textDecoration: "underline" }}
                                        >
                                            - Estações telemétricas
                                        </Link>
                                    </ListItem>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
                                        <Link
                                            href="https://www.snirh.gov.br/hidroweb/apresentacao"
                                            color="inherit"
                                            underline="hover"
                                            target="_blank"
                                            sx={{ textDecoration: "underline" }}
                                        >
                                            - Estações convencionais
                                        </Link>
                                    </ListItem>
                                </List>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <List sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                        <Typography variant="body1" fontWeight="bold">
                                            - Secretaria de estado de meio ambiente e desenvolvimento sustentável de MG (Semad)
                                        </Typography>
                                    </ListItem>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, mt: -1 }}>
                                        <Link
                                            href="http://sistemas.meioambiente.mg.gov.br/licenciamento/site/lista-outorgas"
                                            color="inherit"
                                            underline="hover"
                                            target="_blank"
                                            sx={{ textDecoration: "underline" }}
                                        >
                                            - Outorgas
                                        </Link>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Box>
                }
            />
        </Box>
    );
};
