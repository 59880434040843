import React, { useState } from "react";
import { DrawerMenu } from "../../components/home/draweComponents/drawerMenu";
import { OpenStreetMap } from "../../components/home/mapComponents/openStreet";
import { Column, HidroAppBar, HidroLoading, Row } from "../../components";
import { useUserContext, useRequestUserErrorContext } from "../../core";
import { useUserConfigContext } from "../../core/user_estudo/user/userConfig";
import { Box, Grid } from "@mui/material";
import { useEstudoContext } from "../../core/user_estudo/estudo/estudo";

export function SistemPage() {

    // Estados da tela
    const [openLoading, setOpenLoading] = useState<boolean>(true);

    // Dados do usuário
    const { user } = useUserContext();

    // Dados do estudo
    const { estudo } = useEstudoContext();

    // TODO: Trabalhar na mensagem e ações de erro quando não conseguir obter os dados do usuário
    const requestUserError = useRequestUserErrorContext().requestUserErrorData;

    // Configurações do usuário
    const userConfigContext = useUserConfigContext();
    const userConfig = userConfigContext.userConfig;

    // Verificando se os dados iniciais foram inicializados para retirar o login
    if (openLoading && (user && userConfig) && (estudo !== undefined)) setOpenLoading(false);

    return (
        <>
            <HidroLoading open={openLoading} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
            }}>
                <HidroAppBar
                    trailing={{
                        src: user?.photoURL,
                        icon: "account_circle",
                    }}
                    text={user?.displayName}
                    tooltipLogo="HIDROLogic"
                />

                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <DrawerMenu />
                    <OpenStreetMap />
                </div>
            </Box>
        </>
    );
}
