import React from "react";
import Modal from "@mui/material/Modal";
import { MapContainer, TileLayer } from "react-leaflet";

export const MapZoomModal = ({
    open,
    onClose,
    mapUrl,
}: {
    open: boolean;
    onClose: () => void;
    mapUrl: string;
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <MapContainer
                center={[-19.924444, -43.938888]}
                zoom={13}
                style={{ height: "calc(100vh - 4rem)", width: "100%" }}
            >
                <TileLayer url={mapUrl} />
            </MapContainer>
        </Modal>
    );
};
