import { DEBUG } from "../../enviroment";
import { significantType } from "./number";

export * from "./number";

declare global {
    interface Number {
        toSignificantDigits(significantDigits: significantType): string;
    }
    interface Window {
        clarity: any;
    }
}

declare module 'leaflet' {
    export interface MarkerClusterOptions {
        showCoverageOnHover?: ((zoom: number) => boolean) | boolean;
        zoomToBoundsOnClick?: ((zoom: number) => boolean) | boolean;
        spiderfyOnMaxZoom?: ((zoom: number) => boolean) | boolean;
        removeOutsideVisibleBounds?: ((zoom: number) => boolean) | boolean;
        animate?: ((zoom: number) => boolean) | boolean;
        disableClusteringAtZoom?: ((zoom: number) => number) | number;
        maxClusterRadius?: ((zoom: number) => number) | number;
        singleMarkerMode?: ((zoom: number) => boolean) | boolean;
        spiderfyDistanceMultiplier?: ((zoom: number) => number) | number;
        polygonOptions?: PathOptions;
    }
    export function markerClusterGroup(options?: MarkerClusterOptions): MarkerClusterGroup;
    export class MarkerClusterGroup extends FeatureGroup {
        addLayer(layer: Layer): this;
        removeLayer(layer: Layer): this;
        clearLayers(): this;
    }
}


export const startPrototypes = () => {
    // Não retirar esta função daqui, pois é necessário inicializar este arquivo
    if (DEBUG) console.log("Start nos prototypes");
};