import React, { useState } from 'react';
import { Box, IconButton, Typography, Divider, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { HidroLayersComponent } from '../../components/mapa/layersComponent';
import { ButtonAndInfo } from '../../components/observatorio/button_and_info';
import { HidroLayerGroup } from '../../core';

interface HidroLayersComponentCollapsibleProps {
    hidroLayers: HidroLayerGroup;
    onShowForm: () => void;
}

const HidroLayersComponentCollapsible: React.FC<HidroLayersComponentCollapsibleProps> = ({ hidroLayers, onShowForm }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', position: 'relative' }}>
            <Box
                sx={{
                    width: isCollapsed ? 0 : 276,
                    backgroundColor: 'background.paper',
                    display: "flex",
                    flexDirection: "column",
                    padding: isCollapsed ? 0 : "0.75rem",
                    transition: 'width 0.3s ease-in-out',
                    overflow: 'hidden',
                }}
            >
                {!isCollapsed && (
                    <>
                        <Typography fontSize={20}>Camadas:</Typography>
                        <Divider />
                        <div style={{ height: "0.313rem" }} />
                        <HidroLayersComponent hidroLayers={hidroLayers} />
                        <Box sx={{
                            display: 'flex',
                            mb: 15
                        }}>
                            <ButtonAndInfo onShowForm={onShowForm} />
                        </Box>
                    </>
                )}
            </Box>
            <Tooltip title="Editar camadas" arrow>
                <IconButton
                    onClick={toggleCollapse}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: isCollapsed ? 0 : 'auto',
                        left: isCollapsed ? 'auto' : 264,
                        transform: 'translateY(-50%)',
                        backgroundColor: 'background.paper',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        },
                        transition: 'right 0.3s ease-in-out, left 0.3s ease-in-out',
                        zIndex: 1000,
                    }}
                >
                    {isCollapsed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default HidroLayersComponentCollapsible;