export enum Tempo {
    segundos = 1,
    minutos = 60,
    horas = 3600,
    dias = 86400,
    semanas = 604800,
    milissegundos = 0.001,
    microssegundos = 0.000001,
    nanossegundos = 0.000000001,
    picossegundos = 1e-12,
    femtossegundos = 1e-15,
}