import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const CustomZoomControl = () => {
    const map = useMap();

    useEffect(() => {
        map.removeControl(map.zoomControl);

        const zoomControl = L.control.zoom({
            position: 'topright' 
        });

        zoomControl.addTo(map);

        return () => {
            map.removeControl(zoomControl);
        };
    }, [map]);

    return null;
};

export default CustomZoomControl;