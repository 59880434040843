import React, { ReactNode } from "react";
import { useTheme, Theme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { Spacer } from "./espacador";

// Interface para definir o tipo de dados que a coluna irá aceitar
interface ColumnProps {
    children: ReactNode[] | ReactNode;
    key?: React.Key | null;
    style?: React.CSSProperties;
    theme?: Theme;
}

// Definição do componente de coluna
export function Column({ children, style, theme }: ColumnProps) {
    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;

    //const bkColor = currentTheme.palette.background.default;
    const color = currentTheme.palette.text.primary;

    // Estilo
    const newStyle: React.CSSProperties = style ? { ...style } : { color: color };

    const isArray = Array.isArray(children);

    if (isArray && children) {
        children = (children as ReactNode[]).map((item, index) => {
            const isGridItem = React.isValidElement(item) && (item.type === Grid || item.type === Spacer);

            if (isGridItem) {
                return item;
            }

            return (
                <Grid item xs={12} key={index} sx={newStyle}>
                    {item}
                </Grid>
            );
        });
    }

    return (
        <Grid container spacing={2}>
            {children}
        </Grid>
    );
}

// Definição do componente de coluna
export function Row({ children, style, theme }: ColumnProps) {
    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;

    //const bkColor = currentTheme.palette.background.default;
    const color = currentTheme.palette.text.primary;

    // Estilo
    const newStyle: React.CSSProperties = style ? { ...style } : { color: color };

    const isArray = Array.isArray(children);

    if (isArray && children) {
        children = (children as ReactNode[]).map((item, index) => {
            const isGridItem = React.isValidElement(item) && (item.type === Grid || item.type === Spacer);

            if (isGridItem) {
                return item;
            }

            return (
                <Grid item xs="auto" key={index} sx={newStyle}>
                    {item}
                </Grid>
            );
        });
    }

    return (
        <Grid container spacing={2} wrap="nowrap">
            {children}
        </Grid>
    );
}
