export class ItemExporter {
    constructor(
        {
            nome,
            tag,
            exportar,
            children,
        }: {
            nome: string;
            tag?: string;
            exportar?: boolean;
            children?: ItemExporter[];
        }
    ) {
        this.nome = nome;
        this.tag = tag ?? "";

        if (exportar !== undefined && children !== undefined) {
            throw Error(
                "Um export com exportar definido não pode ter chidrens"
            );
        }

        this.exportar = exportar;
        this.children = children;
    }

    nome = "";
    tag: string;
    exportar?: boolean;
    children?: ItemExporter[];
}

export const dadosExportar: ItemExporter[] = [
    new ItemExporter({
        nome: "Dados do Estudo",
        children: [
            new ItemExporter({
                nome: "Exportar Planilha",
                tag: "exportPlanilha",
                exportar: true,
            }),
            new ItemExporter({
                nome: "Exportar dados georreferenciados",
                tag: "exportGeo",
                exportar: false,
            }),
        ]
    }),
    new ItemExporter({
        nome: "Balanço Hídrico",
        children: [
            new ItemExporter({
                nome: "Regionalização",
                children: [

                    new ItemExporter({
                        nome: "Estações",
                        children: [
                            new ItemExporter({
                                nome: "Exportar planilha das estações",
                                tag: "exportPlan",
                                exportar: false,
                            }),
                            new ItemExporter({
                                // eslint-disable-next-line max-len
                                nome: "Exportar pontos georreferenciados das estações",
                                tag: "exportPlan",
                                exportar: false,
                            }),
                            new ItemExporter({
                                // eslint-disable-next-line max-len
                                nome: "Exportar dados detalhados de cada estação",
                                tag: "exportPlan",
                                exportar: false,
                            }),
                        ],
                    }),


                    new ItemExporter({
                        nome: "Exportar planilha",
                        tag: "export",
                        exportar: false,
                    }),

                ]
            }),
            new ItemExporter({
                nome: "Outorgas",
                children: [
                    new ItemExporter({
                        nome: "Exportar planilha",
                        tag: "export",
                        exportar: false,
                    }),
                    new ItemExporter({
                        nome: "Exportar dados georreferenciados",
                        tag: "export",
                        exportar: false,
                    }),
                ]
            }),
        ]
    }),
    new ItemExporter({
        nome: "Exportar regionalizações",
        tag: "tag",
        exportar: false,
    }),
];
