import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Button, Box, Snackbar, Alert } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import SaveIcon from "@mui/icons-material/Save";
import L from "leaflet";
import { toPng } from "html-to-image";

import { MapConfigModal } from "./mapConfigModal";
import { LeftMenu } from "../leftMenu";
import { MapZoomModal } from "./mapZoomModal";

export function Mapas() {
    const [selectedMap, setSelectedMap] = useState<string>("Open Street Map");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openZoomModal, setOpenZoomModal] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [markerPosition, setMarkerPosition] = useState<L.LatLngTuple | null>(
        null
    );
    const mapRef = useRef<L.Map>(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const maps: { [key: string]: string; } = {
        "Fundo Branco": "",
        "Open Street Map": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "Google Maps": "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
        "Google Satellite": "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        "Google Terrain": "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    };

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            map.on("click", handleMapClick);
        }
    }, []);

    // const handleMapClick = (e: L.LeafletMouseEvent) => {
    //   setMarkerPosition([e.latlng.lat, e.latlng.lng]);
    // };

    const handleOpenZoomModal = () => {
        setOpenZoomModal(true);
        setOpenMenu(true);
    };

    const handleCloseZoomModal = () => {
        setOpenZoomModal(false);
        setOpenMenu(false);
    };

    const handleMapModalClose = (mapName: string) => {
        setSelectedMap(mapName);
        setOpenModal(false);
    };

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleMapClick = (e: any) => {
        setMarkerPosition([e.latlng.lat, e.latlng.lng]);
    };

    const handleScreenshot = () => {
        const mapContainer = document.getElementById("map-container");
        if (!mapContainer) return;

        toPng(mapContainer)
            .then(function (dataUrl: string) {
                const link = document.createElement("a");
                link.download = "map-screenshot.png";
                link.href = dataUrl;
                link.click();
            })
            .catch(function (error: any) {
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div style={{ position: "relative", width: "100%", height: "92vh" }}>
            {/* <Box
                sx={{
                    position: "absolute",
                    height: "100%",
                    overflowY: "auto",
                    backgroundColor: "white",
                }}> */}
            <LeftMenu />
            {/* </Box> */}
            {/* <Box>
                {!openMenu && (
                    <Button
                        variant="contained"
                        sx={{
                            position: "absolute",
                            top: 20,
                            left: 320,
                            zIndex: 9999,
                            backgroundColor: "white",
                            borderRadius: 10,
                            color: "black",
                            "&:hover": { backgroundColor: "#C2D7DE" },
                        }}
                        onClick={() => setOpenMenu(true)}
                    >
                        <TuneIcon sx={{ fontSize: "20px" }} />
                    </Button>
                )}
            </Box> */}
            <MapContainer
                id="map-container"
                center={[-19.924444, -43.938888]}
                zoom={13}
                style={{ height: "100%", width: "100%" }}
                zoomControl={false}
                ref={mapRef}
            >
                <TileLayer url={maps[selectedMap]} />
                {markerPosition && (
                    <Marker position={markerPosition}>
                        <Popup>
                            Lat/Long: {markerPosition[0]}, {markerPosition[1]}
                        </Popup>
                    </Marker>
                )}
            </MapContainer>
            <Box
                sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 1000,
                    display: "flex",
                    gap: "10px",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        height: "40px",
                        width: "40px",
                        backgroundColor: "transparent",
                        color: "black",
                        "&:hover": { backgroundColor: "#C2D7DE" },
                    }}
                    onClick={() => setOpenModal(true)}
                >
                    <TuneIcon sx={{ fontSize: "20px" }} />
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        height: "40px",
                        width: "40px",
                        backgroundColor: "transparent",
                        color: "black",
                        "&:hover": { backgroundColor: "#C2D7DE" },
                    }}
                    onClick={handleScreenshot}
                >
                    <AddAPhotoIcon sx={{ fontSize: "20px" }} />
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        height: "40px",
                        width: "40px",
                        backgroundColor: "transparent",
                        color: "black",
                        "&:hover": { backgroundColor: "#C2D7DE" },
                    }}
                    onClick={handleOpenZoomModal}
                >
                    <AspectRatioIcon sx={{ fontSize: "20px" }} />
                </Button>
            </Box>
            <div
                style={{
                    position: "absolute",
                    bottom: 20,
                    right: 20,
                    zIndex: 1000,
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: 3,
                        color: "white",
                    }}
                >
                    <SaveIcon sx={{ fontSize: "20px", mr: 1 }} />
                    Finalizar
                </Button>
            </div>
            <MapConfigModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                onSelectMap={handleMapModalClose}
            />
            {/* {openMenu && <EstacoesDisponiveisModal onClose={handleCloseMenu} />} */}

            <MapZoomModal
                open={openZoomModal}
                onClose={handleCloseZoomModal}
                mapUrl={maps[selectedMap]}
            />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Ocorreu um erro ao gerar a captura de tela. Por favor, tente
                    novamente.
                </Alert>
            </Snackbar>
        </div>
    );
}
