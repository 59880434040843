import { UnitFactor, Area, Volume } from "../../unidades";
import { Tempo } from "../../unidades/tempo";


export interface UserConfig {
    // Interface de Configuração do Usuário

    // UX
    theme?: boolean; // true -> LightTheme | false -> DarkTheme

    // Hidrologia
    areaDrenagemUnits: UnitFactor;
    Q710Units: UnitFactor;
    q710Units: UnitFactor;

    // Gráficos
    limiarVazaoMinima?: number;
    limiarVazaoMaxima?: number;

    // TODO: Demais unidades
}

export const defaultUserConfig: UserConfig = {
    theme: undefined,

    areaDrenagemUnits: { numerador: Area.km2 },
    Q710Units: { numerador: Volume.m3, denominador: Tempo.segundos },
    q710Units: { numerador: Volume.m3, denominador: [Tempo.segundos, Area.km2] },

    limiarVazaoMinima: undefined,
    limiarVazaoMaxima: undefined,
};