import { HidroCryper } from "../crypter/hidro_cryper";

// Eventos do observatório
export const observatorioOpenOutrasAreas = "openOutrasAreas";
export const observatorioSendForm = "sendFormAreas";
export const observatorioSendFormError = "sendFormAreasError";
export const observatorioCancelSendForm = "cancelSendForm";

interface ClaritySessionResponse {
    id: string;
    session?: string;
    page?: string;
    userHint: string;
}

export const setClaritySesion = async (emailOrId?: string): Promise<ClaritySessionResponse | undefined> => {
    let userId = emailOrId;

    if (!userId) {
        const clarityId = localStorage.getItem("clarityId");
        if (clarityId) {
            userId = HidroCryper.decrypt(clarityId);

        } else {
            userId = generateRandomHash(10);
            const encriptedId = HidroCryper.encrypt(userId);
            localStorage.setItem("clarityId", encriptedId);
        }
    }


    const clarityData = await window.clarity("identify", userId);
    return clarityData;
};

function generateRandomHash(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charsetLength = charset.length;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charsetLength);
        result += charset[randomIndex];
    }

    return result;
}
