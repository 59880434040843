import { Grid } from "@mui/material";

// Definição do componente de espaçamento
export function Spacer(
    {
        height,
        width,
    }: {
        height?: string | number;
        width?: string | number;
    }) {
    if (height || width) {

        return <div style={{
            height: height,
            width: width,
            display: (height === undefined && width === undefined) ? "flex" : undefined,
            flex: (height === undefined && width === undefined) ? "1" : undefined,
        }} />;
    }

    return <Grid item xs />;
}