import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        custom: true;
    }
}
export const LightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#005979',
            dark: '#00384d',
            light: '#0184b4',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#00906A',
            dark: '#00694d',
            light: '#00b887',
            contrastText: '#FFFFFF',
        },
        background: {
            default: '#CDE9FD',
            paper: '#FFFFFF',
        },
    },
    typography: {
        htmlFontSize: 14,
        fontFamily: 'Catamaran',
        fontSize: 14,
        fontWeightBold: 750,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightRegular: 250,
        button: {
            fontWeight: 750,
        },
    },
    // components: {
    //     global: {
    //         '*::-webkit-scrollbar': {
    //             width: '0.4em'
    //         },
    //         '*::-webkit-scrollbar-track': {
    //             '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    //         },
    //         '*::-webkit-scrollbar-thumb': {
    //             backgroundColor: 'rgba(0,0,0,.1)',
    //             outline: '1px solid slategrey'
    //         }
    //     },
    // }
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            custom: 1030,
        },
    },
});
