import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Modal, Fade, TextField, Button, Typography, Box } from '@mui/material';
import PhoneInput from './phoneInput';
import { useTheme } from '@mui/material/styles';
import { SubscriptionForm, sendSubscriptionForm } from './formService';
import { DEBUG, observatorioSendForm, observatorioSendFormError } from '../../../enviroment';

interface FormModalProps {
    open: boolean;
    onClose: () => void;
    autoFillData?: SubscriptionForm;
}

const FormModal: React.FC<FormModalProps> = ({ open, onClose, autoFillData }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState<SubscriptionForm>({
        nome: '',
        telefone: '',
        email: '',
        empresa: '',
        areaInteresse: '',
        comentarios: '',
    });

    useEffect(() => {
        if (autoFillData) {
            setFormData(autoFillData);
        }
    }, [autoFillData]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!formData.email) {
            return;
        }

        if (formData.comentarios.length > 1000) {
            return;
        }

        const sanitizedPhone = formData.telefone.replace(/[()\-\s]/g, '');

        const result = await sendSubscriptionForm({ ...formData, telefone: sanitizedPhone });

        if ('error' in result) {
            if (!DEBUG) {
                window.clarity('event', observatorioSendFormError);
            }
            return;
        } else {
            if (!DEBUG) {
                window.clarity('event', observatorioSendForm);
            }

            setFormData({
                nome: '',
                telefone: '',
                email: '',
                empresa: '',
                areaInteresse: '',
                comentarios: '',
            });
            onClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Fade in={open}>
                <Box
                    style={{
                        backgroundColor: theme.palette.background.paper,
                        border: '2px solid #000',
                        boxShadow: theme.shadows[5],
                        padding: theme.spacing(2, 4, 3),
                        width: '500px',
                        borderRadius: '15px',
                    }}
                >
                    <Typography variant="h6">Preencha os Dados</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField margin="dense" name="nome" label="Nome" type="text" fullWidth value={formData.nome} onChange={handleChange} />
                        <PhoneInput label="Telefone" name="telefone" value={formData.telefone} onChange={handleChange} fullWidth variant="outlined" />
                        <TextField margin="dense" name="email" label="E-mail" type="email" fullWidth required value={formData.email} onChange={handleChange} />
                        <TextField margin="dense" name="empresa" label="Empresa" type="text" fullWidth value={formData.empresa} onChange={handleChange} />
                        <TextField margin="dense" name="areaInteresse" label="Área de Interesse" type="text" fullWidth value={formData.areaInteresse} onChange={handleChange} />
                        <TextField margin="dense" name="comentarios" label="Comentários" type="text" fullWidth multiline rows={4} value={formData.comentarios} onChange={handleChange} inputProps={{ maxLength: 1000 }} />
                        <Box mt={2} textAlign="right">
                            <Button onClick={onClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary">
                                Enviar
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
};

export default FormModal;
