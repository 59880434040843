import { HidroLayer, HidroLayerGroup } from '../../core';
import { HidroLayerComponent } from './layerComponent';
import { useEffect, useState } from 'react';

interface Props {
    hidroLayers: HidroLayerGroup;
    maxWidth?: string | number;
    maxHeight?: string | number;
}

export const HidroLayersComponent: React.FC<Props> = (data) => {
    const [layers, setLayers] = useState<(HidroLayer | HidroLayerGroup)[]>(data.hidroLayers.layers);
    useEffect(() => {
        data.hidroLayers.subscribe(setLayers);
    }, []);

    return <div style={{
        maxWidth: data.maxWidth,
        maxHeight: data.maxHeight,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "auto",
    }}>
        {layers.map((layer, index) => <HidroLayerComponent key={index} hidroLayer={layer} maxWidth={data.maxWidth} />)}
    </div>;
};
