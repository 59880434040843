import { AppThemeProvider, OrientationProvider } from './contexts';
import { AppRoutes } from './routes';
import { KeyEventHandler } from './services';
import { HidroCryper, UserProvider } from './core';
import { UserConfigProvider } from './core/user_estudo/user/userConfig';
import { Api } from './contexts/StartApi';
import { DEBUG, REFRESH_TOKEN } from './enviroment';
import { startPrototypes } from './core/prototypes'; // Não retirar, importa nossos protoypes

export const App = () => {
    startPrototypes();

    if (DEBUG && process.env.NODE_ENV !== 'production') {
        const encrypted = HidroCryper.encrypt(REFRESH_TOKEN ?? '');
        localStorage.setItem('refreshToken', encrypted);
    }

    return (
        <AppThemeProvider>
            <KeyEventHandler>
                <Api>
                    <UserProvider>
                        <UserConfigProvider>
                            <OrientationProvider>
                                <AppRoutes />
                            </OrientationProvider>
                        </UserConfigProvider>
                    </UserProvider>
                </Api>
            </KeyEventHandler>
        </AppThemeProvider>
    );
};
