import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const getEstudos = async (desativados = false): Promise<AxiosResponse> => {
    const data = { desativados: desativados };
    return await axios.get(`${API_URL}/estudo/estudos`, { data });
};

const getEstudo = async (estudoId: string): Promise<AxiosResponse> => {
    const data = { "estudo_id": estudoId };
    return await axios.get(`${API_URL}/estudo`, { data });
};

const postEstudo = async (
    nome: string,
    lat?: number | null,
    lon?: number | null,
    anoHidrologico?: number[] | null,
    crs?: string | null,
): Promise<AxiosResponse> => {
    const data = {
        nome: nome,
        "ano_hidrologico": anoHidrologico,
        lat: lat,
        lon: lon,
        crs: crs,
    };
    return await axios.post(`${API_URL}/estudo`, data);
};

const deleteEstudo = async (estudoId: string): Promise<AxiosResponse> => {
    const data = { "estudo_id": estudoId };
    return await axios.delete(`${API_URL}/estudo`, { data });
};

const updateEstudo = async (
    id?: string | null,
    lat?: number | null,
    lon?: number | null,
    crs?: string | null,
    nome?: string | null,
    anoHidrologico?: number[] | null,
    porcQ710?: number | null,
    ativo?: boolean | null,
    regionalizacaoAtivaId?: number | null,
): Promise<AxiosResponse> => {
    const data = {
        "estudo_id": id,
        lat: lat,
        lon: lon,
        crs: crs,
        nome: nome,
        "ano_hidrologico": anoHidrologico,
        ativo: ativo,
        "regionalizacao_ativa": regionalizacaoAtivaId,
        "porcentagem_q7_10": porcQ710,
    };
    return await axios.put(`${API_URL}/estudo`, data);
};

const getEstudoData = async (estudoId: string): Promise<AxiosResponse> => {
    const data = { "estudo_id": estudoId };
    return await axios.get(`${API_URL}/estudo/dados`, { data });
};

const estudoGPKG = async (
    estudoId?: string,
    gpkgEstudo?: boolean,
    regionalizacoes?: boolean,
    excelRegio?: boolean,
    excelEstacoes?: boolean,
    gpkgEstacoes?: boolean,
    detalhesEstacoes?: boolean,
    excelOutorgas?: boolean,
    gpkgOutorgas?: boolean,
): Promise<AxiosResponse> => {
    const data = {
        "estudo_id": estudoId,
        "gpkg_estudo": gpkgEstudo,
        regionalizacoes: regionalizacoes,
        "excel_regio": excelRegio,
        "excel_estacoes": excelEstacoes,
        "gpkg_estacoes": gpkgEstacoes,
        "detalhes_estacoes": detalhesEstacoes,
        "excel_outorgas": excelOutorgas,
        "gpkg_outorgas": gpkgOutorgas,
    };
    return await axios.get(
        `${API_URL}/estudo/gpkg`,
        {
            data,
            responseType: 'blob'
        }
    );
};

export const ApiEstudo = {
    getEstudos: getEstudos,
    getEstudo: getEstudo,
    postEstudo:postEstudo,
    deleteEstudo: deleteEstudo,
    updateEstudo: updateEstudo,
    getEstudoData: getEstudoData,
    estudoGPKG: estudoGPKG,


};
