import React, { useState } from "react";
import { useTheme, Theme } from "@mui/material/styles";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Color } from "../../../core";

interface ColumnProps {
    value: string;
    id?: string;
    name?: string;
    label?: string;
    type?: string;
    showPass?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    theme?: Theme;
}

// Definição do componente de coluna
export function HidroTextField({
    value,
    id = "text",
    name = "text",
    label,
    type,
    showPass,
    onChange,
    theme
}: ColumnProps) {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;
    const backgroundText = currentTheme.palette.background.paper;

    return (
        <TextField
            fullWidth
            id={id}
            name={name}
            label={label}
            value={value}
            type={type === "password" ? (showPassword ? "text" : "password") : type}
            onChange={onChange}
            InputLabelProps={{
                style: { color: backgroundText },
            }}
            variant="standard"
            InputProps={{
                disableUnderline: true,
                style: { color: backgroundText },
                endAdornment: !showPass ? undefined : (
                    <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? (
                                <VisibilityOff style={{ color: backgroundText }} />
                            ) : (
                                <Visibility style={{ color: backgroundText }} />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            sx={{
                borderBottom: `1px solid ${backgroundText}`,
            }}
        />
    );
}
