import { Box, Modal, Button } from "@mui/material";

export const NoStudyModal = ({
    openModal,
    onCloseModal,
}: {
    openModal: boolean;
    onCloseModal: (value: boolean) => void;
}) => {
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "#DCE8EC",
                    border: "2px solid #000",
                    borderRadius: 10,
                    boxShadow: 24,
                    p: 3,
                }}
            >
                <h2
                    id="modal-modal-title"
                    style={{ marginTop: 0, fontSize: 24, fontWeight: 500 }}
                >
                    Escolha ou crie um estudo antes de prosseguir!
                </h2>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            textTransform: "none",
                            backgroundColor: "#DCE8EC",
                            color: "#005979",
                            border: "1px solid #005979",
                            borderRadius: 20,
                            fontSize: "14px",
                        }}
                        onClick={() => onCloseModal(false)}
                    >
                        OK
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
