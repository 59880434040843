export const DEBUG = process.env.NODE_ENV !== "production";

// URL de debug e testes
export const PORT = 8000;
export const INTERNAL_IP = "192.168.15.10"; //"localhost"; 

// URL em prod
export const PROD_URL = "https://hidrologic.com.br/api";

// URL para utilizar na aplicação
export const API_URL = DEBUG ? `http://${INTERNAL_IP}${PORT ? ':' : ''}${PORT}` : PROD_URL;

// Refresh token para testes
// eslint-disable-next-line max-len
const tokenRefresh = undefined;
export const REFRESH_TOKEN = process.env.NODE_ENV !== "production" ? tokenRefresh : undefined;

// Dados da criptografia tilizada
export const SECRET_KEY_CRYPTO = "JtK9mE7U2w4R4A2oBChNR8LpV6cY3S1gXT5zF04";
