import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Divider, Avatar, Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Column, Row } from "../layout/column_and_row";
import { useShowDebug } from "../../../services";
import { Spacer } from "../layout/espacador";
import { Color, HidroCryper, useRequestUserErrorContext, useUserContext } from "../../../core";
import { DEBUG } from "../../../enviroment";
import { useEstudoContext, useRequestEstudoErrorContext } from "../../../core/user_estudo/estudo/estudo";

type Props = {
    children?: React.ReactNode;
};

export const DebugModal: React.FC<Props> = ({ children }) => {
    const showDebug = useShowDebug();

    const [atualizarTela, setState] = useState(false);

    // Tema
    const theme = useTheme();
    const bkColor = new Color(theme.palette.text.primary);
    bkColor.invert();
    const textColor = bkColor.contrast();

    bkColor.o = 0.8;

    // Dados do usuário
    const {user} = useUserContext();
    const requestUserErrorData = useRequestUserErrorContext();
    const [imgPerfilError, setImgPerfilError] = useState(false);

    // Dados estudo
    const studyData = useEstudoContext();
    const requestStudyErrorData = useRequestEstudoErrorContext();

    // Tela de debug
    const timer = useRef(setInterval(() => { const a = 0; }, 100000));

    useEffect(() => {
        clearInterval(timer.current);
        timer.current = setInterval(() => {
            setState(!atualizarTela);
        }, 1000);
    });

    const encrypted = localStorage.getItem("token") ?? "";
    const authToken = HidroCryper.decrypt(encrypted);

    return (

        <>
            {children}
            {(showDebug ?? false) && DEBUG &&
                <Box sx={{
                    zIndex: theme.zIndex.drawer + 100,
                    height: "95vh",
                    width: "96vw",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    padding: "15px",
                    backgroundColor: bkColor.toHex,
                }}>
                    <Row>
                        <Box sx={{ width: "30vw" }}>
                            <Column>
                                {/* Tema */}
                                <Typography fontWeight={"bold"}>{`Thema: ${theme.palette.mode}`}</Typography>
                                <Divider />

                                {/* API */}
                                <Typography fontWeight={"bold"}>HTTP:</Typography>
                                <Typography fontSize={14}>{`AuthToken: ${authToken.slice(0, 60)}`}</Typography>
                                <Divider />

                                {/* User data */}
                                <Row>
                                    <Typography fontWeight={"bold"}>Dados do Usuário:</Typography>
                                    <Spacer />
                                    <Avatar sx={{
                                        height: "36px",
                                        width: "36px",
                                        backgroundColor: "#0000"
                                    }}>
                                        {
                                            user?.photoURL !== undefined && !imgPerfilError ? (
                                                <img
                                                    alt="trailingPhoto"
                                                    src={user?.photoURL}
                                                    onError={() => setImgPerfilError(true)}
                                                    style={{
                                                        width: "auto",
                                                        height: "100%",
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            ) : (<Icon sx={{ color: textColor.toHex }} > account_circle </Icon>)
                                        }
                                    </Avatar>
                                </Row>
                                <Typography fontSize={14}>{`Id: ${user?.id}`}</Typography>
                                <Typography fontSize={14}>{`Nome: ${user?.displayName.slice(0, 35)}`}</Typography>
                                <Typography fontSize={14}>{`Estudo Id: ${user?.estudoId?.slice(0, 35)}`}</Typography>
                                <Row>
                                    <Typography fontSize={14} fontWeight={"bold"}>Request error:</Typography>
                                    <Box width="5px" />
                                    <Typography fontSize={14}>{requestUserErrorData.requestUserErrorData?.error}</Typography>
                                </Row>
                                <Row>
                                    <Typography fontSize={14} fontWeight={"bold"}>Message:</Typography>
                                    <Box width="5px" />
                                    <Typography fontSize={14}>{requestUserErrorData.requestUserErrorData?.message}</Typography>
                                </Row>
                                <Divider />

                                {/* Study data */}
                                <Typography fontWeight={"bold"}>Dados do Estudo:</Typography>
                                <Typography fontSize={14}>{`Id: ${studyData.estudo?.id}`}</Typography>
                                <Typography fontSize={14}>{`Nome: ${studyData.estudo?.nome}`}</Typography>
                                <Typography fontSize={14}>{`Point: lat ${studyData.estudo?.lat}, lon ${studyData.estudo?.lon}`}</Typography>
                                <Typography fontSize={14}>{`Regionalizações: ${studyData.estudo?.regionalizacoes?.length}`}</Typography>
                                <Typography fontSize={14}>{`Outorgas: ${studyData.estudo?.outorgas?.length}`}</Typography>
                                <Row>
                                    <Typography fontSize={14} fontWeight={"bold"}>Request error:</Typography>
                                    <Box width="5px" />
                                    <Typography fontSize={14}>{requestStudyErrorData?.error}</Typography>
                                </Row>
                                <Row>
                                    <Typography fontSize={14} fontWeight={"bold"}>Request message:</Typography>
                                    <Box width="5px" />
                                    <Typography fontSize={14}>{requestStudyErrorData?.message}</Typography>
                                </Row>
                                <Divider />


                            </Column>
                        </Box>

                        <Spacer />

                        <Box sx={{ width: "30vw" }}>
                            <Column>
                                <Row>
                                    <Spacer />
                                    <Typography fontWeight={"bold"}>{`INFO`}</Typography>
                                </Row>
                                <Divider />
                            </Column>
                        </Box>

                    </Row>
                </Box>}
        </>
    );
};
