import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserConfig, defaultUserConfig } from './userConfigData';
import { useAppThemeContext } from '../../../contexts';

interface UserConfigContextData {
    userConfig?: UserConfig;
    setUserConfig: React.Dispatch<React.SetStateAction<UserConfig | undefined>>;
}

export const useUserConfigContext = (): UserConfigContextData => useContext(UserConfigContext);
const UserConfigContext = createContext({} as UserConfigContextData);

export const UserConfigProvider: React.FC<Props> = ({ children }) => {
    const [userConfig, setUserConfig] = useState<UserConfig | undefined>(undefined);
    const theme = useAppThemeContext();

    useEffect(() => {
        // TODO: Sincronizar os dados com as configurações do usuário no backend
        async function getUserData() {
            if (defaultUserConfig.theme !== undefined && theme.themeType !== defaultUserConfig.theme) theme.toggleTheme();
            setUserConfig(defaultUserConfig);
        }
        getUserData();
    });

    return (
        <UserConfigContext.Provider value={{ userConfig, setUserConfig }}>
            {children}
        </UserConfigContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};
