import React, { useState } from "react";
import { Box } from "@mui/material";
import { useUserConfigContext } from "../../core/user_estudo/user/userConfig";
import { useUserContext, useRequestUserErrorContext } from "../../core";
import { Mapas } from "./estacoesFluviometricas/mapComponents/maps";
import { HidroAppBar } from "../shared";
import { useRegionalizacaoContext } from "../../core/hidrologia/regionalizacao/regionalizacaoProvider";

export function EstacoesFluviometricas() {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const { user } = useUserContext();

    // Configurações do usuário
    const userConfigContext = useUserConfigContext();
    const userConfig = userConfigContext.userConfig;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <HidroAppBar
                trailing={{
                    src: user?.photoURL,
                    icon: "account_circle",
                }}
                text={user?.displayName}
                tooltipLogo="HIDROLogic"
            />
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                }}
            >
                <Mapas />
            </Box>
        </Box>
    );
}
