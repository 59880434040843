import React, { ChangeEvent } from "react";
import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

export interface IncrementInputProps {
    value: number;
    onIncrement: () => void;
    onDecrement: () => void;
    isEditing: boolean;
    onEditStart: () => void;
    inputValue: string;
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onEditEnd: () => void;
}

export const IncrementInput: React.FC<IncrementInputProps> = ({
    value, onIncrement, onDecrement, isEditing, onEditStart, inputValue, onInputChange, onEditEnd
}) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: -5 }}>
        <Button onClick={onDecrement}><ArrowLeftOutlinedIcon /></Button>
        {isEditing ? (
            <input
                type="number"
                value={inputValue}
                onChange={onInputChange}
                onBlur={onEditEnd}
                autoFocus
                style={{ width: '50px', textAlign: 'center' }}
            />
        ) : (
            <Typography
                variant="body1"
                onClick={onEditStart}
                style={{ cursor: 'pointer', width: '50px', textAlign: 'center' }}
            >
                {value.toFixed(2)}
            </Typography>
        )}
        <Button onClick={onIncrement}><ArrowRightOutlinedIcon /></Button>
    </Box>
);