import { AxiosResponse, isAxiosError } from "axios";
import { getTrechosByGeoJson, Trechos, TrechosError } from "../../core/layers_map";
import { getApiSchema } from "../../services";
import { styleRiosStatic } from "./styles";

export const getTrechos = async (): Promise<{ [key: string]: Trechos; } | TrechosError> => {
    const api = getApiSchema();

    const last = location.pathname.split("/").pop();
    const state: string = ((last != "observatorio") ? last : 'MG') ?? 'MG';

    let response: undefined | AxiosResponse = undefined;

    try {
        response = await api.observatorio.trechos(state);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (response.data) {
        const trechosData: { [key: string]: Trechos; } = {};
        for (const key in response.data) {
            const trechos = getTrechosByGeoJson(response.data[key], styleRiosStatic());
            trechosData[key] = trechos;
        }

        return trechosData;
    }

    return {
        error: "Erro",
        message: "Resposta vazia",
    };
};