import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const Q = async (
    estacao: number,
    x: number | string | number[],
    y?: number,
    anoHidrologico?: number[],
    returnGrafic?: boolean,
    dataCol?: string,
    vazaoCol?: string,
    vazaoStatusCol?: string,
    nivelConsistenciaCol?: string,
    unidadesCol?: string,
    metodoObtencaoCol?: string,
): Promise<AxiosResponse> => {
    const data: Map<
        string,
        number | string | number[] | boolean | undefined
    > = new Map();

    data.set("x", x);
    data.set("y", y);
    data.set("data", dataCol);
    data.set("vazao", vazaoCol);
    data.set("vazaoStatus", vazaoStatusCol);
    data.set("nivelConsistencia", nivelConsistenciaCol);
    data.set("unidades", unidadesCol);
    data.set("metodoObtencao", metodoObtencaoCol);
    data.set("return_grafic_data", returnGrafic);
    data.set("ano_hidrologico", anoHidrologico);
    data.set("codigo", estacao);

    return await axios.post(`${API_URL}/toolbox/hidrologia/Q`, data);
};

const qxy = async (
    codigo: number,
    x: string | number,
    y: number,
    anoHidrologico: number[],
    atualizar: boolean,
): Promise<AxiosResponse> => {
    const data: Map<
        string,
        number | string | number[] | boolean | undefined
    > = new Map();

    data.set("codigo", codigo);
    data.set("x", x);
    data.set("y", y);
    data.set("atualizar", atualizar);
    data.set("ano_hidrologico", anoHidrologico);

    return await axios.post(
        `${API_URL}/toolbox/hidrologia/Q_estacao`,
        data
    );
};

const qToXlsx = async (
    codigo: number,
    x: string | number,
    y: number,
    anoHidrologico: number[],
    // atualizar: boolean,
): Promise<AxiosResponse> => {
    const data: Map<
        string,
        number | string | number[] | boolean | undefined
    > = new Map();

    data.set("codigo", codigo);
    data.set("x", x);
    data.set("y", y);
    data.set("ano_hidrologico", anoHidrologico);

    return await axios.post(
        `${API_URL}/toolbox/hidrologia/estacao/xlsx`,
        data,
        { responseType: "blob" }
    );
};

export const ApiHidrologia = {
    Q: Q,
    qxy: qxy,
    qToXlsx: qToXlsx,
};
