import { divIcon, LatLng, LatLngLiteral, Marker } from "leaflet";
import OutoSVG from "../../../assets/outorgas.svg";
import { paintByConditions, PaintMarker, svgWithColor } from "../style";

export interface OutorgaData {
    link?: string;
    portaria?: string;
}

export class Outorga extends Marker<OutorgaData> {

    public properties: OutorgaData = {};

    public svgItem = OutoSVG;

    constructor(latlng: LatLng | LatLngLiteral, properties: OutorgaData, paintOptions: PaintMarker) {
        super(latlng, paintOptions);

        this.properties = properties;

        this.feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [latlng.lat, latlng.lng],
            },
            properties
        };

        if (!paintOptions.icon) {
            // Cor normal
            let paintNormal: PaintMarker = paintOptions;
            if (paintOptions.paintCategory) {
                paintNormal = paintByConditions(this.properties, paintOptions.paintCategory);
            }
            svgWithColor(this.svgItem, paintNormal.color ?? "#000000").then(updatedSvg => {
                const urlSVG2 = `data:image/svg+xml;base64,${btoa(updatedSvg)}`;
                this.svgItem = urlSVG2;
                const icon = divIcon({
                    html: this.getIconBySrc(
                        urlSVG2,
                        properties.portaria??"",
                        paintNormal.color ?? "black",
                        paintNormal.bgColor ?? "white",
                        paintNormal.iconSize ?? 20,
                    ),
                    className: 'custom-svg-icon',
                    iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
                });
                this.setIcon(icon);
            });

            const icon = divIcon({
                html: this.getIconBySrc(
                    this.svgItem,
                    properties.portaria??"",
                    paintNormal.color ?? "black",
                    paintNormal.bgColor ?? "white",
                    paintNormal.iconSize ?? 20,
                ),
                iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
            });
            this.setIcon(icon);
        }
    }

    private getIconBySrc = (src: string, alt: string | number, color: string, bgColor: string, size: number) => {
        return `<img
            src="${src}"
            alt="${alt}"
            style="
                width: ${size}px;
                height: ${size}px;
                border-radius: 50%;
                background-color: ${bgColor};
                border: 1px solid ${color};
            "/>`;
    };
}

export interface ConsumoData {
    consumoTotal: number;
    numeroOutorgas: number;
    outorgas: Outorga[];
}

export interface ResultConsumoRequestError {
    error: string;
    message: string;
}
