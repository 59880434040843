import React, { useEffect, useState, ChangeEvent } from "react";
import {
    Box,
    Button,
    Divider,
    List,
    ListItem,
    Typography,
    TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { IncrementInput } from "./filterComponents/incrementInput";
import { SearchField } from "./filterComponents/searchField";
import { CheckboxWithLabel } from "./filterComponents/checkboxWithLabel";
import { useIncrementalState } from "./filterComponents/incrementalState";

export function LeftMenu() {

    const [valueOrig, setValueOrig] = useState<number>(0);
    const [valueOrigMax, setValueOrigMax] = useState<number>(0);
    const [valueOrigMin, setValueOrigMin] = useState<number>(0);

    const [search, setSearch] = useState<string>("");
    const [checkedMax, setCheckedMax] = useState<boolean>(true);
    const [checkedMin, setCheckedMin] = useState<boolean>(true);
    const [checkedWater, setCheckedWater] = useState<boolean>(false);
    const [waterMassValue, setWaterMassValue] = useState<string>("10.00");

    const maxState = useIncrementalState(1.5, 0.1);
    const minState = useIncrementalState(0.5, 0.1);

    const handleCheckboxChange = (setter: React.Dispatch<React.SetStateAction<boolean>>) => (event: ChangeEvent<HTMLInputElement>) => setter(event.target.checked);
    const handleWaterMassChange = (event: ChangeEvent<HTMLInputElement>) => setWaterMassValue(event.target.value);

    const calcMax = () => setValueOrigMax(checkedMax ? parseFloat((maxState.value * valueOrig).toFixed(2)) : parseFloat(valueOrig.toFixed(2)));
    const calcMin = () => setValueOrigMin(checkedMin ? parseFloat((minState.value * valueOrig).toFixed(2)) : parseFloat(valueOrig.toFixed(2)));


    useEffect(() => {
        calcMax();
        calcMin();
    }, [checkedMax, checkedMin, maxState.value, minState.value, valueOrig]);

    return (
        <Box sx={{ position: 'fixed', top: 50, left: 0, bottom: 0, width: 300, backgroundColor: 'white', border: '1px solid', borderRadius: 3, zIndex: 1000, padding: '16px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Filtrar Estações</Typography>
            <Divider sx={{ border: '1px solid', borderColor: '#828B96', marginTop: '8px', marginBottom: '16px' }} />
            <Box sx={{ justifyContent: 'center', alignContent: 'center', ml: 4 }}>
                <SearchField value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Adicionar estação" />
                <Divider sx={{ border: '1px solid', borderColor: '#828B96', mt: 2, ml: -4 }} />
            </Box>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Área de estudo</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{valueOrig} Km²</Typography>
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
                <ListItem sx={{ display: 'flex', fontWeight: 'bold', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2, alignItems: 'flex-start', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginRight: 11, textAlign: 'left' }}>Proporção entre áreas</Typography>
                        <InfoOutlinedIcon sx={{ fontWeight: 'bold', fontSize: '16px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                        <CheckboxWithLabel checked={checkedMax} onChange={handleCheckboxChange(setCheckedMax)} label="Máxima" />
                        <IncrementInput
                            value={maxState.value}
                            onIncrement={maxState.handleIncrement}
                            onDecrement={maxState.handleDecrement}
                            isEditing={maxState.isEditing}
                            onEditStart={maxState.handleEditStart}
                            inputValue={maxState.inputValue}
                            onInputChange={maxState.handleInputChange}
                            onEditEnd={maxState.handleEditEnd}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                        <CheckboxWithLabel checked={checkedMin} onChange={handleCheckboxChange(setCheckedMin)} label="Mínima" />
                        <IncrementInput
                            value={minState.value}
                            onIncrement={minState.handleIncrement}
                            onDecrement={minState.handleDecrement}
                            isEditing={minState.isEditing}
                            onEditStart={minState.handleEditStart}
                            inputValue={minState.inputValue}
                            onInputChange={minState.handleInputChange}
                            onEditEnd={minState.handleEditEnd}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>Intervalo de áreas</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', marginLeft: 2 }}>
                            {checkedMin ? `${valueOrigMin} km²` : `-`} a {checkedMax ? `${valueOrigMax} km²` : `-`}
                        </Typography>
                    </Box>
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CheckboxWithLabel checked={checkedWater} onChange={handleCheckboxChange(setCheckedWater)} label="Checar massas d'agua" />
                    {checkedWater && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', whiteSpace: 'nowrap' }}>
                            <Typography sx={{ fontSize: '16px', marginRight: 1 }}>Massas d'água:</Typography>
                            <TextField type="number" value={waterMassValue} onChange={handleWaterMassChange} sx={{ width: '100px', marginRight: 1 }} />
                            {waterMassValue && <Typography sx={{ fontSize: '16px' }}>km²</Typography>}
                        </Box>
                    )}
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, borderRadius: 3 }}>
                <Button sx={{ textTransform: 'none' }}>Filtrar estações</Button>
            </Box>
        </Box>
    );
}
