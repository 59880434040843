import { Typography } from '@mui/material';
import { ConsumoData, FluvioStation, TelemetricaStation } from '../../../core';

const getVazaoArea = (areaBacia?: number | null, telemetrica?: TelemetricaStation | null, convencional?: FluvioStation | null): [string[], string] | null => {
    const vazaoEstacao = telemetrica?.properties.vazao;
    const areaEstacao = telemetrica?.properties.areaM2;

    if (!vazaoEstacao || !areaEstacao || !areaBacia) return null;

    const formattedDate = telemetrica.properties.dataMedicao?.toLocaleDateString(
        'pt-BR',
        {
            month: 'short',
            day: '2-digit',
            minute: '2-digit',
            hour: "2-digit",
        }
    ) ?? "-";

    const timeStr = "Vazão instantânea regionalizada";
    const vazaoTeleStr = (vazaoEstacao * areaBacia / areaEstacao).toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " m³/s";

    const correlGum = convencional?.properties.q710?.correlGum ?? 0;
    const correlWei = convencional?.properties.q710?.correlWei ?? 0;
    const areaConvencional = convencional?.properties.areaM2;
    if ((correlGum === 0 && correlWei === 0) || !areaConvencional) return null;

    const q710 = correlGum > correlWei ? (convencional?.properties.q710?.QGumbel ?? 0) : (convencional?.properties.q710?.QWeibull ?? 0);
    if (q710 === 0) return null;

    const q7intro = "Q7,10 regionalizada";
    const q7val = (q710 * areaBacia / areaConvencional).toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " m³/s";

    return [[timeStr, vazaoTeleStr, q7intro, q7val], formattedDate];
};

const getVazaoTelemetrica = (telemetrica?: TelemetricaStation | null): (string | JSX.Element)[] | null => {
    const vazaoEstacao = telemetrica?.properties.vazao;
    const areaEstacao = telemetrica?.properties.areaM2;
    const stationCode = telemetrica?.properties.codigo;
    const area = telemetrica?.properties.areaM2;

    if (!vazaoEstacao || !areaEstacao || !stationCode || !area) return null;

    const vazaoStr = vazaoEstacao.toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " m³/s";
    const areaKM = area / 1E6;
    const codeStr = (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
            <img src={telemetrica.svgItem} alt={`${stationCode}`} style={{
                width: "0.625rem",
                height: "0.625rem",
            }} />
            <div style={{ width: "0.313rem" }} />
            <Typography fontSize='1rem'>
                {stationCode.toFixed(0).toString() + " (" + areaKM.toSignificantDigits("area").toString()?.replaceAll(".", ",") + " km²)"}
            </Typography>
        </div>
    );

    return [codeStr, vazaoStr];
};

const getVazaoConvencional = (convencional?: FluvioStation | null): (string | JSX.Element)[] | null => {
    const correlGum = convencional?.properties.q710?.correlGum ?? 0;
    const correlWei = convencional?.properties.q710?.correlWei ?? 0;
    const area = convencional?.properties.areaM2;
    const stationCode = convencional?.properties.codigo;

    if ((correlGum === 0 && correlWei === 0) || !area || !stationCode) return null;

    const q710 = correlGum > correlWei ? (convencional?.properties.q710?.QGumbel ?? 0) : (convencional?.properties.q710?.QWeibull ?? 0);
    if (q710 === 0) return null;

    const q7val = q710.toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " m³/s";
    const areaKM = area / 1E6;

    const codeStr = (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
            <img src={convencional.svgItem} alt={`${stationCode}`} style={{
                width: "0.625rem",
                height: "0.625rem",
            }} />
            <div style={{ width: "0.313rem" }} />
            <Typography fontSize='1rem'>
                {stationCode.toFixed(0).toString() + " (" + areaKM.toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " km²)"}
            </Typography>
        </div>
    );

    return [codeStr, q7val];
};

export const getTooltipArea = (
    nome: string,
    areaM2: number,
    outorgas?: ConsumoData,
    telemetrica?: TelemetricaStation,
    fluviometrica?: FluvioStation,

): HTMLElement => {
    const tooltipContent = document.createElement('div');

    const vazaoArea = getVazaoArea(areaM2, telemetrica, fluviometrica);
    const vazaoTelemetrica = getVazaoTelemetrica(telemetrica);
    const vazaoConvencional = getVazaoConvencional(fluviometrica);
    const formattedDate = vazaoArea ? vazaoArea[1] : null;

    // Formatendo os dados
    const vazaoTelemetricaValue = vazaoTelemetrica ? vazaoTelemetrica[1] : null;
    const vazaoTelemetricaDescription = vazaoTelemetrica ? getTextFromReactElement(vazaoTelemetrica[0]) : 'N/A';
    const vazaoConvencionalDescription = vazaoConvencional ? getTextFromReactElement(vazaoConvencional[0]) : 'N/A';
    const separateValues = (description: string) => {
        if (description === 'N/A') {
            return { firstNumberTelemetrical: null, secondNumberTelemetrical: null };
        }

        const regex = /(\d+)\s*\((\d+),?\s*km²\)/;

        const match = regex.exec(description);

        if (match) {
            const firstNumberTelemetrical = match[1];
            const secondNumberTelemetrical = match[2];

            return { firstNumberTelemetrical, secondNumberTelemetrical };
        }

        return { firstNumber: null, secondNumber: null };
    };
    const { firstNumberTelemetrical, secondNumberTelemetrical } = separateValues(vazaoTelemetricaDescription);

    const separateValuesConvencional = (description: string) => {
        if (description === 'N/A') {
            return { firstNumberConvencional: null, secondNumberConvencional: null };
        }

        const regex = /(\d+)\s*\((\d+),?\s*km²\)/;

        const match = regex.exec(description);

        if (match) {
            const firstNumberConvencional = match[1];
            const secondNumberConvencional = match[2];

            return { firstNumberConvencional, secondNumberConvencional };
        }

        return { firstNumberConvencional: null, secondNumberConvencional: null };
    };
    const { firstNumberConvencional, secondNumberConvencional } = separateValuesConvencional(vazaoConvencionalDescription);

    tooltipContent.innerHTML = `
    <div style="border-radius: 1rem; padding: 0; background-color: #fff; font-family: 'Catamaran', sans-serif;">
    <h3> Bacia do ${nome}</h3>
      <table>
        <tbody>
          <tr>
            <td style="padding: 0.5px; line-height: 14px"> Área de Drenagem:</td>
            <td style="padding: 0.5px; line-height: 14px"> ${areaM2 ? (areaM2 / 1000000).toSignificantDigits('area').toString()?.replaceAll('.', ',') + ' km²' : 'N/A'}</td>
          </tr>
          ${vazaoArea ? `
              <tr>
                <td style="padding: 0.5px; line-height: 14px"> ${vazaoArea[0][0]}</td>
                <td style="padding: 0.5px; line-height: 14px"> ${vazaoArea[0][1]}</td>
              </tr>
              <tr>
                <td style="padding: 0.5px; line-height: 14px">${vazaoArea[0][2]}</td>
                <td style="padding: 0.5px; line-height: 14px">${vazaoArea[0][3]}</td>
              </tr>
          `: ''}
           <tr>
            <td style="padding: 0.5px; line-height: 14px">Consumo Total Outorgado:</td>
            <td style="padding: 0.5px; line-height: 14px">${outorgas?.consumoTotal.toSignificantDigits("vazao").toString()?.replaceAll(".", ",") + " m³/s" ?? 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 0.5px; line-height: 14px">Quantidade de Outorgas:</td>
            <td style="padding: 0.5px; line-height: 14px">${outorgas?.numeroOutorgas ?? 'N/A'}</td>
          </tr>
        </tbody>
      </table>
        <hr> 
      <table > 
        <tbody>
          ${vazaoTelemetrica ? `
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Telemétrica de Referência:</td>
                <td style="padding: 0.5px; line-height: 14px">${firstNumberTelemetrical}</td>
              </tr>
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Área de Drenagem:</td>
                <td style="padding: 0.5px; line-height: 14px">${secondNumberTelemetrical} km²</td>
              </tr>
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Vazão Instantânea: </td>
                <td style="padding: 0.5px; line-height: 14px"> ${vazaoTelemetricaValue}</td>
              </tr>
          `: ''}
        </tbody>
      </table>
        <hr > 
      <table > 
        <tbody>
          ${vazaoConvencional ? `
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Convencional de Referência: </td>
                <td style="padding: 0.5px; line-height: 14px">${firstNumberConvencional}</td>
              </tr>
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Área de Drenagem: </td>
                <td style="padding: 0.5px; line-height: 14px">${secondNumberConvencional}</td>
              </tr>
              <tr>
                <td style="padding: 0.5px; line-height: 14px">Q7, 10: </td>
                <td style="padding: 0.5px; line-height: 14px">${vazaoConvencional[1]}</td>
              </tr>
          `: ''}
        </tbody>
      </table>
    </div>
  `;
    return tooltipContent;
};



// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTextFromReactElement = (element: any): string => {
    if (typeof element === 'string') {
        return element;
    }
    if (typeof element === 'number') {
        return element.toString();
    }
    if (element && typeof element === 'object' && 'props' in element) {
        let text = '';
        if (element.props.children) {
            if (Array.isArray(element.props.children)) {
                text = element.props.children.map(getTextFromReactElement).join('');
            } else {
                text = getTextFromReactElement(element.props.children);
            }
        }
        return text;
    }
    return '';
};
