import React from 'react';
import TextField from '@mui/material/TextField';

interface PhoneInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    name: string;
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, label, name, fullWidth = false, variant = "standard" }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let input = event.target.value.replace(/\D/g, '');

        if (input.length > 2) {
            input = `(${input.substring(0, 2)}) ${input.substring(2)}`;
        }
        if (input.length > 8) {
            input = `${input.substring(0, 10)}-${input.substring(10, 15)}`;
        }

        if (input.length > 15) {
            input = input.substring(0, 15);
        }

        event.target.value = input;
        onChange(event);
    };

    return (
        <TextField
            value={value}
            onChange={handleInputChange}
            label={label}
            name={name}
            fullWidth={fullWidth}
            variant={variant}
            inputProps={{ maxLength: 15 }} 
        />
    );
};

export default PhoneInput;
