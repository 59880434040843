import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { onError, onResponse, onRequest } from "../services";
import { HidroCryper } from "../core";

interface ApiContextData {
    authToken: string;
}

// Contextos
const apiContext = createContext<ApiContextData>({} as ApiContextData);
export const useAuthToken = () => useContext<ApiContextData>(apiContext);

// Fução API
export const Api: React.FC<Props> = ({ children }) => {
    const encrypted = localStorage.getItem("token") ?? "";
    const startToken = HidroCryper.decrypt(encrypted);

    const [authToken, setAuthToken] = useState<string>(startToken);

    axios.interceptors.request.use(onRequest);
    axios.interceptors.response.use(
        async (response) => onResponse(response, setAuthToken),
        async (error) => onError(error, setAuthToken),
    );

    return (
        <apiContext.Provider value={{ authToken }}>
            {children}
        </apiContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};
