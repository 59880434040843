import { useState } from "react";
import {
    Box,
    Typography,
    Modal,
    Button,
    Divider,
    Grid,
    Tooltip,
    Icon,
} from "@mui/material";
import { ExporterEstudo } from "./exporter_estudo_landscape";
import { ItemExporter, dadosExportar } from "./items_exporter";
import { useTheme, Theme } from "@mui/material/styles";
import { Color } from "../../../core/color/color";
import { Spacer } from "../../shared";

function ExportEstudo(items: ItemExporter[]) {
    console.log(items);
}

interface Props {
    theme?: Theme;
}
export function ExporterEstudoBtn({ theme }: Props) {
    const [openModal, setOpenModal] = useState(false);

    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;

    const backgroundColor = new Color(currentTheme.palette.background.default);
    const textColor = new Color(currentTheme.palette.background.paper);

    return (
        <div>
            {/* Botão que abre o modal */}
            <Tooltip title="Exportar dados do estudo">
                <Button
                    variant="outlined"
                    onClick={() => setOpenModal(true)}
                    sx={{
                        height: "46px",
                        width: "156px",
                        color: textColor.toHex,
                        borderRadius: "46px",
                    }}>
                    <Icon>
                        save
                    </Icon>
                    <Spacer width="15px" />
                    <Typography>
                        Exportar
                    </Typography>
                </Button>
            </Tooltip>

            {/* Modal que exibe os dados */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90vw",
                        height: "85vh",
                        bgcolor: backgroundColor.toHex,
                        borderRadius: "30px",
                        boxShadow: "24px",
                        padding: "15px",
                        overflow: "auto",
                    }}
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "100%",
                    }}>
                        <Typography variant="h6">
                            Exportar estudo
                        </Typography>
                    </div>

                    <div style={{ height: '15px' }} />
                    <Divider />
                    <div style={{ height: '15px' }} />

                    <ExporterEstudo data={dadosExportar} />

                    <Grid container spacing={"10px"} sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "flex-end",
                    }}>
                        <Grid item >
                            <Button
                                onClick={() => ExportEstudo(dadosExportar)}
                                variant="outlined"
                                sx={{ borderRadius: "100px" }}
                            >
                                Exportar
                            </Button>
                        </Grid>

                        <Grid item >
                            <Button
                                variant="contained"
                                onClick={() => setOpenModal(false)}
                                sx={{ borderRadius: "100px" }}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div >
    );
}
