import CryptoES from 'crypto-es';
import { SECRET_KEY_CRYPTO } from "../../enviroment/env";


// Crypto
function encrypt(text: string): string {
    const crypted = CryptoES.AES.encrypt(text, SECRET_KEY_CRYPTO).toString();
    return crypted;
}

function decrypt(text: string): string {
    const decrypted = CryptoES.AES.decrypt(text, SECRET_KEY_CRYPTO).toString(CryptoES.enc.Utf8);
    return decrypted;
}

const HidroCryper = {
    encrypt,
    decrypt,
};

export { HidroCryper };