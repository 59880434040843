

const constantes: Map<string, string> = new Map<string, string>([
    //Colunas das outorgas
    ["objectid", "ID"],
    ["statuspa_4", "Status do Cadastro"],
    ["finuso_4", "Finalidade do Uso da Outorga"],

    //Para dados de vazão
    ["data", "Data"],
    ["vazao", "Vazão"],
    ["unidades", "Unidade"],
    ["vazaoStatus", "Status da Vazão"],
    ["metodoObtencao", "Método de Obtenção"],
    ["nivelConsistencia", "Nível de Consistência"],

    // Estados brasileiros
    ["AC", "Acre"],
    ["AL", "Alagoas"],
    ["AP", "Amapá"],
    ["AM", "Amazonas"],
    ["BA", "Bahia"],
    ["CE", "Ceará"],
    ["DF", "Distrito Federal"],
    ["ES", "Espírito Santo"],
    ["GO", "Goiás"],
    ["MA", "Maranhão"],
    ["MT", "Mato Grosso"],
    ["MS", "Mato Grosso do Sul"],
    ["MG", "Minas Gerais"],
    ["PA", "Pará"],
    ["PB", "Paraíba"],
    ["PR", "Paraná"],
    ["PE", "Pernambuco"],
    ["PI", "Piauí"],
    ["RJ", "Rio de Janeiro"],
    ["RN", "Rio Grande do Norte"],
    ["RS", "Rio Grande do Sul"],
    ["RO", "Rondônia"],
    ["RR", "Roraima"],
    ["SC", "Santa Catarina"],
    ["SP", "São Paulo"],
    ["SE", "Sergipe"],
    ["TO", "Tocantins"],
    ["URU", "URUGUAI"],
    ["ARG", "ARGENTINA"],
    ["PAR", "PARAGUAI"],
    ["CHI", "CHILE"],
    ["BOL", "BOLÍVIA"],
    ["PER", "PERU"],
    ["COL", "COLÔMBIA"],
    ["EQU", "EQUADOR"],
    ["VEN", "VENEZUELA"],
    ["GUI", "GUIANA"],
    ["SUR", "SURINAME"],
    ["GFR", "GUIANA FRANCESA"]
]);

function dicionario(val: unknown): unknown {
    if (typeof val === 'string') {
        return constantes.get(val as string) || val;
    } else if (val instanceof Map) {
        const retornar: Map<string, unknown> = new Map<string, unknown>();

        val.forEach((key, value) => {
            key = dicionario(key);
            value = dicionario(value);
            retornar.set(key, value);
        });
        return retornar;
    } else {
        return val;
    }
}

const Constantes = {
    dicionario,
    constantes,
};

export { Constantes };
