import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const autoAnoHidrologico = (/*anoHidrologico: number[]*/): AxiosResponse => {
    return {
        data: {
            "meses": {
                2: 155.34,
                3: 435.015,
                4: 280,
                5: 567,
                6: 986,
                7: 1156,
                8: 700,
                9: 500,
                10: 300,
                11: 315,
                12: 105,
                1: 95,
            },
        },
        status: 200,
        statusText: "OK",
        headers: {}, //api.api.defaults.headers,
        config: {
            headers: {} as AxiosRequestHeaders,
        }
    };
};

const checkCRS = async (crs: string): Promise<AxiosResponse> => {
    return await axios.get(
        `${API_URL}/toolbox/geoprocess/check_crs`,
        { data: { crs: crs } },
    );
};

// TODO: Implementar o listLayers e adicionar envio de arquivos
// async listLayers(crs: string): Promise<AxiosResponse> {
//   // const dataSend = new Map<string, unknown>;
//   // dataSend.set("crs", crs);
//   // return await Api.sendRequest(
//   //   "/toolbox/geoprocess/check_crs",
//   //   {
//   //     method: "POST",
//   //     queryData: dataSend,
//   //   },
//   // );
// }

export const ApiAuxiliares = {
    autoAnoHidrologico: autoAnoHidrologico,
    checkCRS: checkCRS,
};
